import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import {
  CompanyData,
  Guideline,
  SuperAdminBlog,
  User,
  UserLite,
} from "./../../types.tsx";

import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { getTranslation } from "../../translation";
import ReactQuill from "react-quill";
import axios from "axios";
import { common, lightGreen } from "@mui/material/colors";
import { lightGreen400 } from "material-ui/styles/colors";
import ContextMenu from "../components/ContextMenu.tsx";
import { alignProperty } from "@mui/material/styles/cssUtils";
import Calendar from "react-calendar";

type AlertColor = "success" | "info" | "warning" | "error";

export default function CompanyView({
  company,
  all_users,
  login,
  allCompanies,
}: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [startDate, setStartDate] = useState(new Date());

  const [categories, setCategories] = useState([
    { name: "Apple Inc",
    subcategories:[] },
    {
      name: "AirPods",
      subcategories: [
        { name: "AirPods" },
        { name: "AirPods Pro" },
        { name: "AirPods Max" },
      ],
    },
    { name: "AppleTv" ,
    subcategories:[]},
    {
      name: "AppleWatch",
      subcategories: [
        { name: "Apple Watch Series 7" },
        { name: "Apple Watch SE" },
        { name: "Apple Watch Series 3" },
        { name: "Apple WatchOS" },
        // Add more Apple Watch models as needed
      ],
    },
    { name: "HomePod", subcategories: [{ name: "HomePod" }, { name: "Mini" }] },
    {
      name: "iPad",
      subcategories: [
        { name: "iPad" },
        { name: "iPad Pro" },
        { name: "iPad Air" },
        { name: "iPad mini" },
        { name: "iPadOS" },
      ],
    },
    {
      name: "iPhone",
      subcategories: [
        { name: "iPhone" },
        { name: "iPhone Pro" },
        { name: "iPhone Pro Max" },
        { name: "iPhone SE" },
        { name: "IOS" },
        // Add more iPhone models as needed
      ],
    },
    {
      name: "Mac",
      subcategories: [
        { name: "MacBook Air" },
        { name: "MacBook Pro" },
        { name: "iMac" },
        { name: "iMac Pro" },
        { name: "Mac Pro" },
        { name: "Mac mini" },
        { name: "Studio" },
        { name: "MacOS" },
      ],
    },
    {
      name: "Services",
      subcategories: [
        { name: "Apple Music" },
        { name: "Apple TV+" },
        { name: "Apple Arcade" },
        { name: "iCloud" },
        { name: "Apple Books" },
        { name: "Other" },
        // Add more Apple services as needed
      ],
    },
    { name: "Accessories" ,
    subcategories:[]},
    { name: "AppleVisionPro",
    subcategories:[] }, // Assuming this is a specific product or category, adjust if it's a placeholder
  ]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSlackNotifications = () => {
    // Implement Slack Notifications logic
    handleCloseMenu();
  };

  const handleMakeInactive = () => {
    // Implement Make Inactive logic
    handleCloseMenu();
  };

  const handleDeleteProject = async () => {
    debugger;
    if (currentCompanyData) {
      let updatedProjectsList = currentCompanyData.company_projects.filter(
        (project) => project !== selectedProject
      );

      let body = {
        project_name: selectedProject,
        company_projets: updatedProjectsList,
        company_uuid: currentCompanyData?.company_uuid,
      };
      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/project/delete",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );
        const data = await response.json();

        showToast("success", "SUCCESSFULLY DELETED");

        // setCurrentCompanieBlogs(data.current_project_blogs); // Update the services state with fetched data
      } catch (error) {
        console.error("Error fetching services:", error);
      }
      getCompanyBlogs(currentCompanyData.company_uuid);
      setDeleteBlogs(!deleteBlogs);
      setDeleteProject(!deleteProject);
    }
  };

  const [newBlog, setNewBlog] = useState<Boolean>(false);
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();
  const [selectedBlogUuids, setSelectedBlogUuids] = useState([]);

  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);
  const [showBoard, setShowBoard] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([] as any);
  const [currentUser, setUser] = useState<User>({
    email: "",
    name: "",
    user_uuid: "",
    password: "",
    roles: [],
  });
  const [newProjectName, setNewProjectName] = useState<String>("");
  const [checkedItems, setCheckedItems] = useState<Number[]>([]);

  const [createProject, setCreateProject] = useState(false); // Loading state
  const [assignUser, setAssignUser] = useState(false); // Loading state
  const [showAddEditor, setShowAddEditor] = useState(false); // Loading state
  const [contact, setContact] = useState(false); // Loading state
  const [deleteBlogs, setDeleteBlogs] = useState(false); // Loading statesetDeleteProject(!deleteProject)
  const [deleteProject, setDeleteProject] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showExampleArticle, setShowExampleArticle] = useState(false);
  const [createGoogleDoc, setCreateGoogleDoc] = useState(false);
  const [showAdditionalGuidelines, setShowAdditionalGuidelines] = useState(
    false
  );
  const [showCompanyGuidelines, setShowCompanyGuidelines] = useState(false);
  const [generalGuidelines, setGeneralGuidelines] = useState(false);
  const [showDeadline, setShowDeadline] = useState(false);

  const [currentBlog, setCurrentBlog] = useState<SuperAdminBlog>({
    blog_uuid: "",
    urgent: false,
    project_name: "",
    amount: 0,
    blog_title: "",
    multiple: false,
    company_name: "",
    deadline: "",
    keywords: "",
    additional_guidelines: "",
    company_guidelines: [],
    general_guidelines: false,
    status: "Pending",
    editor_name: "",
    category: "",
    sub_category: "",
    editor_uuid: "",
    url: "",
    words: 0,
    company_uuid: "",
    writer_uuid: "",
    writer: "",
    additional_info: "",
    blog_length: 0,
    example_article: [],
    tools: [],
  });
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [currentCompanieBlogs, setCurrentCompanieBlogs] = useState<
    SuperAdminBlog[]
  >([]);
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const [SelectedView, setSelectedView] = useState("company");
  const getProjectBlogs = async (project: string) => {
    const requestBody = {
      project_name: project,
      company_uuid: currentCompanyData?.company_uuid,
    };
    try {
      let companyView = login ? true : false;
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/company/project-blogs?companyView=${companyView}`, // Fixed URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const createNewProject = async () => {
    try {
      StartLoading();
      if (currentCompanyData) {
        currentCompanyData.company_projects.push(newProjectName.toString());
        let list: string[] = currentCompanyData.company_projects;

        let requestBody = {
          company_projets: list,
          company_uuid: currentCompanyData.company_uuid,
        };
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/project/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        StopLoading();
        setCreateProject(!createProject);
        showToast("success", "SUCCESSFULLY CREATED");
      }
    } catch (error:any) {
      StopLoading();
      showToast("error", error.toString());
    }
  };
  const handleChange = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`writer_uuid`]: event.target.value,
    }));
  };
  const handleChangeProject = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`project_name`]: event.target.value,
    }));
  };

  useEffect(() => {
    if (company) {
      setCurrentCompanieBlogs(company.current_project_blogs);
      setCurrentCompanyData(company);
      setSelectedProject(company.company_projects[0]);
      fetchGuidelines();
      fetchAdminGuidelines();
    }

    // getCompanyBlogs(company_uuid);
  }, [company]);
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [allStatuses, setAllStatuses] = useState([
    "pending",
    "in progress",
    "written",
    "editor",
    "completed",
    "revision",
    "failed",
  ] as any);

  const [activeSpans, setActiveSpans] = useState([] as any);
  const [activeCategories, setActiveCategories] = useState([] as any);
  const [activeSubCategories, setActiveSubCategories] = useState([] as any);

  const toggleSpan = (span: any) => {
    if (activeSpans.includes(span)) {
      setActiveSpans(
        activeSpans.filter((activeSpan: any) => activeSpan !== span)
      );
    } else {
      setActiveSpans([...activeSpans, span]);
    }
  };

  const toggleCategory = (category:any) => {
    debugger
    if(activeCategories.includes(category)) {
      setActiveCategories(
        activeCategories.filter((activeCategory: any) => activeCategory !== category)
      );
    }
    else {
      setActiveCategories([...activeCategories, category]);
    }
  }
  const toggleSubCategory = (category:any) => {

    if(activeSubCategories.includes(category)) {
      setActiveSubCategories(
        activeSubCategories.filter((activeCategory: any) => activeCategory !== category)
      );
    }
    else {
      setActiveSubCategories([...activeSubCategories, category]);
    }
  }

  function filterBlogsByCategoryAndSubcategory(): SuperAdminBlog[] {
    if (activeCategories.length > 0 ) {
      if (activeSubCategories.length > 0 ) {
        return currentCompanieBlogs.filter(blog => activeCategories.includes(blog.category) || activeSubCategories.includes(blog.sub_category));
      } else {
        return currentCompanieBlogs.filter(blog => activeCategories.includes(blog.category));
      }
    } else if (activeCategories.length === 0 && activeSubCategories.length > 0 ) {
      return currentCompanieBlogs.filter(blog => activeSubCategories.includes(blog.sub_category));
    }
    return currentCompanieBlogs
}


  function getRender(status: string): React.ReactNode {
    let backgroundColor = "lightgreen";
    let color = "white";

    switch (status) {
      case "Pending": {
        backgroundColor = "lightgray";
        color = "black";
        break;
      }
      case "In Progress": {
        backgroundColor = "blue";
        break;
      }
      case "Written": {
        backgroundColor = "lightgreen";
        color = "black";
        break;
      }
      case "Editor": {
        backgroundColor = "orange";
        break;
      }
      case "Completed": {
        backgroundColor = "darkgreen";
        break;
      }
      case "Revision": {
        backgroundColor = "purple";
        break;
      }
      case "Paid": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Failed": {
        backgroundColor = "red";
        break;
      }
    }
    return (
      <>
        <div
          style={{
            padding: "0.2rem 0.5rem",
            // border: "1px solid gray",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "3vh",
            color: color,
            background: backgroundColor,
          }}
        >
          {status}
        </div>
      </>
    );
  }
  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }
  const handleChangeStatus = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`status`]: event.target.value,
    }));
  };
  const handleChangeEditor = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`editor_uuid`]: event.target.value,
    }));
  };
  const [subcategories, setSubcategories] = useState([]);
  const handleChangeCategory = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`category`]: event.target.value,
    }));

    // Find the selected category obje
  };

  // Your existing handleChangeEditor function should be updated to handle subcategory changes
  // Assuming you will handle subcategory change here
  const handleChangeSubCategory = (event: any) => {
    // Handle subcategory selection change
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`sub_category`]: event.target.value,
    }));
    // You may want to update a state or perform other actions here
  };
  const setupCurrentBlog = (blog: any) => {
    setCurrentBlog(blog);
    // if (blog.genera_guidelines && blog.company_gui.length > 0) {
    //   setShowCompanyGuidelines(true);
    //   // setShowExampleArticle(true);
    // }
    if (blog.company_guidelines && blog.company_guidelines.length > 0) {
      setShowCompanyGuidelines(true);
      // setShowExampleArticle(true);
    }
    if (blog.deadline.length > 0) {
      setShowDeadline(true);
    }
    if (blog.additional_info && blog.additional_info.length > 0) {
      setShowAdditionalInfo(true);
      // setShowExampleArticle(true);
    }
    if (blog.additional_guidelines && blog.additional_guidelines.length > 0) {
      setShowAdditionalGuidelines(true);
      // setShowExampleArticle(true);
    }
    if (blog.example_article && blog.example_article.length > 0) {
      setShowExampleArticle(true);
      // setShowExampleArticle(true);
    }
    if (blog.general_guidelines) {
      setGeneralGuidelines(true);
    }
  };

  // Custom styles
  const checkboxStyle = {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
    padding: "10px",
    bordeRadius: "10px",
  };
  const [toast_message, setToastMessage] = useState("empty_fields");
  const [toast_type, setToastType] = useState<AlertColor>("success");

  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const showToast = (type: string, message: string) => {
    setToastType(type as AlertColor);
    setToastMessage(message);
    setOpen(true);
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  };
  const [guidelines, setGuidelines] = useState([]);
  const [adminGuidelines, setAdminGuidelines] = useState([]);
  const fetchAdminGuidelines = async () => {
    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/admin/guidelines/active",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAdminGuidelines(data);
      // Handle the data as needed
      console.log(data);
    } catch (error) {
      console.error("Error fetching company guidelines:", error);
    }
  };
  const fetchGuidelines = async () => {
    if (company) {
      const requestBody = {
        company_uuid: company.company_uuid,
      };

      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/company/guidelines",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);

        // setSelectedProject(data[selectedGuideline]);
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };
  const SelectTools = () => {
    const options = ["Grammarly", "Positional AI", "Copyscape", "Copyleaks"]; // Replace with your options

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(selectedValues);

      setUser((prevUser) => ({
        ...prevUser,
        roles: selectedValues,
      }));
    };

    return (
      <FormControl style={{ width: "100%", padding: "0rem 1rem" }}>
        <InputLabel id="multiple-select-label">Tools</InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="Tools" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const CustomDiv = ({ name, number, customecolor }: any) => {
    let colored = "4px solid " + customecolor;
    console.log("--------", customecolor, "--", colored);
    return (
      <div
        style={{
          minHeight: "5vh",
          background: "white",
          boxShadow: "2px 2px 10px rgba(181, 181, 181, 0.7)",
          borderRadius: "5px",
          display: "flex",
          borderTop: colored,
          justifyContent: "start",
          flexDirection: "row",
          marginBottom: "1rem",
          padding: "0rem 2rem",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "bold", color: "gray" }}>{name}</span>{" "}
        <span
          style={{
            marginLeft: "1rem",
            padding: "0.2rem 0.5rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
          }}
        >
          {number}
        </span>
      </div>
    );
  };

  const BlogPaper = ({ blog, checked }: any) => {
    console.log(blog);
    // let bg = "white";
    // if (blog.urgent) {
    //   bg = "red";
    // }
    let wn, ws, en, es;
    let surname = "";
    let name = "";
    if (
      blog.writer &&
      String(blog.writer)?.split(" ")[0] &&
      String(blog.writer)?.split(" ")[1]
    ) {
      wn = String(blog.writer)?.split(" ")[0]?.at(0)?.toString();
      ws = String(blog.writer)?.split(" ")[1]?.at(0)?.toString();

      name = (((wn as string) + ws) as string).toString();
    }
    if (
      blog.editor_name &&
      String(blog.editor_name)?.split(" ")[0] &&
      String(blog.editor_name)?.split(" ")[1]
    ) {
      en = String(blog.editor_name)?.split(" ")[0]?.at(0)?.toString();
      es = String(blog.editor_name)?.split(" ")[1]?.at(0)?.toString();
      surname = (((en as string) + es) as string).toString();
    }
    let check = "";
    if (checked == true) {
      check = "#F1E6FF";
    } else {
      check = "white";
    }
    return (
      <div
        style={{
          minHeight: "auto",
          background: check,
          borderRadius: "5px",
          display: "flex",
          border: "1px solid #dfdfdf",
          justifyContent: "space-between",
          flexDirection: "column",
          padding: "1rem 1.5rem",
          marginBottom: "1rem",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            minWidth: "100%",
            justifyContent: "space-between",
            // paddingLeft: "0.3rem",
          }}
        >
          {blog.urgent ? (
            <span
              style={{
                color: "white",
                padding: "0.3rem 0.7rem 0.4rem 0.7rem",
                background: "crimson",
                borderRadius: "30px",
                fontSize: "small",
                fontWeight: "bold",
              }}
            >
              {"Urgent"}
            </span>
          ) : (
            <></>
          )}
          {/* {blog.urgent ?  <span>{"Urgent"}</span>: <></>} */}
          {blog.url ? (
            <span
              style={{
                color: "black",
                padding: "0.3rem 0.7rem 0.4rem 0.7rem",
                borderRadius: "30px",
                fontSize: "small",
                fontWeight: "bold",
              }}
            >
              <a href={blog.url} target="_blank">
                {"Link"}
              </a>
            </span>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            minWidth: "100%",
            justifyContent: "space-between",
            alignItems: "start",
            paddingTop: "1rem",
          }}
        >
          <div>
            {blog.keywords.length > 0 ? blog.keywords : blog.blog_title}
          </div>
          <div>
            {blog.amount > 0 ? (
              <span
                style={{
                  color: "green",
                  fontSize: "large",
                  fontWeight: "bold",
                }}
              >
                {blog.amount}
                {"$"}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            paddingTop: "1rem",
            alignItems: "center",
            minWidth: "100%",
            justifyContent: "space-between",
            paddingLeft: "0.3rem",
          }}
        >
          <div style={{ fontWeight: "bold", fontSize: "small" }}>
            {blog.blog_length}
          </div>
          <div style={{ display: "flex", gap: "7px" }}>
            {blog.writer &&
            String(blog.writer)?.split(" ")[0] &&
            String(blog.writer)?.split(" ")[1] ? (
              <>
                <span
                  style={{
                    background: "black",
                    padding: "0.6rem 0.5rem",
                    color: "white",
                    // marginRight:'0,3rem',
                    borderRadius: "100%",
                  }}
                >
                  {name}
                </span>
              </>
            ) : (
              <></>
            )}
            {blog.editor_name &&
            String(blog.editor_name)?.split(" ")[0] &&
            String(blog.editor_name)?.split(" ")[1] ? (
              <>
                <span
                  style={{
                    background: "orange",
                    padding: "0.6rem 0.5rem",
                    color: "white",
                    borderRadius: "100%",
                  }}
                >
                  {surname}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const [showGuidelineEditor, setShowGuidelineEditor] = useState<string | null>(
    null
  );
  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([]);

  const handleEyeIconClick = (guidelineId: string) => {
    setShowGuidelineEditor((prevId) =>
      prevId === guidelineId ? null : guidelineId
    );
  };

  const handleCheckboxChange = (guidelineUuid: string) => {
    setSelectedGuidelines((prevSelected) =>
      prevSelected.includes(guidelineUuid)
        ? prevSelected.filter((uuid) => uuid !== guidelineUuid)
        : [...prevSelected, guidelineUuid]
    );
  };

  const deleteSelectedBlogs = async () => {
    try {
      StartLoading();
      console.log(currentBlog, checkedItems);
      const blogUuids = checkedItems.map((index: Number) => {
        // Assuming currentCompanieBlogs is an array of objects with a property blog_uuid
        return currentCompanieBlogs[index as number].blog_uuid;
      });

      await axios.post(
        `https://servercode.wecon-digital.agency/api/delete-blogs/`,
        {
          blogs: blogUuids,
          company_uuid: company.company_uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setDeleteBlogs(!deleteBlogs);
      getProjectBlogs(selectedProject);
      showToast("success", "SUCCESSFULLY ARCHIVED");

      setCheckedItems([]);
      if (SelectedView === "users") {
        // getWriterBlogs(selectedWriterUuid.toString());
      } else {
        // getCompanyBlogs(currentCompanyUuid.toString());
      }

      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };
  const getCompanyBlogs = async (company_uuid: string) => {
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-company/blogs/${company_uuid}`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs);
      setCurrentCompanyData(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  const assignCurrentUser = async () => {
    try {
      StartLoading();
      console.log(currentBlog, checkedItems);
      const blogUuids = checkedItems.map((index: Number) => {
        // Assuming currentCompanieBlogs is an array of objects with a property blog_uuid
        return currentCompanieBlogs[index as number].blog_uuid;
      });
      if (currentBlog?.editor_uuid?.length > 0) {
        currentBlog.editor_name = all_users.filter(
          (user: UserLite) => user.user_uuid === currentBlog.editor_uuid
        )[0].name;
      } else if (
        currentBlog.editor_uuid === "" ||
        currentBlog.editor_uuid === undefined
      ) {
        currentBlog.editor_name = "";
      }
      if (currentBlog?.writer_uuid?.length > 0) {
        currentBlog.writer = all_users.filter(
          (user: UserLite) => user.user_uuid === currentBlog.writer_uuid
        )[0].name;
      } else if (
        currentBlog.writer_uuid === "" ||
        currentBlog.writer_uuid === undefined
      ) {
        currentBlog.writer = "";
      }
      await axios.post(
        `https://servercode.wecon-digital.agency/api/assign-user/`,
        {
          writer_uuid: currentBlog.writer_uuid,
          writer: currentBlog.writer,
          editor_uuid: currentBlog.editor_uuid,
          editor_name: currentBlog.editor_name,
          blogs: blogUuids,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setContact(!setContact);
      setShowAddEditor(false);
      getProjectBlogs(selectedProject);
      setAssignUser(!assignUser);
      setCheckedItems([]);
      if (SelectedView === "users") {
        // getWriterBlogs(selectedWriterUuid.toString());
      } else {
        // getCompanyBlogs(currentCompanyUuid.toString());
      }
      showToast("success", "SUCCESSFULLY ASSIGNED");
      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };

  const createNewBlog = async () => {
    try {
      console.log(selectedGuidelines);
      StartLoading();
      currentBlog.company_guidelines = selectedGuidelines;
      currentBlog.company_uuid = currentCompanyData?.company_uuid.toString() as string;
      // currentBlog.writer_uuid = currentBlog.writer;
      // currentBlog.editor_uuid = currentBlog.editor_name;
      currentBlog.company_name = currentCompanyData?.company_name?.toString() as string;
      if (currentBlog.editor_uuid.length > 0) {
        currentBlog.editor_name = all_users.filter(
          (user: UserLite) => user.user_uuid === currentBlog.editor_uuid
        )[0].name;
      }
      if (currentBlog.writer_uuid.length > 0) {
        currentBlog.writer = all_users.filter(
          (user: UserLite) => user.user_uuid === currentBlog.writer_uuid
        )[0].name;
      }
      currentBlog.status.length > 0
        ? (currentBlog.status = currentBlog.status)
        : (currentBlog.status = "Pending");
      await axios.post(`https://servercode.wecon-digital.agency/api/blog/create`, currentBlog, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      StopLoading();
      setContact(!setContact);
      getProjectBlogs(selectedProject);
      setShowCompanyGuidelines(false);
      setShowDeadline(false);
      setCreateGoogleDoc(false);
      setShowExampleArticle(false);
      setGeneralGuidelines(false);
      setShowAdditionalGuidelines(false);
      setShowAdditionalInfo(false);
      showToast("success", "SUCCESSFULLY CREATED");
      setContact(!setContact);
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error:any) {
      StopLoading();
      showToast("error", error.toString());
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  // Function to fetch services
  const saveChanges = async () => {
    try {
      if (!login) {
        if (currentBlog.editor_uuid.length > 0) {
          currentBlog.editor_name = all_users.filter(
            (user: UserLite) => user.user_uuid === currentBlog.editor_uuid
          )[0].name;
        } else {
          currentBlog.editor_name = "";
          currentBlog.editor_uuid = "";
        }
        if (currentBlog.writer_uuid.length > 0) {
          currentBlog.writer = all_users.filter(
            (user: UserLite) => user.user_uuid === currentBlog.writer_uuid
          )[0].name;
        } else {
          currentBlog.writer = "";
          currentBlog.writer_uuid = "";
        }
        if (showCompanyGuidelines) {
          currentBlog.company_guidelines = selectedGuidelines;
        } else {
          currentBlog.company_guidelines = [];
        }
      }

      StartLoading();
      await axios.post(
        `https://servercode.wecon-digital.agency/api/save/${currentBlog.blog_uuid}`,
        currentBlog,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setContact(!setContact);

      getProjectBlogs(selectedProject);
      getProjectBlogs(selectedProject);
      setShowCompanyGuidelines(false);
      setShowDeadline(false);
      if (SelectedView === "users") {
        // getWriterBlogs(selectedWriterUuid.toString());
      } else {
        // getCompanyBlogs(currentCompanyUuid.toString());
      }
      showToast("success", "SUCCESSFULLY UPDATED");
      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };

  const handleDateChange = (c_date: Date) => {
    console.log(c_date);
    let date = new Date(c_date)
      .toLocaleString()
      .split(",")[0]
      .trim()
      .replaceAll("/", "-");
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`deadline`]: date,
    }));
  };

  function computePaymentCompany(): React.ReactNode {
    let total = 0;
    currentCompanieBlogs.map((blog) => {
      // if ( blog.status === "Finished") {
      //   total = total +blog.amount
      // }
      total = total + blog.amount;
    });
    return <>{total.toFixed(2)} </>;
  }

  const renderContact = () => {
    return (
      <>
        <div className="blog_container">
          <div className="" style={{ minHeight: "5vh" }}>
            {currentBlog.blog_uuid.length > 0 ? (
              <>
                {" "}
                <h1>Current Blog</h1>
              </>
            ) : (
              <>
                {" "}
                <h1>Create Blog</h1>
              </>
            )}
          </div>
          <div className="modal_main_container">
            <div className="additional-div blog_css">
              {loadingSavingBlog ? (
                <>
                  <div className="container_loading">
                    <div className="loading"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="top_blog">
                    {currentBlog.blog_uuid.length > 0 ? (
                      // CURRENT
                      <>
                        {" "}
                        <TextField
                          style={{
                            maxWidth: "15%",
                          }}
                          name="company_name"
                          className="textfield"
                          // readOnly={true}
                          disabled={true}
                          id="outlined-required"
                          label="Company Name"
                          placeholder="CompanyName"
                          value={
                            currentBlog.company_name.length > 0
                              ? currentBlog.company_name
                              : ""
                          }
                          onChange={handleValueChange}
                        />
                        <TextField
                          style={{
                            maxWidth: "15%",
                          }}
                          name="blog_length"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Blog Length"
                          placeholder="Blog Length"
                          value={currentBlog.blog_length}
                          onChange={(e) => {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`blog_length`]: Number(e.currentTarget.value),
                            }));
                          }}
                        />
                        {login ? (
                          <></>
                        ) : (
                          <>
                            <FormControl style={{ minWidth: "25%" }}>
                              <InputLabel id="demo-simple-select-label">
                                Writer
                              </InputLabel>
                              <Select
                                name="writer"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentBlog.writer_uuid}
                                label="Writer Name"
                                onChange={handleChange}
                              >
                                {" "}
                                <MenuItem value="">{"None"}</MenuItem>
                                {all_users.map((user: UserLite) => {
                                  if (user.roles.includes("Writer")) {
                                    return (
                                      <MenuItem
                                        key={user.user_uuid}
                                        value={user.user_uuid}
                                      >
                                        {user.name}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </Select>
                            </FormControl>{" "}
                          </>
                        )}
                      </>
                    ) : (
                      // CREATE BLOG
                      <>
                        <TextField
                          style={{
                            maxWidth: "30%",
                          }}
                          name="company_name"
                          className="textfield"
                          required
                          disabled={true}
                          id="outlined-required"
                          // label="Company Name"
                          // placeholdere="Company Name"
                          value={currentCompanyData?.company_name}
                          onChange={handleValueChange}
                        />
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Project
                          </InputLabel>
                          <Select
                            name="editor_name"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.project_name}
                            label="Editor"
                            onChange={handleChangeProject}
                          >
                            {currentCompanyData &&
                            currentCompanyData.company_projects &&
                            currentCompanyData.company_projects.length > 0
                              ? currentCompanyData.company_projects.map(
                                  (project: string) => (
                                    <MenuItem key={project} value={project}>
                                      {project}
                                    </MenuItem>
                                  )
                                )
                              : null}
                          </Select>
                        </FormControl>{" "}
                        {login ? (
                          <></>
                        ) : (
                          <>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">
                                Writer
                              </InputLabel>
                              <Select
                                name="writer"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentBlog.writer_uuid}
                                label="Writer Name"
                                onChange={handleChange}
                              >
                                {" "}
                                <MenuItem value="">{"None"}</MenuItem>
                                {all_users.map((user: UserLite) => {
                                  if (user.roles.includes("Writer")) {
                                    return (
                                      <MenuItem
                                        key={user.user_uuid}
                                        value={user.user_uuid}
                                      >
                                        {user.name}
                                      </MenuItem>
                                    );
                                  }
                                })}
                              </Select>
                            </FormControl>
                          </>
                        )}
                        <TextField
                          style={{
                            maxWidth: "25%",
                          }}
                          name="blog_length"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Blog Length"
                          placeholder="Blog Length"
                          value={currentBlog.blog_length}
                          onChange={handleValueChange}
                        />
                        {login ? (
                          <></>
                        ) : (
                          <>
                            <FormControl style={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentBlog.status}
                                label="Company Name"
                                onChange={handleChangeStatus}
                              >
                                <MenuItem value={"Pending"}>Pending</MenuItem>
                                <MenuItem value={"In Progress"}>
                                  In Progress
                                </MenuItem>
                                <MenuItem value={"Written"}>Written</MenuItem>
                                <MenuItem value={"Editor"}>Editor</MenuItem>
                                <MenuItem value={"Completed"}>
                                  Completed
                                </MenuItem>
                                <MenuItem value={"Revision"}>Revision</MenuItem>
                                <MenuItem value={"Failed"}>Failed</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      padding: "1rem 0rem",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      style={{
                        maxWidth: "100%",
                      }}
                      name="blog_title"
                      className="textfield"
                      required
                      id="outlined-required"
                      label="Blog Title"
                      placeholder="CompanyName"
                      value={
                        currentBlog.blog_title.length > 0
                          ? currentBlog.blog_title
                          : ""
                      }
                      onChange={handleValueChange}
                    />
                    {login ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                        <SelectTools />
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Editor
                          </InputLabel>
                          <Select
                            name="editor_name"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.editor_uuid}
                            label="Editor"
                            onChange={handleChangeEditor}
                          >
                            {" "}
                            <MenuItem value="">{"None"}</MenuItem>
                            {all_users.map((user: UserLite) => {
                              if (user.roles.includes("Editor")) {
                                return (
                                  <MenuItem
                                    key={user.user_uuid}
                                    value={user.user_uuid}
                                  >
                                    {user.name}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Category</h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 25,
                      maxWidth: "70%",
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="main-category-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="main-category-select-label"
                        id="main-category-select"
                        value={currentBlog.category}
                        label="Category"
                        onChange={handleChangeCategory}
                      >
                        {/* <MenuItem value="">None</MenuItem> */}
                        {categories.map((category) => (
                          <MenuItem key={category.name} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="sub-category-select-label">
                          SubCategory
                        </InputLabel>
                        <Select
                          name="sub_category_name"
                          labelId="sub-category-select-label"
                          id="sub-category-select"
                          value={currentBlog.sub_category} // This should be the state that tracks the selected subcategory
                          label="SubCategory"
                          onChange={handleChangeSubCategory}
                        >

                         {categories
                            .filter((cat) => cat.name === currentBlog.category)
                            .flatMap(cat => cat.subcategories || []) // Use flatMap to handle undefined subcategories and avoid nesting
                            .map((subcat:any) => (
                            <MenuItem key={subcat.name} value={subcat.name}>
                              {subcat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Deadline</h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showDeadline}
                        onChange={(e) => {
                          if (showDeadline) {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`deadline`]: "",
                            }));
                            setShowDeadline(!showDeadline);
                          } else {
                            setShowDeadline(!showDeadline);
                          }
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showDeadline ? (
                    <>
                      <Calendar
                        selectRange={false}
                        defaultValue={currentBlog.deadline}
                        onChange={(e: any) => {
                          handleDateChange(e as Date);
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Urgent</h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={currentBlog.urgent}
                        onChange={(e) => {
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`urgent`]: !currentBlog.urgent,
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {newBlog ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Multiple Article </h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={currentBlog.multiple}
                            onChange={(e) => {
                              if (!currentBlog.multiple) {
                                setCurrentBlog((prevFormData) => ({
                                  ...prevFormData,
                                  [`url`]: "  ",
                                }));
                              }
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`multiple`]: !currentBlog.multiple,
                              }));

                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {currentBlog.multiple ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingBottom: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: "small", color: "gray" }}>
                              {
                                "For Separate Blogs With Comma Example:( Keyword1 Keyword2 , Keyoword3 )"
                              }{" "}
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      padding: "0rem 0rem 1rem 0rem",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      style={{
                        maxWidth: "100%",
                      }}
                      name="keywords"
                      className="textfield"
                      required
                      id="outlined-required"
                      label="Keywords"
                      placeholder="CompanyName"
                      value={
                        currentBlog.keywords.length > 0
                          ? currentBlog.keywords
                          : ""
                      }
                      onChange={handleValueChange}
                    />
                    {/* <TextField
                    style={{
                      maxWidth: "100%",

                      padding: "0rem 0.5rem",
                    }}
                    name="url"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Google Doc Url"
                    placeholder="Url"
                    value={currentBlog.url.length > 0 ? currentBlog.url : ""}
                    onChange={handleValueChange}
                  />
                  <a
                    style={{ marginLeft: "1rem" }}
                    href={currentBlog.url}
                    target="_blank"
                  >
                    {currentBlog.url.length > 0 ? "Link" : ""}
                  </a> */}
                  </div>
                  {login ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>General Guidelines </h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={generalGuidelines}
                            onChange={(e) => {
                              setGeneralGuidelines(!generalGuidelines);
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`general_guidelines`]: Boolean(
                                  !currentBlog.general_guidelines
                                ),
                              }));
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                    </>
                  )}

                  {generalGuidelines && !newBlog ? (
                    <>
                      <div
                        id="company_guidelines"
                        style={{
                          width: "100%",
                          // display: "flex",
                          gap: "15px",
                          display: "grid",
                          // gridTemplateColumns: "repeat(3, 1fr)",
                          // justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        {adminGuidelines.map((guideline: Guideline) => {
                          const doc = guideline.guideline_data.startsWith(
                            "http"
                          );
                          return (
                            <>
                              <div className="guideline_css">
                                {guideline.guideline_name}{" "}
                                <div>
                                  <RemoveRedEyeIcon
                                    onClick={() =>
                                      handleEyeIconClick(
                                        guideline.guideline_uuid
                                      )
                                    }
                                  />
                                  {/* <input
                                        type="checkbox"
                                        value="myCheckbox" // Assigning a value to the checkbox
                                        checked={selectedGuidelines.includes(
                                          guideline.guideline_uuid
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            guideline.guideline_uuid
                                          )
                                        }
                                        style={checkboxStyle}
                                      />{" "} */}
                                </div>
                              </div>
                              {showGuidelineEditor ===
                                guideline.guideline_uuid && (
                                <>
                                  {doc ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ReactQuill
                                          readOnly={true}
                                          style={{ width: "100%" }}
                                          value={guideline.guideline_data}
                                        />
                                        <a
                                          href={guideline.guideline_data}
                                          target="_blank"
                                        >
                                          {" "}
                                          Link
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <ReactQuill
                                        readOnly={true}
                                        style={{ width: "100%" }}
                                        value={guideline.guideline_data}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {newBlog ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Company Guidelines </h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={showCompanyGuidelines}
                            onChange={(e) => {
                              setShowCompanyGuidelines(!showCompanyGuidelines);
                              setSelectedGuidelines([]);
                              setShowGuidelineEditor("");
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`guideline`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {showCompanyGuidelines ? (
                        <>
                          <div
                            id="company_guidelines"
                            style={{
                              width: "100%",
                              // display: "flex",
                              gap: "15px",
                              display: "grid",
                              // gridTemplateColumns: "repeat(3, 1fr)",
                              // justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {guidelines.map((guideline: Guideline) => {
                              const doc = guideline.guideline_data.startsWith(
                                "http"
                              );
                              return (
                                <>
                                  <div className="guideline_css">
                                    {guideline.guideline_name}{" "}
                                    <div>
                                      <RemoveRedEyeIcon
                                        onClick={() =>
                                          handleEyeIconClick(
                                            guideline.guideline_uuid
                                          )
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        value="myCheckbox" // Assigning a value to the checkbox
                                        checked={selectedGuidelines.includes(
                                          guideline.guideline_uuid
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            guideline.guideline_uuid
                                          )
                                        }
                                        style={checkboxStyle}
                                      />{" "}
                                    </div>
                                  </div>
                                  {showGuidelineEditor ===
                                    guideline.guideline_uuid && (
                                    <>
                                      {doc ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <ReactQuill
                                              readOnly={true}
                                              style={{ width: "100%" }}
                                              value={guideline.guideline_data}
                                            />
                                            <a
                                              href={guideline.guideline_data}
                                              target="_blank"
                                            >
                                              {" "}
                                              Link
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <ReactQuill
                                            readOnly={true}
                                            style={{ width: "100%" }}
                                            value={guideline.guideline_data}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Company Guidelines</h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={showCompanyGuidelines}
                            onChange={(e) => {
                              setShowCompanyGuidelines(!showCompanyGuidelines);
                              setSelectedGuidelines([]);
                              setShowGuidelineEditor("");
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`guideline`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {showCompanyGuidelines ? (
                        <>
                          <div
                            id="company_guidelines"
                            style={{
                              width: "100%",
                              // display: "flex",
                              gap: "15px",
                              display: "grid",
                              // gridTemplateColumns: "repeat(3, 1fr)",
                              // justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {guidelines.map((guideline: Guideline) => {
                              const doc = guideline.guideline_data.startsWith(
                                "http"
                              );
                              return (
                                <>
                                  <div className="guideline_css">
                                    {guideline.guideline_name}{" "}
                                    <div>
                                      <RemoveRedEyeIcon
                                        onClick={() =>
                                          handleEyeIconClick(
                                            guideline.guideline_uuid
                                          )
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        value="myCheckbox" // Assigning a value to the checkbox
                                        checked={selectedGuidelines.includes(
                                          guideline.guideline_uuid
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            guideline.guideline_uuid
                                          )
                                        }
                                        style={checkboxStyle}
                                      />{" "}
                                    </div>
                                  </div>
                                  {showGuidelineEditor ===
                                    guideline.guideline_uuid && (
                                    <>
                                      {doc ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <ReactQuill
                                              readOnly={true}
                                              style={{ width: "100%" }}
                                              value={guideline.guideline_data}
                                            />
                                            <a
                                              href={guideline.guideline_data}
                                              target="_blank"
                                            >
                                              {" "}
                                              Link
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <ReactQuill
                                            readOnly={true}
                                            style={{ width: "100%" }}
                                            value={guideline.guideline_data}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Additional Guideline </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showAdditionalGuidelines}
                        onChange={(e) => {
                          setShowAdditionalGuidelines(
                            !showAdditionalGuidelines
                          );
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`guideline`]: "",
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showAdditionalGuidelines ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <ReactQuill
                          style={{ width: "100%" }}
                          value={currentBlog.additional_guidelines}
                          onChange={(content) => {
                            console.log();
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`additional_guidelines`]: content,
                            }));
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Create Google Doc </h3>

                    {newBlog ? (
                      <>
                        {" "}
                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            // disabled= {? true :false}
                            checked={
                              currentBlog.url.length > 0
                                ? true
                                : createGoogleDoc
                            }
                            onChange={(e) => {
                              setCreateGoogleDoc(!createGoogleDoc);
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`url`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {createGoogleDoc &&
                  currentBlog.url.length === 0 &&
                  !currentBlog.multiple ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          Add custom link ?{" "}
                          <span
                            onClick={() => {
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`url`]: "Add url here...",
                              }));
                            }}
                            style={{ fontWeight: "bold" }}
                            className="hover"
                          >
                            Click here
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {(currentBlog.url.length > 0 || !newBlog) &&
                  !currentBlog.multiple ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          style={{
                            maxWidth: "100%",
                          }}
                          name="url"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Google Docs Url"
                          placeholder="Google Docs Url"
                          value={
                            currentBlog.url.length > 0 ? currentBlog.url : ""
                          }
                          onChange={handleValueChange}
                        />
                        <a
                          style={{ marginLeft: "1rem" }}
                          href={currentBlog?.url}
                          target="_blank"
                        >
                          {currentBlog?.url?.length > 0 ? "Link" : ""}
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Example Article </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showExampleArticle}
                        onChange={(e) => {
                          setShowExampleArticle(!showExampleArticle);
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`example_article`]: [],
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showExampleArticle ? (
                    <>
                      {/* Render each example article with delete button */}
                      {Array.isArray(currentBlog.example_article) &&
                        currentBlog.example_article.map((example, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "100%",
                            }}
                          >
                            <TextField
                              style={{
                                minWidth: "60%",
                                padding: "0.5rem 0rem",
                              }}
                              value={example}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setCurrentBlog((prevBlog) => ({
                                  ...prevBlog,
                                  example_article: [
                                    ...prevBlog.example_article.slice(0, index),
                                    newValue,
                                    ...prevBlog.example_article.slice(
                                      index + 1
                                    ),
                                  ],
                                }));
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                setCurrentBlog((prevBlog) => ({
                                  ...prevBlog,
                                  example_article: [
                                    ...prevBlog.example_article.slice(0, index),
                                    ...prevBlog.example_article.slice(
                                      index + 1
                                    ),
                                  ],
                                }));
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}
                      {/* Add button to add another example article */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>Add another example</span>
                        <IconButton
                          className="hover"
                          style={{
                            color: "white",
                            padding: "0.1rem",
                            borderRadius: "50px",
                            background: "blue",
                          }}
                          onClick={() => {
                            setCurrentBlog((prevBlog) => ({
                              ...prevBlog,
                              example_article: [
                                ...(prevBlog.example_article || []),
                                "",
                              ],
                            }));
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                      {/* <div style={{display:'flex', flexDirection:'row', userSelect:'none', padding:'1rem 0rem', justifyContent:'start', gap:'10px', alignItems:'center'}}>
                          <span>Add another example</span> <AddIcon className="hover" style={{color:'white', padding:'0.1rem', borderRadius:'50px', background:'blue'}} />
                          </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Additional Information </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showAdditionalInfo}
                        onChange={(e) => {
                          setShowAdditionalInfo(!showAdditionalInfo);
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`additional_info`]: "",
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showAdditionalInfo ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <ReactQuill
                          style={{ width: "100%" }}
                          value={currentBlog.additional_info}
                          onChange={(content) => {
                            console.log();
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`additional_info`]: content,
                            }));
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {login ? (
                    <></>
                  ) : (
                    <>
                      {!newBlog ? (
                        <>
                          <div
                            style={{
                              padding: "1rem 0rem",
                              alignItems: "center",
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <TextField
                                style={{
                                  maxWidth: "100%",
                                }}
                                name="docs_name"
                                className="textfield"
                                required
                                id="outlined-required"
                                label="Enter the whole text from the blog"
                                placeholder="Url"
                                multiline={true}
                                rows={4}
                                // value={
                                //   blog.url.length > 0
                                //     ? blog.url
                                //     : ""
                                // }
                                onChange={(e) => {
                                  // ""
                                  // console.log(e.currentTarget.value)
                                  let text = e.currentTarget.value;
                                  let words = text
                                    .split(/\s+/)
                                    .filter((word) => word.length > 0);
                                  // const { name, value } = e.target;
                                  setCurrentBlog((prevFormData) => ({
                                    ...prevFormData,
                                    [`words`]: words.length,
                                  }));

                                  // console.log(words.length);
                                  // updateWords(index, words.length);
                                  // console.log(index);
                                  // console.log();
                                }}
                              />
                            </div>
                          </div>
                          <div className="bottom_view">
                            <TextField
                              name="words"
                              className="textfield"
                              required
                              id="outlined-required"
                              label="Words"
                              placeholder="Url"
                              value={
                                currentBlog.words > 0 ? currentBlog.words : ""
                              }
                              onChange={(e) => {
                                setCurrentBlog((prevFormData) => ({
                                  ...prevFormData,
                                  [`words`]: Number(e.currentTarget.value),
                                }));
                              }}
                            />
                            <span
                              className="board_hide"
                              style={{ padding: "0rem 1rem" }}
                              onClick={() => {
                                setCurrentBlog((prevFormData) => ({
                                  ...prevFormData,
                                  [`amount`]: Number(
                                    (currentBlog.words * 0.005).toFixed(2)
                                  ),
                                }));
                              }}
                            >
                              Calculate
                            </span>
                            <TextField
                              name="docs_name"
                              className="textfield"
                              required
                              id="outlined-required"
                              label="Amount"
                              placeholder="Amount"
                              value={
                                currentBlog.amount > 0 ? currentBlog.amount : ""
                              }
                              onChange={(e) => {
                                setCurrentBlog((prevFormData) => ({
                                  ...prevFormData,
                                  [`amount`]: Number(e.currentTarget.value),
                                }));
                              }}
                            />
                            <FormControl
                              style={{
                                maxWidth: "100%",
                                minWidth: "100%",
                              }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Blog Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  currentBlog.status.length > 0
                                    ? currentBlog.status
                                    : "Pending"
                                }
                                label="Company Name"
                                onChange={handleChangeStatus}
                              >
                                <MenuItem value={"Pending"}>Pending</MenuItem>
                                <MenuItem value={"In Progress"}>
                                  In Progress
                                </MenuItem>
                                {/* <MenuItem value={10}>Finished</MenuItem> */}
                                <MenuItem value={"Written"}>Written</MenuItem>
                                <MenuItem value={"Editor"}>Editor</MenuItem>
                                <MenuItem value={"Completed"}>
                                  Completed
                                </MenuItem>
                                <MenuItem value={"Revision"}>Revision</MenuItem>
                                <MenuItem value={"Failed"}>Failed</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {/* Content of the additional div */}
                </>
              )}
            </div>
          </div>

          <div className="blog_buttons">
            <Button
              style={{
                // margin: "0.5rem",
                color: "white",
                // width: "15%",
                marginRight: "1rem",
                // border: "1px solid  black",
                background: "gray",
              }}
              startIcon={<CloseIcon />}
              onClick={() => {
                //   handleAdminTimeslotClick(index);
                setCreateGoogleDoc(false);
                setShowExampleArticle(false);
                setShowCompanyGuidelines(false);
                setShowDeadline(false);
                setGeneralGuidelines(false);
                setShowAdditionalGuidelines(false);
                setShowAdditionalInfo(false);
                setSelectedGuidelines([]);
                setContact(!contact);
                setCurrentBlog({
                  blog_uuid: "",
                  amount: 0,
                  multiple: false,
                  blog_title: "",
                  company_name: "",
                  deadline: "",
                  urgent: false,
                  project_name: "",
                  keywords: "",
                  status: "",
                  company_uuid: "",
                  general_guidelines: false,
                  company_guidelines: [],
                  additional_guidelines: "",
                  writer_uuid: "",
                  url: "",
                  words: 0,
                  editor_name: "",
                  editor_uuid: "",
                  writer: "",
                  category: "",
                  sub_category: "",
                  additional_info: "",
                  blog_length: 0,
                  example_article: [],
                  tools: [],
                });
              }}
              // variant="outlined"
            >
              {getTranslation("buttons.cancel")}
            </Button>
            <Button
              style={{
                // margin: "0.5rem",
                color: "white",
                // width: "15%",
                // border: "1px solid  black",
                background: "#1d73ed",
              }}
              startIcon={<SaveIcon />}
              onClick={() => {
                //   "";
                if (newBlog) {
                  createNewBlog();
                } else {
                  saveChanges();
                }
                // handleAdminTimeslotClick(index);
              }}
              variant="outlined"
            >
              {newBlog ? (
                <> {getTranslation("buttons.create")}</>
              ) : (
                <>{getTranslation("buttons.save")}</>
              )}
            </Button>
          </div>
          {/* Content of the additional div */}
        </div>
      </>
    );
  };
  const renderProject = () => {
    return (
      <>
        <div className="assign_blogs">
          <div className="additional-div blog_css">
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="project_name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Project Name"
                    placeholder="ProjectName"
                    value={newProjectName.length > 0 ? newProjectName : ""}
                    onChange={(e) => {
                      setNewProjectName(e.currentTarget.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setCreateProject(!createProject);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      createNewProject();
                    }}
                    variant="outlined"
                  >
                    <>{getTranslation("buttons.create")}</>
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderDelete = () => {
    return (
      <>
        <div className="assign_blogs">
          <div className="additional-div blog_css">
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                {!deleteProject ? (
                  <>
                    <h1 style={{ marginBottom: "0rem" }}>Archive Blogs</h1>
                  </>
                ) : (
                  <>
                    <h1 style={{ marginBottom: "0rem" }}>Delete Project</h1>
                  </>
                )}
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ color: "gray" }}>
                    {!deleteProject ? (
                      <>Are you sure that you want to archive ?</>
                    ) : (
                      <>Are you sure that you want to delete ?</>
                    )}
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      border: "none",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      if (deleteProject) {
                        setDeleteProject(!deleteProject);
                      } else {
                        setDeleteBlogs(!deleteBlogs);
                      }
                    }}
                    // variant="outlined"
                  >
                    {"No"}
                    {/* {getTranslation("buttons.cancel")} */}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      border: "none",
                      background: "green",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      if (deleteProject) {
                        handleDeleteProject();
                      } else {
                        setDeleteBlogs(!deleteBlogs);
                        deleteSelectedBlogs();
                      }
                    }}
                    variant="outlined"
                  >
                    {/* <>{getTranslation("buttons.save")}</> */}
                    {"Yes"}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderAssignUser = () => {
    return (
      <>
        <div className="assign_blogs">
          <div className="additional-div blog_css">
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <h1>Assign</h1>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Writer
                    </InputLabel>
                    <Select
                      name="writer"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currentBlog.writer_uuid}
                      label="Writer Name"
                      onChange={handleChange}
                    >
                      {/* assign */}
                      <MenuItem value="">{"None"}</MenuItem>
                      {all_users.map((user: UserLite) => {
                        if (user.roles.includes("Writer")) {
                          return (
                            <MenuItem
                              key={user.user_uuid}
                              value={user.user_uuid}
                            >
                              {user.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </div>

                {showAddEditor ? (
                  <>
                    <div
                      style={{
                        marginBottom: "2rem",
                        alignItems: "center",
                        // display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Editor
                        </InputLabel>
                        <Select
                          name="editor_name"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={currentBlog.editor_uuid}
                          label="Editor"
                          onChange={handleChangeEditor}
                        >
                          <MenuItem value="">{"None"}</MenuItem>
                          {all_users.map((user: UserLite) => {
                            if (user.roles.includes("Editor")) {
                              return (
                                <MenuItem
                                  key={user.user_uuid}
                                  value={user.user_uuid}
                                >
                                  {user.name}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        marginBottom: "2rem",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <span style={{ color: "gray" }}>
                        Do you want to set editor ? Press
                      </span>{" "}
                      <span
                        className="hover"
                        onClick={() => {
                          setShowAddEditor(true);
                        }}
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          paddingLeft: "0.3rem",
                        }}
                      >
                        here
                      </span>
                    </span>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setShowAddEditor(false);
                      setAssignUser(!assignUser);
                      setCurrentBlog({
                        blog_uuid: "",
                        amount: 0,
                        multiple: false,
                        blog_title: "",
                        company_name: "",
                        deadline: "",
                        urgent: false,
                        project_name: "",
                        keywords: "",
                        status: "",
                        company_uuid: "",
                        general_guidelines: false,
                        company_guidelines: [],
                        additional_guidelines: "",
                        writer_uuid: "",
                        url: "",
                        words: 0,
                        editor_name: "",
                        editor_uuid: "",
                        writer: "",
                        category: "",
                        sub_category: "",
                        additional_info: "",
                        blog_length: 0,
                        example_article: [],
                        tools: [],
                      });
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      assignCurrentUser();
                    }}
                    variant="outlined"
                  >
                    {/* <>{getTranslation("buttons.save")}</> */}
                    {"Assign"}
                  </Button>
                </div>
                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="main_container company_view">
        {" "}
        {login ? (
          <></>
        ) : (
          <>
            <div style={{ minWidth: "100%" }}>
              {allCompanies ? (
                <></>
              ) : (
                <>
                  {" "}
                  <h2>{currentCompanyData?.company_name} Home Dashboard</h2>
                </>
              )}
            </div>
          </>
        )}
        {login ? (
          <></>
        ) : (
          <>
            <div
              style={{
                minWidth: "100%",
                minHeight: "5vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  justifyContent: "end",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  //   marginRight: "1rem",
                }}
              >
                {/* <ToggleButtonGroup
              color="primary"
              value={SelectedView}
              exclusive
              onChange={(event, newSelectedView) => {
                setSelectedView(newSelectedView);
                // Other operations you want to perform when the selection changes
              }}
              aria-label="Platform"
              sx={{
                "& .MuiToggleButton-root": {
                  color: "#1d73ed",
                  outline: "#1d73ed",
                  padding: "1rem",
                  maxHeight: "3vh",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#1d73ed",
                  },
                },
                "& .Mui-selected": {
                  color: "white !important", // Custom style for the selected item text color
                  backgroundColor: "#1d73ed  !important", // Custom style for the selected item background color
                  "&:hover": {
                    backgroundColor: "#1d73ed  !important",
                    color: "white", // Maintain the custom style on hover
                  },
                  "& .MuiButtonBase-root": {
                    color: "#1d73ed  !important",
                    // backgroundColor: 'red', // Update the background color of the selected item
                  },
                },
              }}
            >
              <ToggleButton value="company">
                <ApartmentIcon />
              </ToggleButton>
              <ToggleButton value="users">
                <PeopleIcon />
              </ToggleButton>
            </ToggleButtonGroup> */}
                {allCompanies ? (
                  <></>
                ) : (
                  <>
                    <Button
                      style={{
                        // margin: "0.5rem",
                        color: "white",
                        // border: "1px solid  black",
                        background: "#1d73ed",
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={(e: any) => {
                        setNewBlog(true);
                        setCurrentBlog({
                          blog_uuid: "",
                          amount: 0,
                          multiple: false,
                          blog_title: "",
                          company_name: "",
                          deadline: "",
                          category: "",
                          sub_category: "",
                          urgent: false,
                          project_name: "",
                          keywords: "",
                          status: "",
                          company_uuid: "",
                          general_guidelines: false,
                          company_guidelines: [],
                          additional_guidelines: "",
                          writer_uuid: "",
                          url: "",
                          words: 0,
                          editor_name: "",
                          editor_uuid: "",
                          writer: "",
                          additional_info: "",
                          blog_length: 0,
                          example_article: [],
                          tools: [],
                        });
                        setContact(!contact);
                      }}
                      variant="outlined"
                    >
                      {getTranslation("buttons.create_blog")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {SelectedView === "company" ? (
          <>
            <div
              className={"admin_timeslot_header no_hover"}
              style={{
                margin: "0rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={"regular_user"}>
                {/* <div
                      onClick={() => {
                        // currentCompanieBlogs()
                        setSelectedCompany(100);
                      }}
                      className="user_name"
                      style={{
                        padding: "0.2rem 1.5rem",
                        // borderRadius: "50px",
                        fontWeight: "bold",
                        background: 100 === selectedCompany ? "white" : "",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: 100 == selectedCompany ? "#1d73ed" : "",
                        minWidth: "auto",
                      }}
                    >
                      All
                    </div> */}
                {currentCompanyData &&
                currentCompanyData.company_projects &&
                currentCompanyData.company_projects.length > 0 ? (
                  <>
                    {currentCompanyData?.company_projects.map(
                      (project, index) => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setSelectedProject(project);
                                setCheckedItems([]);
                                getProjectBlogs(project);
                              }}
                              className="user_name"
                              style={{
                                padding: "0.2rem 1.5rem",
                                // borderRadius: "50px",
                                fontWeight: "bold",
                                background:
                                  project === selectedProject ? "white" : "",
                                fontSize: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color:
                                  project === selectedProject ? "#1d73ed" : "",
                                minWidth: "auto",
                              }}
                            >
                              {project}
                            </div>
                          </>
                        );
                      }
                    )}
                  </>
                ) : null}

                {allCompanies ? (
                  <></>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        // currentCompanieBlogs()
                        // setSelectedProject(0);
                        setCreateProject(!createProject);
                      }}
                      className="user_name"
                      style={{
                        padding: "0.2rem 1.5rem",
                        // borderRadius: "50px",
                        fontWeight: "bold",
                        background:
                          "all" === selectedProject ? "white" : "blue",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "all" == selectedProject ? "#1d73ed" : "white",
                        minWidth: "auto",
                      }}
                    >
                      <AddIcon />
                    </div>
                  </>
                )}
              </div>
              {true ? (
                <>
                  {" "}
                  <div
                    style={{
                      minHeight: "15vh",
                      width: "100%",
                      background: "white",
                    }}
                  >
                    {/* Headers for Company Info */}
                    <div
                      className={" no_hover"}
                      style={{
                        margin: "2rem 0rem 0rem 0rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          margin: "0rem 1rem 1rem 1rem",
                          flexDirection: "row",
                          gap: "5px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            display: "flex",

                            flexDirection: "row",
                            gap: "5px",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div
                            onClick={() => {
                              setShowBoard(false);
                            }}
                            className={showBoard ? "hover" : ""}
                            style={{
                              display: "flex",
                              userSelect: "none",
                              background: !showBoard ? "lightgray" : "",
                              padding: "0.1rem 0.8rem",
                              borderRadius: "30px",
                              flexDirection: "row",
                              gap: "2px",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <FormatListBulletedIcon />
                            </span>
                            <span>LIST</span>
                          </div>
                          <div
                            onClick={() => {
                              setShowBoard(true);
                            }}
                            className={
                              !showBoard ? "hover board_hide" : "board_hide"
                            }
                            style={{
                              userSelect: "none",
                              borderRadius: "30px",
                              background: showBoard ? "lightgray" : "",
                              padding: "0.1rem 0.8rem",
                              gap: "2px",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <SpaceDashboardIcon />
                            </span>
                            <span>BOARD</span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "100%",
                            paddingTop: "2rem",
                          }}
                        >
                          <div>
                            <HistoryIcon
                              className="hover"
                              onClick={() => {
                                setActiveSpans([]);
                              }}
                            />
                          </div>
                          <div className="active_spans">
                            {[
                              "Pending",
                              "In progress",
                              "Written",
                              "Editor",
                              "Completed",
                              "Revision",
                              "Failed",
                            ].map((span: string) => {
                              console.log(span.replaceAll(" ", "") + "-board");
                              span =
                                span.charAt(0).toUpperCase() +
                                span.slice(1, span.length);
                              return (
                                <>
                                  {" "}
                                  <span
                                    className={`status hover ${
                                      activeSpans.includes(span)
                                        ? ""
                                        : span
                                            .toLocaleLowerCase()
                                            .replaceAll(" ", "") + "-board"
                                    }`}
                                    onClick={() => {
                                      toggleSpan(span);
                                    }}
                                  >
                                    {span}
                                  </span>
                                </>
                              );
                            })}
                          </div>
                        </div>
                        <div
                          className="mobile"
                          style={{
                            display: "grid",
                            flexDirection: "column",
                            justifyContent: "end",
                            // paddingBottom: "1rem",
                          }}
                        >
                          {" "}
                          {login ? (
                            <></>
                          ) : (
                            <>
                              <MoreHorizIcon
                                className="hover"
                                onClick={(e) => {
                                  handleMenuClick(e as any);
                                }}
                              />
                            </>
                          )}
                        </div>
                        {login ? (
                          <>
                            <div>
                              {" "}
                              <Button
                                style={{
                                  // margin: "0.5rem",
                                  color: "white",
                                  // border: "1px solid  black",
                                  background: "#1d73ed",
                                }}
                                startIcon={<AddCircleIcon />}
                                onClick={(e: any) => {
                                  setNewBlog(true);
                                  setCurrentBlog({
                                    blog_uuid: "",
                                    amount: 0,
                                    multiple: false,
                                    blog_title: "",
                                    company_name: "",
                                    deadline: "",
                                    urgent: false,
                                    project_name: "",
                                    keywords: "",
                                    status: "",
                                    company_uuid: "",
                                    general_guidelines: false,
                                    company_guidelines: [],
                                    additional_guidelines: "",
                                    writer_uuid: "",
                                    url: "",
                                    words: 0,
                                    editor_name: "",
                                    category: "",
                                    sub_category: "",
                                    editor_uuid: "",
                                    writer: "",
                                    additional_info: "",
                                    blog_length: 0,
                                    example_article: [],
                                    tools: [],
                                  });
                                  setContact(!contact);
                                }}
                                variant="outlined"
                              >
                                {getTranslation("buttons.create_blog")}
                              </Button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    {true ? <></>:<>  <div
                        style={{
                          minHeight: "4vh",
                          alignItems: "normal",
                          margin: "0rem 1rem",
                          display: "flex",
                          flexDirection: "row",
                          alignContent:'start',
                          textAlign:'start',
                          justifyContent: "start",
                        }}
                      >
                        <div>
                            <HistoryIcon
                              className="hover"
                              onClick={() => {
                                setActiveCategories([]);
                                setActiveSubCategories([]);
                                filterBlogsByCategoryAndSubcategory()
                              }}
                            />
                          </div>
                          <div style={{display:'flex', minWidth:'90%', justifyContent:'space-between'}}>
                            {categories.map((category: any) => {

                              return (
                                <div style={{display:'flex', margin:'0rem 0.2rem', flexDirection:'column'}}>
                                  <span
                                  style={{margin:'0rem 0rem 0.5rem 0rem', padding:'0.3rem 0.5rem', textAlign:'center', borderRadius:5}}
                                    className={`status hover ${
                                      activeCategories.includes(category.name)
                                        ? "active_category"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      toggleCategory(category.name);
                                    }}
                                  >
                                    {category.name}
                                  </span>
                                  <div style={{display:'flex', flexDirection:'column', padding:'0.3rem 0rem'}}>
                                    {category.subcategories.map((subcategory: any) => {
                                      if (subcategory.name === category.name) {
                                        return <></>
                                      }
                                      return (
                                        <>
                                          {" "}
                                          <span
                                          style={{padding:'0.5rem 0rem'}}
                                            className={` hover ${
                                              activeSubCategories.includes(subcategory.name)
                                                ? "active_subcategory"
                                                :""
                                            }`}
                                            onClick={() => {
                                              toggleSubCategory(subcategory.name);
                                            }}
                                          >
                                            {subcategory.name}
                                          </span>
                                        </>
                                      );
                                    })}
                                  </div>

                                </div>
                              );
                            })}
                          </div>

                        {/* <div
                        className="hide_horizontal"
                          style={{
                            display: "grid",
                            flexDirection: "column",
                            justifyContent: "end",
                            // paddingBottom: "1rem",
                          }}
                        >
                          {" "}
                          {login ? (
                            <></>
                          ) : (
                            <>
                              <MoreHorizIcon
                                className="hover"
                                onClick={(e) => {
                                  handleMenuClick(e as any);
                                }}
                              />
                            </>
                          )}
                        </div> */}
                        <ContextMenu
                          anchorEl={anchorEl}
                          onClose={handleCloseMenu}
                          onSlackNotifications={handleSlackNotifications}
                          onMakeInactive={handleMakeInactive}
                          onDelete={() => {
                            handleCloseMenu();
                            setDeleteBlogs(!deleteBlogs);
                            setDeleteProject(!deleteProject);
                          }}
                        />
                      </div></>}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent:
                            checkedItems.length > 0 ? "space-between" : "end",
                          minHeight: "5vh",
                          alignItems: "center",
                          margin: "1rem",
                        }}
                      >
                        {checkedItems.length > 0 ? (
                          <>
                            <div style={{ maxWidth: "50%", minWidth: "50%" }}>
                              <></>
                              <></>
                              {login ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    style={{
                                      // margin: "0.5rem",
                                      color: "white",
                                      // width: "15%",
                                      marginRight: "1rem",
                                      fontWeight: "bold",
                                      //   border: "1px solid  ",
                                      background: "green",
                                    }}
                                    // startIcon={<CloseIcon />}
                                    onClick={() => {
                                      setAssignUser(!assignUser);
                                      setCurrentBlog({
                                        blog_uuid: "",
                                        amount: 0,
                                        multiple: false,
                                        blog_title: "",
                                        company_name: "",
                                        deadline: "",
                                        urgent: false,
                                        project_name: "",
                                        keywords: "",
                                        status: "",
                                        company_uuid: "",
                                        general_guidelines: false,
                                        company_guidelines: [],
                                        additional_guidelines: "",
                                        writer_uuid: "",
                                        url: "",
                                        words: 0,
                                        category: "",
                                        sub_category: "",
                                        editor_name: "",
                                        editor_uuid: "",
                                        writer: "",
                                        additional_info: "",
                                        blog_length: 0,
                                        example_article: [],
                                        tools: [],
                                      });
                                    }}
                                    // variant="outlined"
                                  >
                                    <div
                                      onClick={() => {
                                        setAssignUser(!assignUser);
                                      }}
                                    >
                                      {"Assign"}
                                    </div>
                                  </Button>
                                </>
                              )}
                              <Button
                                style={{
                                  // margin: "0.5rem",
                                  color: "white",
                                  // width: "15%",
                                  marginRight: "1rem",
                                  fontWeight: "bold",
                                  background: "red",
                                }}
                                // startIcon={<DeleteIcon />}
                                onClick={() => {
                                  setDeleteBlogs(!deleteBlogs);
                                }}
                                // variant="outlined"
                              >
                                {"Archive"}
                              </Button>
                              {login ? (
                                <></>
                              ) : (
                                <>
                                  <Button
                                    style={{
                                      // margin: "0.5rem",
                                      color: "white",
                                      // width: "15%",
                                      marginRight: "1rem",

                                      fontWeight: "bold",
                                      background: "black",
                                    }}
                                    // startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      setCreateProject(!createProject);
                                    }}
                                    // variant="outlined"
                                  >
                                    {"Paid"}
                                    {/* {getTranslation("buttons.delete")} */}
                                  </Button>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {login ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <div style={{ display: "grid" }}>
                              <span
                                style={{
                                  padding: "0rem 0.8rem",
                                  fontSize: "13px",
                                  textAlign: "center",
                                  color: "#1d73ed",
                                }}
                              >
                                Amount (USD)
                              </span>
                              <span
                                style={{
                                  padding: "0rem 0.8rem",
                                  fontSize: "35px",
                                  textAlign: "center",
                                }}
                              >
                                ${computePaymentCompany()}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      {/* add tuka */}
                      {showBoard ? (
                        <></>
                      ) : (
                        <>
                          <div className={"regular company_headers "}>
                            <div className="actions_header">
                              <span className="board_hide"> Actions</span>
                            </div>
                            <div className="topic_header">
                              <span style={{ fontWeight: "bold" }}>Topic</span>
                            </div>
                            {/* <div
                          style={{
                            padding: "0.2rem 0.5rem",
                            // border: "1px solid gray",
                            borderRadius: "50px",
                            fontSize: "15px",
                            maxWidth: "20%",
                            fontWeight: "bold",
                            minWidth: "20%",
                          }}
                        >
                          Blog Title
                        </div> */}
                            <div className="url_header">URL</div>
                            {login ? (
                              <></>
                            ) : (
                              <>
                                <div
                                  className="board_hide"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    justifyContent: "center",
                                    maxWidth: "15%",
                                    fontWeight: "bold",
                                    minWidth: "15%",
                                  }}
                                >
                                  Assign To
                                </div>
                              </>
                            )}

                            <div
                              className="board_hide"
                              style={{
                                padding: "0.2rem 0.5rem",
                                // border: "1px solid gray",
                                borderRadius: "50px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                maxWidth: "6%",
                                minWidth: "6%",
                                justifyContent: "center",
                              }}
                            >
                              Words
                            </div>
                            {login ? (
                              <></>
                            ) : (
                              <>
                                <div
                                  className="board_hide"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "6%",
                                    justifyContent: "center",
                                    minWidth: "6%",
                                  }}
                                >
                                  Amount
                                </div>{" "}
                              </>
                            )}
                            <div className="status_header">Status</div>
                            <div
                              className="board_hide"
                              style={{
                                padding: "0.2rem 0.5rem",
                                // border: "1px solid gray",
                                borderRadius: "50px",
                                fontSize: "15px",
                                fontWeight: "bold",
                                maxWidth: "8%",
                                justifyContent: "center",
                                minWidth: "8%",
                              }}
                            >
                              Deadline
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="table table_rows">
                      {/* list */}
                      {showBoard ? (
                        <>
                          <div
                            className="reservation admin-timeslot-container"
                            style={{
                              gap: "10px",
                              display: "flex",
                              flexDirection: "row",
                              maxWidth: "100%",
                              overflowX: "auto",
                              justifyContent: "center",
                              overflowY: "scroll",
                            }}
                          >
                            {!activeSpans.includes("Pending") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    maxHeight: "50vh",
                                    minHeight: "50vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="PENDING"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "pending"
                                      ).length
                                    }
                                    customecolor="gray"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        // Check if the current blog is selected
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "pending"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {/* In Progress */}
                            {!activeSpans.includes("In progress") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="IN PROGRESS"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "in progress"
                                      ).length
                                    }
                                    customecolor="blue"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "in progress"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                            {/* Written */}
                            {!activeSpans.includes("Written") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="WRITTEN"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "written"
                                      ).length
                                    }
                                    customecolor="lightgreen"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "written"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                            {/* editor */}
                            {!activeSpans.includes("Editor") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="EDITOR"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "editor"
                                      ).length
                                    }
                                    customecolor="orange"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "editor"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                            {/* completed */}
                            {!activeSpans.includes("Completed") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="COMPLETED"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "completed"
                                      ).length
                                    }
                                    customecolor="green"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "completed"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                            {/* revision */}
                            {!activeSpans.includes("Revision") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="REVISION"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "revision"
                                      ).length
                                    }
                                    customecolor="purple"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "revision"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}

                            {/* failed */}
                            {!activeSpans.includes("Failed") ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    minWidth: "20%",
                                    minHeight: "40vh",
                                    display: "inline-block",
                                    // background: "red",
                                  }}
                                >
                                  <CustomDiv
                                    name="FAILED"
                                    number={
                                      currentCompanieBlogs.filter(
                                        (item) =>
                                          item.status.toLocaleLowerCase() ===
                                          "failed"
                                      ).length
                                    }
                                    customecolor="red"
                                  />
                                  {currentCompanieBlogs.length > 0 ? (
                                    filterBlogsByCategoryAndSubcategory().map(
                                      (blog: SuperAdminBlog, index: number) => {
                                        const isSelected = checkedItems.includes(
                                          index
                                        );
                                        if (
                                          blog.status.toLocaleLowerCase() ==
                                          "failed"
                                        ) {
                                          return (
                                            <div
                                              onClick={() => {
                                                // Toggle selection on click
                                                if (isSelected) {
                                                  // If already selected, remove from the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) =>
                                                      prevCheckedItems.filter(
                                                        (item) => item !== index
                                                      )
                                                  );
                                                } else {
                                                  // If not selected, add to the array
                                                  setCheckedItems(
                                                    (prevCheckedItems) => [
                                                      ...prevCheckedItems,
                                                      index,
                                                    ]
                                                  );
                                                }
                                              }}
                                            >
                                              <BlogPaper
                                                blog={blog}
                                                checked={isSelected}
                                              />
                                            </div>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {allCompanies ? (
                            <>
                              <div className="reservation admin-timeslot-container">
                                {activeSpans.length > 0 ? (
                                  <>
                                    <h3>Overdue</h3>
                                    {/* OVERDUE */}
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;
                                          let status =
                                            blog.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.status
                                              .slice(1, blog.status.length)
                                              .toLocaleLowerCase();

                                          // "check the moment"
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );

                                          // Check if today's date is before the blog deadline
                                          const isDeadlineBeforeToday = deadlineMoment.isBefore(
                                            moment().subtract(1, "days")
                                          );

                                          if (
                                            !activeSpans.includes(status) &&
                                            isDeadlineBeforeToday
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <h3>Today</h3>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;
                                          let status =
                                            blog.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.status
                                              .slice(1, blog.status.length)
                                              .toLocaleLowerCase();
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );

                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineToday = deadlineMoment.isSame(
                                            moment(),
                                            "day"
                                          );

                                          if (
                                            !activeSpans.includes(status) &&
                                            isDeadlineToday
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <h3>Tomorrow</h3>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;
                                          let status =
                                            blog.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.status
                                              .slice(1, blog.status.length)
                                              .toLocaleLowerCase();

                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );
                                          const tomorrow = moment().add(
                                            1,
                                            "days"
                                          );
                                          debugger;
                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineTomorrow = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          ).isSame(tomorrow, "day");
                                          console.log(">>>>>>>>>>>>>");

                                          if (
                                            !activeSpans.includes(status) &&
                                            isDeadlineTomorrow
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <h3>No Deadline</h3>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;
                                          let status =
                                            blog.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.status
                                              .slice(1, blog.status.length)
                                              .toLocaleLowerCase();

                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );
                                          const tomorrow = moment().add(
                                            1,
                                            "days"
                                          );
                                          debugger;
                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineTomorrow = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          ).isSame(tomorrow, "day");
                                          console.log(">>>>>>>>>>>>>");

                                          if (
                                            !activeSpans.includes(status) &&
                                            blog.deadline.length === 0
                                          ) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <h3>Overdue</h3>
                                    {/* OVERDUE */}
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;

                                          // "check the moment"
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );

                                          // Check if today's date is before the blog deadline
                                          const isDeadlineBeforeToday = deadlineMoment.isBefore(
                                            moment().subtract(1, "days")
                                          );
                                          // const isDeadlineToday =  deadlineMoment.isSame(moment(), 'day');
                                          console.log(">>>>>>>>>>>>>");
                                          if (isDeadlineBeforeToday) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                          onClick={() => {
                                            setContact(!contact);
                                            setCurrentBlog(blog);
                                            setNewBlog(false);
                                          }}
                                          style={{
                                            padding: "0.2rem 0.5rem",
                                            // border: "1px solid gray",
                                            borderRadius: "50px",
                                            fontSize: "15px",
                                            maxWidth: "20%",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            minWidth: "20%",
                                          }}
                                        >
                                          {blog.blog_title}
                                        </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <h3>Today</h3>
                                    {/* TODAY */}
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;

                                          // "check the moment"
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );

                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineToday = deadlineMoment.isSame(
                                            moment(),
                                            "day"
                                          );
                                          console.log(">>>>>>>>>>>>>");
                                          if (isDeadlineToday) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                          onClick={() => {
                                            setContact(!contact);
                                            setCurrentBlog(blog);
                                            setNewBlog(false);
                                          }}
                                          style={{
                                            padding: "0.2rem 0.5rem",
                                            // border: "1px solid gray",
                                            borderRadius: "50px",
                                            fontSize: "15px",
                                            maxWidth: "20%",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            minWidth: "20%",
                                          }}
                                        >
                                          {blog.blog_title}
                                        </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {/* Tomorrow */}
                                    <h3>Tomorrow</h3>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;

                                          // "check the moment"
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );
                                          const tomorrow = moment().add(
                                            1,
                                            "days"
                                          );
                                          debugger;
                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineTomorrow = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          ).isSame(tomorrow, "day");
                                          console.log(">>>>>>>>>>>>>");
                                          if (isDeadlineTomorrow) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                          onClick={() => {
                                            setContact(!contact);
                                            setCurrentBlog(blog);
                                            setNewBlog(false);
                                          }}
                                          style={{
                                            padding: "0.2rem 0.5rem",
                                            // border: "1px solid gray",
                                            borderRadius: "50px",
                                            fontSize: "15px",
                                            maxWidth: "20%",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            minWidth: "20%",
                                          }}
                                        >
                                          {blog.blog_title}
                                        </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {/* No Deadline */}
                                    <h3>No Deadline</h3>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;

                                          // "check the moment"
                                          const deadlineMoment = moment(
                                            blog.deadline,
                                            "MM-DD-YYYY"
                                          );
                                          const tomorrow = moment().add(
                                            1,
                                            "days"
                                          );
                                          debugger;
                                          // Check if today's date is before the blog deadline
                                          // const isDeadlineAfterToday = deadlineMoment.isAfter(moment());
                                          const isDeadlineTomorrow =
                                            blog.deadline.length === 0
                                              ? true
                                              : false;
                                          console.log(">>>>>>>>>>>>>");
                                          if (isDeadlineTomorrow) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                          onClick={() => {
                                            setContact(!contact);
                                            setCurrentBlog(blog);
                                            setNewBlog(false);
                                          }}
                                          style={{
                                            padding: "0.2rem 0.5rem",
                                            // border: "1px solid gray",
                                            borderRadius: "50px",
                                            fontSize: "15px",
                                            maxWidth: "20%",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            minWidth: "20%",
                                          }}
                                        >
                                          {blog.blog_title}
                                        </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="reservation admin-timeslot-container">
                                {activeSpans.length > 0 ? (
                                  <>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;
                                          let status =
                                            blog.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            blog.status
                                              .slice(1, blog.status.length)
                                              .toLocaleLowerCase();

                                          if (!activeSpans.includes(status)) {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    background: urgent
                                                      ? "#ffdfe3"
                                                      : "",
                                                  }}
                                                  className={
                                                    selectedBlogIndex !== index
                                                      ? "admin_timeslot"
                                                      : "admin_timeslot_opened"
                                                  }
                                                >
                                                  <div
                                                    className={"regular"}
                                                    style={{ padding: "0rem" }}
                                                  >
                                                    <div className="admin_row_css_checkbox">
                                                      <label style={labelStyle}>
                                                        <input
                                                          type="checkbox"
                                                          value="myCheckbox" // Assigning a value to the checkbox
                                                          checked={isChecked}
                                                          onChange={() => {
                                                            if (isChecked) {
                                                              // If already checked, remove from the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) =>
                                                                  prevCheckedItems.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      index
                                                                  )
                                                              );
                                                            } else {
                                                              // If not checked, add to the array
                                                              setCheckedItems(
                                                                (
                                                                  prevCheckedItems
                                                                ) => [
                                                                  ...prevCheckedItems,
                                                                  index,
                                                                ]
                                                              );
                                                            }
                                                          }}
                                                          className="checkbox_css"
                                                        />
                                                      </label>
                                                    </div>
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_keywords"
                                                    >
                                                      {blog.keywords}
                                                    </div>
                                                    {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                    <div className="admin_row_css_url">
                                                      {blog.url.length > 0 ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                              color: "blue",
                                                            }}
                                                            onClick={() => {
                                                              window.open(
                                                                blog.url,
                                                                "_blank"
                                                              );
                                                            }}
                                                          >
                                                            Link
                                                          </a>
                                                        </>
                                                      ) : (
                                                        <>No link</>
                                                      )}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            maxWidth: "15%",
                                                            fontWeight: "bold",
                                                            minWidth: "15%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.writer}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent:
                                                          "center",
                                                        maxWidth: "5%",
                                                        minWidth: "5%",
                                                      }}
                                                    >
                                                      {blog.blog_length}
                                                    </div>
                                                    {login ? (
                                                      <></>
                                                    ) : (
                                                      <>
                                                        <div
                                                          onClick={() => {
                                                            if (
                                                              blog
                                                                .company_guidelines
                                                                ?.length > 0
                                                            ) {
                                                              setShowCompanyGuidelines(
                                                                true
                                                              );
                                                              const commonList = guidelines
                                                                .filter(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    blog.company_guidelines.includes(
                                                                      item.guideline_uuid
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    item: Guideline
                                                                  ) =>
                                                                    item.guideline_uuid
                                                                );
                                                              setSelectedGuidelines(
                                                                commonList
                                                              );
                                                            } else {
                                                              setShowCompanyGuidelines(
                                                                false
                                                              );
                                                            }
                                                            setContact(
                                                              !contact
                                                            );
                                                            setupCurrentBlog(
                                                              blog
                                                            );
                                                            setNewBlog(false);
                                                          }}
                                                          className="board_hide"
                                                          style={{
                                                            padding:
                                                              "0.2rem 0.5rem",
                                                            // border: "1px solid gray",
                                                            borderRadius:
                                                              "50px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            maxWidth: "6%",
                                                            minWidth: "6%",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          {blog.amount + "$"}
                                                        </div>
                                                      </>
                                                    )}
                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="admin_row_css_status"
                                                    >
                                                      {getRender(blog.status)}
                                                    </div>

                                                    <div
                                                      onClick={() => {
                                                        if (
                                                          blog
                                                            .company_guidelines
                                                            ?.length > 0
                                                        ) {
                                                          setShowCompanyGuidelines(
                                                            true
                                                          );
                                                          const commonList = guidelines
                                                            .filter(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                blog.company_guidelines.includes(
                                                                  item.guideline_uuid
                                                                )
                                                            )
                                                            .map(
                                                              (
                                                                item: Guideline
                                                              ) =>
                                                                item.guideline_uuid
                                                            );
                                                          setSelectedGuidelines(
                                                            commonList
                                                          );
                                                        } else {
                                                          setShowCompanyGuidelines(
                                                            false
                                                          );
                                                        }
                                                        setContact(!contact);
                                                        setupCurrentBlog(blog);
                                                        setNewBlog(false);
                                                      }}
                                                      className="board_hide"
                                                      style={{
                                                        padding:
                                                          "0.2rem 0.5rem",
                                                        // border: "1px solid gray",
                                                        borderRadius: "50px",
                                                        fontSize: "15px",
                                                        fontWeight: "bold",
                                                        maxWidth: "8%",
                                                        minWidth: "8%",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      {blog.deadline}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {currentCompanieBlogs.length > 0 ? (
                                      filterBlogsByCategoryAndSubcategory().map(
                                        (
                                          blog: SuperAdminBlog,
                                          index: number
                                        ) => {
                                          const isChecked = checkedItems.includes(
                                            index
                                          );
                                          const urgent = blog.urgent;

                                          // ""

                                          return (
                                            <>
                                              <div
                                                style={{
                                                  background: urgent
                                                    ? "#ffdfe3"
                                                    : "",
                                                }}
                                                className={
                                                  selectedBlogIndex !== index
                                                    ? "admin_timeslot"
                                                    : "admin_timeslot_opened"
                                                }
                                              >
                                                <div
                                                  className={"regular"}
                                                  style={{ padding: "0rem" }}
                                                >
                                                  <div className="admin_row_css_checkbox">
                                                    <label style={labelStyle}>
                                                      <input
                                                        type="checkbox"
                                                        value="myCheckbox" // Assigning a value to the checkbox
                                                        checked={isChecked}
                                                        onChange={() => {
                                                          if (isChecked) {
                                                            // If already checked, remove from the array
                                                            setCheckedItems(
                                                              (
                                                                prevCheckedItems
                                                              ) =>
                                                                prevCheckedItems.filter(
                                                                  (item) =>
                                                                    item !==
                                                                    index
                                                                )
                                                            );
                                                          } else {
                                                            // If not checked, add to the array
                                                            setCheckedItems(
                                                              (
                                                                prevCheckedItems
                                                              ) => [
                                                                ...prevCheckedItems,
                                                                index,
                                                              ]
                                                            );
                                                          }
                                                        }}
                                                        className="checkbox_css"
                                                      />
                                                    </label>
                                                  </div>
                                                  <div
                                                    onClick={() => {
                                                      if (
                                                        blog.company_guidelines
                                                          ?.length > 0
                                                      ) {
                                                        setShowCompanyGuidelines(
                                                          true
                                                        );
                                                        const commonList = guidelines
                                                          .filter(
                                                            (item: Guideline) =>
                                                              blog.company_guidelines.includes(
                                                                item.guideline_uuid
                                                              )
                                                          )
                                                          .map(
                                                            (item: Guideline) =>
                                                              item.guideline_uuid
                                                          );
                                                        setSelectedGuidelines(
                                                          commonList
                                                        );
                                                      } else {
                                                        setShowCompanyGuidelines(
                                                          false
                                                        );
                                                      }
                                                      setContact(!contact);
                                                      setupCurrentBlog(blog);
                                                      setNewBlog(false);
                                                    }}
                                                    className="admin_row_css_keywords"
                                                  >
                                                    {blog.keywords}
                                                  </div>
                                                  {/* <div
                                        onClick={() => {
                                          setContact(!contact);
                                          setCurrentBlog(blog);
                                          setNewBlog(false);
                                        }}
                                        style={{
                                          padding: "0.2rem 0.5rem",
                                          // border: "1px solid gray",
                                          borderRadius: "50px",
                                          fontSize: "15px",
                                          maxWidth: "20%",
                                          fontWeight: "bold",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          minWidth: "20%",
                                        }}
                                      >
                                        {blog.blog_title}
                                      </div> */}

                                                  <div className="admin_row_css_url">
                                                    {blog.url.length > 0 ? (
                                                      <>
                                                        <a
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                            color: "blue",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              blog.url,
                                                              "_blank"
                                                            );
                                                          }}
                                                        >
                                                          Link
                                                        </a>
                                                      </>
                                                    ) : (
                                                      <>No link</>
                                                    )}
                                                  </div>
                                                  {login ? (
                                                    <></>
                                                  ) : (
                                                    <>
                                                      <div
                                                        onClick={() => {
                                                          if (
                                                            blog
                                                              .company_guidelines
                                                              ?.length > 0
                                                          ) {
                                                            setShowCompanyGuidelines(
                                                              true
                                                            );
                                                            const commonList = guidelines
                                                              .filter(
                                                                (
                                                                  item: Guideline
                                                                ) =>
                                                                  blog.company_guidelines.includes(
                                                                    item.guideline_uuid
                                                                  )
                                                              )
                                                              .map(
                                                                (
                                                                  item: Guideline
                                                                ) =>
                                                                  item.guideline_uuid
                                                              );
                                                            setSelectedGuidelines(
                                                              commonList
                                                            );
                                                          } else {
                                                            setShowCompanyGuidelines(
                                                              false
                                                            );
                                                          }
                                                          setContact(!contact);
                                                          setupCurrentBlog(
                                                            blog
                                                          );
                                                          setNewBlog(false);
                                                        }}
                                                        className="board_hide"
                                                        style={{
                                                          padding:
                                                            "0.2rem 0.5rem",
                                                          // border: "1px solid gray",
                                                          borderRadius: "50px",
                                                          fontSize: "15px",
                                                          maxWidth: "15%",
                                                          fontWeight: "bold",
                                                          minWidth: "15%",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        {blog.writer}
                                                      </div>
                                                    </>
                                                  )}
                                                  <div
                                                    onClick={() => {
                                                      if (
                                                        blog.company_guidelines
                                                          ?.length > 0
                                                      ) {
                                                        setShowCompanyGuidelines(
                                                          true
                                                        );
                                                        const commonList = guidelines
                                                          .filter(
                                                            (item: Guideline) =>
                                                              blog.company_guidelines.includes(
                                                                item.guideline_uuid
                                                              )
                                                          )
                                                          .map(
                                                            (item: Guideline) =>
                                                              item.guideline_uuid
                                                          );
                                                        setSelectedGuidelines(
                                                          commonList
                                                        );
                                                      } else {
                                                        setShowCompanyGuidelines(
                                                          false
                                                        );
                                                      }
                                                      setContact(!contact);
                                                      setupCurrentBlog(blog);
                                                      setNewBlog(false);
                                                    }}
                                                    className="board_hide"
                                                    style={{
                                                      padding: "0.2rem 0.5rem",
                                                      // border: "1px solid gray",
                                                      borderRadius: "50px",
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      justifyContent: "center",
                                                      maxWidth: "5%",
                                                      minWidth: "5%",
                                                    }}
                                                  >
                                                    {blog.blog_length}
                                                  </div>
                                                  {login ? (
                                                    <></>
                                                  ) : (
                                                    <>
                                                      <div
                                                        onClick={() => {
                                                          if (
                                                            blog
                                                              .company_guidelines
                                                              ?.length > 0
                                                          ) {
                                                            setShowCompanyGuidelines(
                                                              true
                                                            );
                                                            const commonList = guidelines
                                                              .filter(
                                                                (
                                                                  item: Guideline
                                                                ) =>
                                                                  blog.company_guidelines.includes(
                                                                    item.guideline_uuid
                                                                  )
                                                              )
                                                              .map(
                                                                (
                                                                  item: Guideline
                                                                ) =>
                                                                  item.guideline_uuid
                                                              );
                                                            setSelectedGuidelines(
                                                              commonList
                                                            );
                                                          } else {
                                                            setShowCompanyGuidelines(
                                                              false
                                                            );
                                                          }
                                                          setContact(!contact);
                                                          setupCurrentBlog(
                                                            blog
                                                          );
                                                          setNewBlog(false);
                                                        }}
                                                        className="board_hide"
                                                        style={{
                                                          padding:
                                                            "0.2rem 0.5rem",
                                                          // border: "1px solid gray",
                                                          borderRadius: "50px",
                                                          fontSize: "15px",
                                                          fontWeight: "bold",
                                                          maxWidth: "6%",
                                                          minWidth: "6%",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        {blog.amount + "$"}
                                                      </div>
                                                    </>
                                                  )}
                                                  <div
                                                    onClick={() => {
                                                      if (
                                                        blog.company_guidelines
                                                          ?.length > 0
                                                      ) {
                                                        setShowCompanyGuidelines(
                                                          true
                                                        );
                                                        const commonList = guidelines
                                                          .filter(
                                                            (item: Guideline) =>
                                                              blog.company_guidelines.includes(
                                                                item.guideline_uuid
                                                              )
                                                          )
                                                          .map(
                                                            (item: Guideline) =>
                                                              item.guideline_uuid
                                                          );
                                                        setSelectedGuidelines(
                                                          commonList
                                                        );
                                                      } else {
                                                        setShowCompanyGuidelines(
                                                          false
                                                        );
                                                      }
                                                      setContact(!contact);
                                                      setupCurrentBlog(blog);
                                                      setNewBlog(false);
                                                    }}
                                                    className="admin_row_css_status"
                                                  >
                                                    {getRender(blog.status)}
                                                  </div>

                                                  <div
                                                    onClick={() => {
                                                      if (
                                                        blog.company_guidelines
                                                          ?.length > 0
                                                      ) {
                                                        setShowCompanyGuidelines(
                                                          true
                                                        );
                                                        const commonList = guidelines
                                                          .filter(
                                                            (item: Guideline) =>
                                                              blog.company_guidelines.includes(
                                                                item.guideline_uuid
                                                              )
                                                          )
                                                          .map(
                                                            (item: Guideline) =>
                                                              item.guideline_uuid
                                                          );
                                                        setSelectedGuidelines(
                                                          commonList
                                                        );
                                                      } else {
                                                        setShowCompanyGuidelines(
                                                          false
                                                        );
                                                      }
                                                      setContact(!contact);
                                                      setupCurrentBlog(blog);
                                                      setNewBlog(false);
                                                    }}
                                                    className="board_hide"
                                                    style={{
                                                      padding: "0.2rem 0.5rem",
                                                      // border: "1px solid gray",
                                                      borderRadius: "50px",
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                      maxWidth: "8%",
                                                      minWidth: "8%",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {blog.deadline}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={"admin_timeslot_header no_hover"}
              style={{
                margin: "2rem 0rem 0rem 0rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={"regular_user"} style={{ padding: "0rem" }}>
                {/* <div
                      onClick={() => {
                        setSelectedUserId(100);
                      }}
                      className="user_name"
                      style={{
                        padding: "0.2rem 1.5rem",
                        // borderRadius: "50px",
                        fontWeight: "bold",
                        background: 100 === selectedUserId ? "white" : "",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: 100 == selectedUserId ? "#1d73ed" : "",
                        minWidth: "auto",
                      }}
                    >
                      All
                    </div> */}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={createProject ? "modal-container" : "hidden"}>
        {renderProject()}
      </div>
      <div className={assignUser ? "modal-container" : "hidden"}>
        {renderAssignUser()}
      </div>
      <div className={contact ? "modal-container" : "hidden"}>
        {renderContact()}
      </div>
      <div className={deleteBlogs ? "modal-container" : "hidden"}>
        {renderDelete()}
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast_type} variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </>
  );
}
function blog(value: SuperAdminBlog, index: number, array: SuperAdminBlog[]): value is SuperAdminBlog {
  throw new Error("Function not implemented.");
}

