// LoginPage.js

import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "./../styles/LoginPage.css"; // Import the CSS file for styling
import TextField from "@mui/material/TextField";
import LogoImg from "./../img/wecon-mail.png"; // Adjust the path accordingly
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';

import axios from "axios";

interface ChildComponentProps {
  sendBooleanToParent: (value: boolean) => void;
}

interface LoginParams {
  email: string;
  pass: string;
}

interface CreateAccount {
  email: string;
  password: string;
  name:string;
  confirm_password:string;
}

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface LoginProps {
  onLogin: (userDetails: UserDetails) => void;
}

const LoginPage: React.FC<LoginProps> = ({ onLogin }) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [error_message, setErrorMessage] = useState("");
  const handleOpenError = () => {
    setError(true);
  };

  const handleCloseError = () => {
    setError(false);
  };
  const [formData, setFormData] = useState<LoginParams>({
    pass: "",
    email: "",
  });

  const [newAccount, setNewAcctount] = useState<CreateAccount>({
    password: "",
    email: "",
    confirm_password:"",
    name:"",
  });

  const handleLogin = async (e: React.FormEvent) => {
    if(createAccount ){
      createAccountCall(e)
    } else {
      login(e)
    }
  }
  const createAccountCall = async (e: React.FormEvent) => {
    e.preventDefault();

    if(!validateEmail(newAccount.email)) {
      setErrorMessage("Please enter valid email")
      handleOpenError()
      return
    }


    if(newAccount.name.length == 0) {
      setErrorMessage("Name cant be empty!")
      handleOpenError()
      return
    }


    if(newAccount.password.length < 4) {
      setErrorMessage("Password needs to be at least 4 characters")
      handleOpenError()
      return
    }

    if(!(newAccount.password === newAccount.confirm_password)) {
      setErrorMessage("Passwords not matching")
      handleOpenError()
      return
    }

    try {
      StartLoading()
      const response = await fetch("https://servercode.wecon-digital.agency/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: newAccount.email,
          password: newAccount.password,
          name:newAccount.name
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      setNewAcctount({
        confirm_password:'',
        email:'',
        name:'',
        password:''
      })
      StopLoading()
      setCreateAccount(false)
      setSuccess(true)
      setErrorMessage("Invitaion successfully sended ! Please check your email")

    } catch (error:any) {
      setError(true);
      StopLoading()
      handleOpenError()
      setErrorMessage(error.message)
    }
  };
  const login = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      StartLoading()
      const response = await fetch("https://servercode.wecon-digital.agency/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.pass,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const userDetails = await response.json();
      console.log(userDetails);
      localStorage.setItem("token", userDetails.token); // Store token in local storage

      onLogin(userDetails);
    } catch (error:any) {
      setError(true);
      StopLoading()
      handleOpenError()
      setErrorMessage("Invalid Credentials")
      // setErrorMessage(error);
    }
  };
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNewAccountValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAcctount((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const [loadingLoging, setLoadingLoging] = useState(false);
  const [createAccount, setCreateAccount] = useState(false)


  function StartLoading() {
    setLoadingLoging(true);
  }

  function StopLoading() {
    setLoadingLoging(false);
  }

  function validateEmail(email:string) {
    const re = /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\\.,;:\s@\"]+\.)+[^<>()[\]\\.,;:\s@\"]{2,})$/i;
    return re.test(email);
  }

  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleLogin}>
        <div style={{display:'flex', justifyContent:'center' , padding:'1rem 0rem'}}>
        <img
              src={LogoImg}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            /> </div>
        {createAccount ? <>
          <TextField
          name="name"
          className="textfield"
          required
          id="outlined-required"
          label="Name"
          placeholder="John"
          onChange={handleNewAccountValueChange}
        />
          <TextField
          name="email"
          className="textfield"
          required
          id="outlined-required"
          label="Email"
          placeholder="example@email.com"
          onChange={handleNewAccountValueChange}
        />
        <TextField
          name="password"
          className="textfield"
          required
          type="password"
          id="outlined-required"
          label="Password"
          placeholder="Password"
          onChange={handleNewAccountValueChange}
        />
        <TextField
          name="confirm_password"
          className="textfield"
          required
          type="password"
          id="outlined-required"
          label="Confrim Password"
          placeholder="Confrim Password"
          onChange={handleNewAccountValueChange}
        />
        </>:<>
        <TextField
          name="email"
          className="textfield"
          required
          id="outlined-required"
          label="Email"
          placeholder="example@email.com"
          onChange={handleValueChange}
        />
        <TextField
          name="pass"
          className="textfield"
          required
          id="outlined-required"
          label="Password"
          placeholder="Password"
          onChange={handleValueChange}
        /></>}
        <div className="create_acc" onClick={()=>{
          if (!createAccount) {
            setCreateAccount(true)
          } else {
            setCreateAccount(false)
          }
        }}>{createAccount ? <></>:<>Create new account ?</>}</div>
        <button className="login_button" type="submit" style={{position: 'relative', display: 'inline-block', height: '47px'}}>
          <span style={{fontWeight: 'bold'}}>
            {loadingLoging ? (
              <div className="loading_login">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            ) : (
              <div style={{fontSize:'16px', fontWeight:'bold'}}>{createAccount ? <>Create account</>:<>Login</>}</div>
            )}
          </span>
        </button>
      </form>

      <Snackbar open={error} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseError} severity="error" variant="filled">
         <span>{error_message}</span>
        </Alert>
      </Snackbar>
      <Snackbar open={success} autoHideDuration={3000}  >
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseError} severity="success" variant="filled">
         <span>{error_message}</span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LoginPage;
