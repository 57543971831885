import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { getTranslation } from "../../translation";
import ReactQuill from "react-quill";
import axios from "axios";
interface SuperAdminBlog {
  multiple: boolean;
  urgent: boolean;
  project_name: string;
  blog_uuid: string;
  blog_title: string;
  company_name: string;
  company_uuid: string;
  words: number;
  writer: string;
  writer_uuid: string;
  editor_name: string;
  editor_uuid: string;
  amount: number;
  general_guidelines: boolean;
  company_guidelines: string[];
  additional_guidelines: string;
  keywords: string;
  deadline: string;
  url: string;
  status: string;
  blog_length: number;
  tools: string[];
  additional_info: string;
  example_article: string;
}

interface UserLite {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
}

interface User {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
  password: string;
}

interface CompanyData {
  company_name: string;
  company_uuid: string;
  company_projects: string[];
  current_project_blogs: SuperAdminBlog[];
}

interface Guideline {
  guideline_uuid: string;
  guideline_data: string;
  guideline_name: string;
  active: boolean;
}
type AlertColor = "success" | "info" | "warning" | "error";

export default function CompanyGuidelines({
  company,
  all_users,
  adminGuidelines,
}: any) {
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);
  const [customGuideline, setCustomGuideline] = useState(false);
  const [guidelines, setGuidelines] = useState([]);
  const [deleteBlogs, setDeleteBlogs] = useState(false); // Loading statesetDeleteProject(!deleteProject)

  const [newGuidelineName, setNewGuidelineName] = useState<String>("");
  const [createGuideline, setCreateGuideline] = useState(false); // Loading state
  const [selectedGuideline, setSelectedGuideline] = useState(0);
  const [selectedProject, setSelectedProject] = useState<Guideline>({
    guideline_data: "",
    guideline_uuid: "",
    guideline_name: "",
    active: false,
  });

  const [toast_message, setToastMessage] = useState("empty_fields");
  const [toast_type, setToastType] = useState<AlertColor>("success");

  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const showToast = (type: string, message: string) => {
    setToastType(type as AlertColor);
    setToastMessage(message);
    setOpen(true);
  };

  const [SelectedView, setSelectedView] = useState("company");

  const fetchGuidelines = async (fromDelete:boolean) => {
    if (company) {
      const requestBody = {
        company_uuid: company.company_uuid,
      };

      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/company/guidelines",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);
      setSelectedProject(data[fromDelete ? 0 :selectedGuideline ]); // Ensure selectedGuideline is correctly defined and used
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };

  const fetchAdminGuidelines = async (fromDelete:boolean) => {
    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/admin/guidelines",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // Include the isActive boolean in the request body
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setGuidelines(data);
      setSelectedProject(data[fromDelete ? 0 :selectedGuideline ]); // Ensure selectedGuideline is correctly defined and used
      // Handle the data as needed
      console.log(data);
    } catch (error) {
      console.error("Error fetching company guidelines:", error);
    }
  };
  const deleteGuideline = async () => {

    try {
      StartLoading();

        let requestBody = {
          guideline_uuid: String(guidelines[selectedGuideline].guideline_uuid) ,

        };
        await fetch("https://servercode.wecon-digital.agency/api/company/guidelines/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        StopLoading();
        setDeleteBlogs(false)
        if(adminGuidelines) {
          fetchAdminGuidelines(true)
        } else{
          fetchGuidelines(true);
        }

        setSelectedGuideline(0)
    } catch (error) {
      StopLoading();
    }
  };

  const createNewGuideline = async () => {
    try {
      StartLoading();
      if (company) {
        let requestBody = {
          guideline_name: newGuidelineName,
          company_uuid: company.company_uuid,
        };
        await fetch("https://servercode.wecon-digital.agency/api/company/guidelines/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });
        StopLoading();
        if (guidelines.length != 0) {
          setCreateGuideline(!createGuideline);
        }
        setSelectedGuideline(0)
        showToast("success", "SUCCESSFULLY CREATED");

        fetchGuidelines(false);
      }
    } catch (error) {
      StopLoading();
    }
  };
  const createNewAdminGuideline = async () => {
    try {
      StartLoading();

      let requestBody = {
        guideline_name: newGuidelineName,
      };
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/admin/guidelines/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      setSelectedGuideline(0)
      StopLoading();
      if (guidelines.length != 0) {
        setCreateGuideline(!createGuideline);
      }
      showToast("success", "SUCCESSFULLY CREATED");

      fetchAdminGuidelines(false);
    } catch (error) {
      StopLoading();
    }
  };

  const saveChangesAdminGuideline = async () => {
    let json = {
      guideline_data: selectedProject.guideline_data,
      guideline_uuid: selectedProject.guideline_uuid,
      guideline_name: selectedProject.guideline_name,
      active :selectedProject.active,
    };
    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/admin/guidelines/save",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(json),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      fetchAdminGuidelines(false);
      const data = await response.json();
      console.log(data);
      showToast("success", "SUCCESSFULLY UPDATED");

      // setGuidelines(data)

      // setSelectedProject(data[0]);
      // Handle the data as needed
    } catch (error) {
      console.error("Error fetching company guidelines:", error);
    }
  };
  const saveChangesCompanyGuideline = async () => {
    let json = {
      guideline_data: selectedProject.guideline_data,
      guideline_uuid: selectedProject.guideline_uuid,
      guideline_name: selectedProject.guideline_name,
      company_uuid: company.company_uuid,
    };
    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/company/guidelines/save",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(json),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      fetchGuidelines(false);
      const data = await response.json();
      console.log(data);
      showToast("success", "SUCCESSFULLY UPDATED");

      // setGuidelines(data)

      // setSelectedProject(data[0]);
      // Handle the data as needed
    } catch (error) {
      console.error("Error fetching company guidelines:", error);
    }
  };

  // useEffect(() => {
  //   if (company && !adminGuidelines) {
  //     setCurrentCompanyData(company);
  //     fetchGuidelines();
  //   }
  //   if( adminGuidelines) {
  //     fetchAdminGuidelines();
  //   }

  //   // getCompanyBlogs(company_uuid);
  // }, [company]);
  useEffect(() => {
    if (!adminGuidelines) {
      setCurrentCompanyData(company);
      fetchGuidelines(false);
    }
    if (adminGuidelines) {
      fetchAdminGuidelines(false);
    }

    // getCompanyBlogs(company_uuid);
  }, [adminGuidelines]);

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  // Custom styles
  const checkboxStyle = {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
    padding: "10px",
    bordeRadius: "10px",
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  };

  const renderGuideline = () => {
    return (
      <>
        <div
        className="assign_blogs"
        >

          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    // padding: "1rem 0rem",

                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 style={{ marginBottom: "0.3rem" }}>Create Guideline</h2>
                </div>
                <div
                  style={{
                    // padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ color: "gray" }}>
                    {"( dont use same Project name )"}
                  </span>
                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="project_name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Guideline Name"
                    placeholder="Guideline Name"
                    value={newGuidelineName.length > 0 ? newGuidelineName : ""}
                    onChange={(e) => {
                      setNewGuidelineName(e.currentTarget.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setNewGuidelineName("");
                      setCreateGuideline(!createGuideline);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      if (adminGuidelines) {
                        createNewAdminGuideline();
                      } else {
                        createNewGuideline();
                      }
                    }}
                    variant="outlined"
                  >
                    <>{getTranslation("buttons.create")}</>
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderDelete = () => {
    return (
      <>
        <div
         className="assign_blogs"
        >
          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                {!deleteBlogs ? (
                  <>
                    <h1 style={{ marginBottom: "0rem" }}>Archive Blogs</h1>
                  </>
                ) : (
                  <>
                    <h1 style={{ marginBottom: "0rem" }}>Delete Guideline</h1>
                  </>
                )}
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ color: "gray" }}>
                    {!deleteBlogs ? (
                      <>Are you sure that you want to archive ?</>
                    ) : (
                      <>Are you sure that you want to delete ?</>
                    )}
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      width: "30%",
                      marginRight: "1rem",
                      border: "none",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      if (deleteBlogs) {
                        setDeleteBlogs(!deleteBlogs);
                      } else {
                        setDeleteBlogs(!deleteBlogs);
                      }
                    }}
                    // variant="outlined"
                  >
                    {"No"}
                    {/* {getTranslation("buttons.cancel")} */}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      width: "30%",
                      border: "none",
                      background: "green",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {

                        deleteGuideline();
                    }}
                    variant="outlined"
                  >
                    {/* <>{getTranslation("buttons.save")}</> */}
                    {"Yes"}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {createGuideline ? <></>:<>
      <div
        className="main_container"
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <div style={{ minWidth: "100%" }}>
          <h2> Guidelines</h2>
        </div>


        {SelectedView === "company" ? (
          <>
            <div
              className={"admin_timeslot_header no_hover"}
              style={{
                margin: "2rem 0rem 0rem 0rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className={"regular_user"}
                style={{
                  padding: "0rem",
                  display: "flex",
                  flexDirection: guidelines.length > 0 ? "row" : "column",
                  justifyContent: guidelines.length > 0 ? "" : "start",
                  alignItems: guidelines.length > 0 ? "" : "start",
                }}
              >
                {guidelines.map((project: Guideline, index) => {
                  if (project && selectedProject != null) {
                    return (
                      <>
                        <div
                          onClick={() => {
                            if (project.guideline_data.startsWith("htt")) {
                              setCustomGuideline(false);
                            } else {
                              setCustomGuideline(true);
                            }
                            setSelectedGuideline(index);
                            setSelectedProject(project);
                            //  fetchGuidelines()
                          }}
                          className="user_name"
                          style={{
                            padding: "0.2rem 1.5rem",
                            // borderRadius: "50px",
                            fontWeight: "bold",
                            background:
                              index === selectedGuideline ? "white" : "",
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: index === selectedGuideline ? "#1d73ed" : "",
                            minWidth: "auto",
                          }}
                        >
                          {project.guideline_name}
                        </div>
                      </>
                    );
                  }
                })}
                {guidelines.length == 0 ? (
                  <>
                    <h2>Create your first guideline </h2>

                    <div
                    className="mobile_create_guideline"
                      style={{
                        width: "45%",
                        background: "white",
                        borderRadius: guidelines.length > 0 ? "20px" : "",
                      }}
                    >
                      <div
                        className="additional-div"
                        style={{
                          minHeight: "15vh",
                          padding: "2rem",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {loadingSavingBlog ? (
                          <>
                            <div className="container_loading">
                              <div className="loading"></div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                padding: "1rem 0rem",
                                alignItems: "center",
                                // display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <TextField
                                style={{
                                  maxWidth: "100%",
                                }}
                                name="project_name"
                                className="textfield"
                                required
                                id="outlined-required"
                                label="Guideline Name"
                                placeholder="Guideline Name"
                                value={
                                  newGuidelineName.length > 0
                                    ? newGuidelineName
                                    : ""
                                }
                                onChange={(e) => {
                                  setNewGuidelineName(e.currentTarget.value);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                style={{
                                  // margin: "0.5rem",
                                  color: "white",
                                  width: "30%",
                                  marginRight: "1rem",
                                  // border: "1px solid  black",
                                  background: "gray",
                                }}
                                startIcon={<CloseIcon />}
                                onClick={() => {
                                  setNewGuidelineName("");
                                  setCreateGuideline(!createGuideline);
                                }}
                                // variant="outlined"
                              >
                                {getTranslation("buttons.cancel")}
                              </Button>
                              <Button
                                style={{
                                  // margin: "0.5rem",
                                  color: "white",
                                  width: "30%",
                                  // border: "1px solid  black",
                                  background: "#1d73ed",
                                }}
                                startIcon={<SaveIcon />}
                                onClick={() => {
                                  if (adminGuidelines) {
                                    createNewAdminGuideline();
                                  } else {
                                    createNewGuideline();
                                  }
                                }}
                                variant="outlined"
                              >
                                <>{getTranslation("buttons.create")}</>
                              </Button>
                            </div>

                            {/* Content of the additional div */}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        // currentCompanieBlogs()
                        // setSelectedProject(0);
                        setCreateGuideline(!createGuideline);
                      }}
                      className="user_name"
                      style={{
                        // borderRadius: "50px",
                        fontWeight: "bold",
                        background: "blue",
                        fontSize: "15px",
                        padding: "0.2rem 1.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        minWidth: "auto",
                      }}
                    >
                      <AddIcon />
                    </div>
                  </>
                )}
              </div>

              {guidelines.length > 0 ? (
                <>

                  <div
                    style={{
                      padding: "0rem 2rem 2rem 2rem",
                      background: "white",
                      display: "flex",
                      paddingTop: "2rem",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                        {adminGuidelines ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                            <h3>Currently {selectedProject.active ? "active":"inactive"} </h3>

                          <label style={labelStyle}>
                            <input
                              type="checkbox"
                              value="myCheckbox" // Assigning a value to the checkbox
                              checked={selectedProject.active}
                              onChange={(e) => {
                                // setCustomGuideline(!customGuideline);
                                setSelectedProject((prevFormData) => ({
                                  ...prevFormData,
                                  [`active`]: !selectedProject.active,
                                }));

                              }}
                              style={checkboxStyle}
                            />
                          </label>



                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <TextField
                      style={{
                        maxWidth: "30%",
                      }}
                      name="guideline_name"
                      className="textfield"
                      required
                      id="outlined-required"
                      label="Guideline Name"
                      placeholder="Guideline Name"
                      value={
                        selectedProject?.guideline_name?.length > 0
                          ? selectedProject?.guideline_name
                          : ""
                      }
                      onChange={(e) => {
                        setSelectedProject((prevFormData) => ({
                          ...prevFormData,
                          [`guideline_name`]: e.target.value,
                        }));
                      }}
                    />

                    {customGuideline ? (
                      <> </>
                    ) : (
                      <>
                        <TextField
                          style={{
                            maxWidth: "100%",
                          }}
                          name="guideline_url"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Guideline Url"
                          placeholder="Guideline Url"
                          value={
                            selectedProject?.guideline_data?.length > 0
                              ? selectedProject?.guideline_data
                              : ""
                          }
                          onChange={(e) => {
                            setSelectedProject((prevFormData) => ({
                              ...prevFormData,
                              [`guideline_data`]: e.target.value,
                            }));
                          }}
                        />
                      </>
                    )}


                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <h3>Write Guideline </h3>

                      <label style={labelStyle}>
                        <input
                          type="checkbox"
                          value="myCheckbox" // Assigning a value to the checkbox
                          checked={customGuideline}
                          onChange={(e) => {
                            setCustomGuideline(!customGuideline);
                            // setCurrentBlog((prevFormData) => ({
                            //   ...prevFormData,
                            //   [`guideline`]: "",
                            // }));
                            //
                          }}
                          style={checkboxStyle}
                        />
                      </label>
                    </div>
                    {customGuideline ? (
                      <>
                        {" "}
                        <ReactQuill
                          value={selectedProject.guideline_data}
                          onChange={(content) => {
                            setSelectedProject((prevFormData) => ({
                              ...prevFormData,
                              [`guideline_data`]: content,
                            }));
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "start",
                      }}
                    >
                                                 <Button
                                style={{
                                  // margin: "0.5rem",
                                  color: "red",
                                  width: "15%",
                                  marginRight: "1rem",
                                  fontWeight: "bold",
                                  background: "white",
                                  border:'1px solid red'
                                }}
                                // startIcon={<DeleteIcon />}
                                onClick={() => {
                                  setDeleteBlogs(!deleteBlogs);
                                }}
                                // variant="outlined"
                              >
                                {"Delete"}
                              </Button>
                      <Button
                        className="guideline_button"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={() => {
                          if (adminGuidelines) {
                            saveChangesAdminGuideline();
                          } else {
                            saveChangesCompanyGuideline();
                          }
                        }}
                      >
                        {guidelines.length != 0 ? "Save changes" : "Create"}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div></>}

      <div className={createGuideline ? "modal-container" : "hidden"}>
        {renderGuideline()}
      </div>
      <div className={deleteBlogs ? "modal-container" : "hidden"}>
        {renderDelete()}
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast_type} variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </>
  );
}
