import React from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Menu,
    ToggleButton,
    ToggleButtonGroup,
  } from "@mui/material";

interface ContextMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSlackNotifications: () => void;
  onMakeInactive: () => void;
  onDelete: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  anchorEl,
  onClose,
  onSlackNotifications,
  onMakeInactive,
  onDelete,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={onSlackNotifications}>Slack Notifications</MenuItem>
      <MenuItem onClick={onMakeInactive}>Make Inactive</MenuItem>
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Menu>
  );
};

export default ContextMenu;