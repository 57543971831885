import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import { setDefaultLanguage, getTranslation } from "./../../translation";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import axios from "axios";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-calendar/dist/Calendar.css';
import "../../styles/Calendar.css";
import YouTube from "react-youtube";
import "../../styles/Reservation.css";
import SettingsIcon from "@mui/icons-material/Settings";
import "../../styles/AdminWelcome.css";
import LogoImg from "./../../img/wecon-mail.png"; // Adjust the path accordingly
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
// import Checkbox from 'material-ui/Checkbox';
import { FormValues } from "../../interfaces";
import ReactQuill from "react-quill";
import { Guideline, SuperAdminBlog, UserBlog } from "../../types";

interface TimeSlot {
  time: string;
  booked: boolean;
  selected: boolean;
}

interface Company {
  company_name: string;
  company_uuid: string;
}
interface Writer {
  writer_name: string;
  writer_uuid: string;
}

interface BookingInfo {
  booking_id: number | null;
  user_email: string;
  services: string[]; // Assuming the services are stored as an array of strings
  packages: string[]; // Assuming the packages are stored as an array of strings
  status: number;
  timeslot: string;
}

interface User {
  user_uuid: string;
  name: string;
  password: string;
  roles: string[];
  email: string;
}

interface UserInfoShort {
  name: string;
  user_uuid: string;
  roles: string[];
  email: string;
}

interface BlogDataResponse {
  user_info: UserInfoShort;
  blogs: SuperAdminBlog[];
}

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface HomepageProps {
  userDetails: UserDetails | null;
}
interface HomepageProps {}

const UserComponent: React.FC<HomepageProps> = ({ userDetails }) => {
  const navigate = useNavigate();
  const handleChangeCompany = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`company_name`]: event.target.value,
    }));
  };
  const handleChangeStatus = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`status`]: event.target.value,
    }));
  };
  const handleChange = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`writer`]: event.target.value,
    }));
  };
  const [getCompanyName, setGetCompanyName] = useState<String>("");
  const [currentUser, setUser] = useState<User>({
    email: "",
    name: "",
    user_uuid: "",
    password: "",
    roles: [],
  });
  const [selectedOptions, setSelectedOptions] = useState([] as any);

  // const MultipleSelectDropdown = () => {
  //   const options = ["Writer", "Editor"]; // Replace with your options

  //   const handleChange = (event: any) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     const selectedValues =
  //       typeof value === "string" ? value.split(",") : value;
  //     setSelectedOptions(selectedValues);

  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       roles: selectedValues,
  //     }));
  //   };

  //   return (
  //     <FormControl sx={{ width: 300 }}>
  //       <InputLabel id="multiple-select-label">Roles</InputLabel>
  //       <Select
  //         labelId="multiple-select-label"
  //         multiple
  //         value={selectedOptions}
  //         onChange={handleChange}
  //         input={<OutlinedInput label="Options" />}
  //         renderValue={(selected) => selected.join(", ")}
  //       >
  //         {options.map((option) => (
  //           <MenuItem key={option} value={option}>
  //             {option}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   );
  // };

  const [newBlog, setNewBlog] = useState<Boolean>(false);

  const [selectedUserId, setSelectedUserId] = useState<Number>(0);
  const [selectedCompany, setSelectedCompany] = useState<Number>(-1);
  const [currentCompanyUuid, setCurrentCompanyUuid] = useState<String>("");
  const [selectedWriterUuid, setSelectedWriterUuid] = useState<String>("");

  const [companies, setCompanies] = useState<Company[]>([]);
  const [writers, setWriters] = useState<Writer[]>([]);
  const [contact, setContact] = useState(false); // Loading state
  const [createCompany, setCreateCompany] = useState(false); // Loading state
  const [createWriter, setCreateWriter] = useState(false); // Loading state
  const [editUser, setEditUser] = useState(false); // Loading state
  // Loading state
  const [createEditor, setCreateEditor] = useState(false); // Loading state
  function getRender(status: string): React.ReactNode {
    let backgroundColor = "lightgreen";
    let color = "white";

    switch (status) {
      case "Pending": {
        backgroundColor = "lightgray";
        color = "black";
        break;
      }
      case "In Progress": {
        backgroundColor = "blue";
        break;
      }
      case "Written": {
        backgroundColor = "lightgreen";
        color = "black";
        break;
      }
      case "Editor": {
        backgroundColor = "orange";
        break;
      }
      case "Completed": {
        backgroundColor = "darkgreen";
        break;
      }
      case "Revision": {
        backgroundColor = "purple";
        break;
      }
      case "Paid": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Failed": {
        backgroundColor = "red";
        break;
      }
    }
    return (
      <>
        <div
          style={{
            padding: "0.2rem 0.5rem",
            // border: "1px solid gray",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "3vh",
            color: color,
            background: backgroundColor,
          }}
        >
          {status}
        </div>
      </>
    );
  }

  const [toast_message, setToastMessage] = useState("empty_fields");

  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  const [activeView, setActiveView] = useState("booking");

  const [services, setServices] = useState([]);
  // const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [blogs, setBlogs] = useState<UserBlog[]>([]);
  const [editBlogs, setEditBlogs] = useState<UserBlog[]>([]);
  const [currentCompanieBlogs, setCurrentCompanieBlogs] = useState<
    SuperAdminBlog[]
  >([]);
  const [currentWriterBlogs, setCurrentWriterBlogs] = useState<
    BlogDataResponse
  >({
    blogs: [],
    user_info: { email: "", user_uuid: "", name: "", roles: [] },
  });

  const saveChanges = async () => {
    try {
      StartLoading();
      await axios.post(
        `https://servercode.wecon-digital.agency/api/save/${currentBlog.blog_uuid}`,
        currentBlog,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setContact(!setContact);
      if (userDetails) {
        fetchUserBlogs(userDetails?.uuid);
      }
      if (SelectedView === "users") {
        getWriterBlogs(selectedWriterUuid.toString());
      } else {
        getCompanyBlogs(currentCompanyUuid.toString());
      }

      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };

  const fetchUserBlogs = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-user/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setBlogs(data.blogs); // Update the services state with fetched data
      setEditBlogs(data.editor_blogs); // Update the services state with fetched data
      // ""
      if (userDetails) {
        setUser((prevFormData) => ({
          ...prevFormData,
          name: userDetails?.name,
          user_uuid: userDetails?.uuid,
          email: userDetails?.email,
          roles: userDetails?.roles.split(","),
        }));

        console.log(currentUser);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchUserEditBlog = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-user/editor/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setEditBlogs(data.blogs); // Update the services state with fetched data

      if (userDetails) {
        setUser((prevFormData) => ({
          ...prevFormData,
          name: userDetails?.name,
          user_uuid: userDetails?.uuid,
          email: userDetails?.email,
          roles: userDetails?.roles.split(","),
        }));

        console.log(currentUser);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  const [adminGuidelines, setAdminGuidelines] = useState([]);
  const fetchAdminGuidelines = async () => {
    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/api/admin/guidelines/active",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAdminGuidelines(data);
      // Handle the data as needed
      console.log(data);
    } catch (error) {
      console.error("Error fetching company guidelines:", error);
    }
  };
  const fetchGuidelines = async () => {
    if (currentBlog.company_name) {
      const requestBody = {
        company_name: currentBlog.company_name,
      };

      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/company/guidelines/name",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);
        // ""
        // setSelectedProject(data[selectedGuideline]);
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      fetchUserBlogs(userDetails?.uuid);
    }
    // ... other data fetching calls
  }, []);
  // Use effect hook to fetch services whenever activeView changes
  const [guidelines, setGuidelines] = useState([]);

  const [currentBlog, setCurrentBlog] = useState<UserBlog>({
    blog_uuid: "",
    blog_title: "",
    deadline: "",
    keywords: "",
    guideline: "",
    additional_guidelines: "",
    company_guidelines: [],
    urgent: false,
    status: "Pending",
    url: "",
    writer_uuid: "",
    company_name: "",
    additional_info: "",
    blog_length: 0,
    general_guidelines: false,
    example_article: [],
  });

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const [SelectedView, setSelectedView] = useState("users");
  const handleAdminTimeslotClick = (index: any) => {
    // Toggle visibility: if clicked again on the same item, it will hide
    // "";
    setContact(!contact);
    // setSelectedBlogIndex(selectedBlogIndex === index ? null : index);
  };

  const [showGuidelineEditor, setShowGuidelineEditor] = useState<string | null>(
    null
  );
  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([]);

  const handleEyeIconClick = (guidelineId: string) => {
    setShowGuidelineEditor((prevId) =>
      prevId === guidelineId ? null : guidelineId
    );
  };

  const updateUser = async () => {
    try {
      StartLoading();
      console.log(currentUser);
      await axios.post(
        `https://servercode.wecon-digital.agency/api/writer/update/`,
        currentUser, // Assuming `user` is the object that matches your User interface
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setCreateWriter(!createWriter);
      setEditUser(false);
      setGetCompanyName("");
      setSelectedWriterUuid(currentUser.user_uuid);
      getWriterBlogs(currentUser.user_uuid);
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  const getCompanyBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-company/blogs/${company_uuid}`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.blogs); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getWriterBlogs = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/writer/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setCurrentWriterBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const renderUserBlogs = (index: any, blog: any) => {
    return (
      <>
        <div
          className="blog_container"
        >
          <div
            className="additional-div"
            style={{
              minHeight: "50vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <h1>Blog Info </h1>{" "}
                <span
                  hidden={!currentBlog.urgent}
                  style={{
                    color: "white",
                    background: "red",
                    padding: "0.5rem 1rem",
                    borderRadius: "20px",
                    maxWidth: "5rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Urgent
                </span>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    display: "flex",
                    width: "50%",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    style={{
                      maxWidth: "50%",
                    }}
                    name="blog_length"
                    className="textfield"
                    disabled={true}
                    id="outlined-required"
                    label="Blog length"
                    placeholder="CompanyName"
                    value={
                      currentBlog.blog_length > 0 ? currentBlog.blog_length : ""
                    }
                    onChange={handleValueChange}
                  />

                  <FormControl
                    style={{
                      maxWidth: "100%",
                      minWidth: "50%",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Blog Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        currentBlog.status.length > 0
                          ? currentBlog.status
                          : "Pending"
                      }
                      label="Company Name"
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value={"Pending"}>Pending</MenuItem>
                      <MenuItem value={"In Progress"}>In Progress</MenuItem>
                      {/* <MenuItem value={10}>Finished</MenuItem> */}
                      <MenuItem value={"Written"}>Written</MenuItem>
                      <MenuItem value={"Editor"}>Editor</MenuItem>
                      <MenuItem value={"Completed"}>Completed</MenuItem>
                      <MenuItem value={"Failed"}>Failed</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    style={{
                      minWidth: "50%",
                    }}
                    name="deadline"
                    className="textfield"
                    disabled={true}
                    id="outlined-required"
                    label="Deadline"
                    placeholder="Deadline"
                    value={
                      currentBlog.deadline.length > 0
                        ? currentBlog.deadline
                        : "No deadline"
                    }
                    onChange={handleValueChange}
                  />
                </div>
                {/* <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    display: "flex",
                    width: "70%",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    style={{
                      maxWidth: "70%",
                    }}
                    name="blog_title"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Blog Title"
                    placeholder="CompanyName"
                    value={
                      currentBlog.blog_title.length > 0
                        ? currentBlog.blog_title
                        : ""
                    }
                    onChange={handleValueChange}
                  />
                </div> */}
                <div
                  style={{
                    padding: "1rem 0rem",
                    // alignItems: "center",
                    // width: "70%",
                    alignItems: "center",
                    display: "flex",
                    // flexDirection:'column',
                    justifyContent: "start",
                  }}
                >
                  <TextField
                    style={{
                      // maxWidth: "70%",
                    }}
                    name="url"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Google Doc Url"
                    placeholder="Url"
                    value={currentBlog.url.length > 0 ? currentBlog.url : ""}
                    onChange={handleValueChange}
                  />
                  <a
                    style={{ marginLeft: "1rem" }}
                    href={currentBlog.url}
                    target="_blank"
                  >
                    {currentBlog.url.length > 0 ? "Link" : ""}
                  </a>
                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    display: "flex",
                    // width: "70%",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    style={{
                      // maxWidth: "70%",
                    }}
                    name="keywords"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Keywords"
                    placeholder="CompanyName"
                    value={
                      currentBlog.keywords.length > 0
                        ? currentBlog.keywords
                        : ""
                    }
                    onChange={handleValueChange}
                  />
                </div>
                 {/* General Guidelines */}
                {currentBlog?.general_guidelines ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <h3>General Guidelines </h3>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                 {currentBlog?.general_guidelines ? (
                  <>
                     <div
                  style={{
                    width: "100%",
                    // display: "grid",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  {adminGuidelines.map((guideline: Guideline) => {
                    const doc = guideline.guideline_data.startsWith("http");
                    return (
                      <>
                        <div
                          style={{
                            width: "35%",
                            margin: "0.5rem",
                            padding: "0.5rem 1.3rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "lightgray",
                            borderRadius: "30px",
                          }}
                        >
                          {guideline.guideline_name}{" "}
                          <div>
                            <RemoveRedEyeIcon
                              onClick={() =>
                                handleEyeIconClick(guideline.guideline_uuid)
                              }
                            />
                          </div>
                        </div>
                        {showGuidelineEditor === guideline.guideline_uuid && (
                          <>
                            {doc ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ReactQuill
                                    readOnly={true}
                                    style={{ width: "100%" }}
                                    value={guideline.guideline_data}
                                  />
                                  <a
                                    href={guideline.guideline_data}
                                    target="_blank"
                                  >
                                    {" "}
                                    Link
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <ReactQuill
                                  readOnly={true}
                                  style={{ width: "100%" }}
                                  value={guideline.guideline_data}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
                  </>
                ) : (
                  <></>
                )}

                 {/* ----------------------- */}
                {/* Company Guidelines */}
                {currentBlog?.company_guidelines?.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <h3>Company Guidelines </h3>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    width: "100%",
                    // display: "grid",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  {guidelines.map((guideline: Guideline) => {
                    if (currentBlog.company_guidelines) {
                      if (
                        currentBlog.company_guidelines.length > 0 &&
                        currentBlog?.company_guidelines?.includes(
                          guideline.guideline_uuid
                        )
                      ) {
                        const doc = guideline.guideline_data.startsWith("http");
                        return (
                          <>
                            <div
                              style={{
                                width: "35%",
                                margin: "0.5rem",
                                padding: "0.5rem 1.3rem",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "lightgray",
                                borderRadius: "30px",
                              }}
                            >
                              {guideline.guideline_name}{" "}
                              <div>
                                <RemoveRedEyeIcon
                                  onClick={() =>
                                    handleEyeIconClick(guideline.guideline_uuid)
                                  }
                                />
                              </div>
                            </div>
                            {showGuidelineEditor ===
                              guideline.guideline_uuid && (
                              <>
                                {doc ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <ReactQuill
                                        readOnly={true}
                                        style={{ width: "100%" }}
                                        value={guideline.guideline_data}
                                      />
                                      <a
                                        href={guideline.guideline_data}
                                        target="_blank"
                                      >
                                        {" "}
                                        Link
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <ReactQuill
                                      readOnly={true}
                                      style={{ width: "100%" }}
                                      value={guideline.guideline_data}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        );
                      }
                    }
                  })}
                </div>
                 {/* ----------------------- */}
                   {/* Example articles */}
                 {currentBlog.example_article.length > 0 ?<>
                  <div  style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <h3>Examples </h3>
                </div>

                </>:<></>}
                {
                  currentBlog.example_article.map((example, index)=>{
                    return (

                      <div
                      style={{
                        padding: "1rem 0rem",
                        alignItems: "center",
                        display: "flex",
                        width: "70%",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        style={{
                          maxWidth: "100%",
                          marginRight:'1rem'
                        }}
                        name="keywords"
                        className="textfield"
                        disabled ={true}
                        id="outlined-required"
                        label="Keywords"
                        placeholder="CompanyName"
                        value={currentBlog.example_article[index]}
                        onChange={handleValueChange}
                      />
                      <a href={currentBlog.example_article[index]} target="_blank" >See</a>
                    </div>
                    )
                  })
                }
                {/* ------------------------- */}
                 {/* Additional Guideline */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <h3>Additional Guideline </h3>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <ReactQuill
                    readOnly={true}
                    style={{ width: "100%" }}
                    value={currentBlog.additional_guidelines}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <h3>Additional Information </h3>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <ReactQuill
                    readOnly={true}
                    style={{ width: "100%" }}
                    value={currentBlog.additional_info}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "15%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      handleAdminTimeslotClick(index);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "15%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      saveChanges();

                      // handleAdminTimeslotClick(index);
                    }}
                    variant="outlined"
                  >
                    {newBlog ? (
                      <> {getTranslation("buttons.create")}</>
                    ) : (
                      <>{getTranslation("buttons.save")}</>
                    )}
                  </Button>
                </div>
                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderWriter = () => {
    return (
      <>
        <div
  className="user_settings"
        >
          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {editUser ? (
              <>
                <h1>My Info</h1>
              </>
            ) : (
              <>
                <h1>Create User</h1>
              </>
            )}

            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                {userDetails ? (
                  <>
                    <div
                      style={{
                        padding: "1rem 0rem",
                        alignItems: "center",
                        // display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        style={{
                          maxWidth: "100%",
                        }}
                        name="name"
                        className="textfield"
                        required
                        id="outlined-required"
                        label="User Name"
                        placeholder="User Name"
                        value={
                          userDetails?.name?.length > 0 ? userDetails?.name : ""
                        }
                        onChange={handleUserChange}
                      />
                    </div>
                    <div
                      style={{
                        padding: "1rem 0rem",
                        alignItems: "center",
                        // display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <TextField
                        style={{
                          maxWidth: "100%",
                        }}
                        name="email"
                        className="textfield"
                        required
                        id="outlined-required"
                        label="User Email"
                        placeholder="User Email"
                        value={
                          userDetails?.email.length > 0
                            ? userDetails?.email
                            : ""
                        }
                        onChange={handleUserChange}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {editUser && currentUser.password.length === 0 ? (
                  <>

                    {/* <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <span
                        className="suggest_password"
                        onClick={() => {
                          const length = 10;
                          const charset =
                            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
                          let randomPassword = "";
                          for (let i = 0, n = charset.length; i < length; ++i) {
                            randomPassword += charset.charAt(
                              Math.floor(Math.random() * n)
                            );
                          }

                          setUser((prevUser) => ({
                            ...prevUser,
                            password: randomPassword,
                          }));
                        }}
                      >
                        New password?
                      </span>
                    </div> */}
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      style={{
                        padding: "1rem 0rem 0.4rem 0rem",
                        alignItems: "center",
                        // display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <TextField
                        style={{
                          maxWidth: "100%",
                        }}
                        name="password"
                        className="textfield"
                        required
                        id="outlined-required"
                        label="User Passoword"
                        placeholder="User Password"
                        value={
                          currentUser.password.length > 0
                            ? currentUser.password
                            : ""
                        }
                        onChange={handleUserChange}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <span
                        className="suggest_password"
                        onClick={() => {
                          const length = 10;
                          const charset =
                            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
                          let randomPassword = "";
                          for (let i = 0, n = charset.length; i < length; ++i) {
                            randomPassword += charset.charAt(
                              Math.floor(Math.random() * n)
                            );
                          }

                          setUser((prevUser) => ({
                            ...prevUser,
                            password: randomPassword,
                          }));
                        }}
                      >
                        Suggest password?
                      </span>
                    </div>
                  </>
                )}

                <div
                  style={{
                    padding: "0.5rem 0rem 1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      if (editUser) {
                        setEditUser(false);
                      }
                      setCreateWriter(!createWriter);
                      setUser({
                        email: "",
                        name: "",
                        user_uuid: "",
                        password: "",
                        roles: [],
                      });
                      setSelectedOptions([]);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {

                        updateUser();

                    }}
                    variant="outlined"
                  >
                    {getTranslation(
                      editUser ? "buttons.update" : "buttons.create"
                    )}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="container minh-100"
        // , borderTop:'3vh solid #1d73ed',borderLeft:'3vh solid #1d73ed'
        style={{ display: "flex", width: "100%" }}
      >
        <div
          className="admin_header minh-100 left_side"
          style={{
            minHeight: "100%",
            width: "15%",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <div
            className="top_view"
            style={{
              minHeight: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid lightgray",
            }}
          >
            <img
              src={LogoImg}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
          </div>
        </div>

        <div
          className="admin_header minh-100 right_side"
        >
   <div className="top_view header_logout">
    <img
            className="mobile_show"
              src={LogoImg}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
            <div
              className="logout"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");

                navigate("/login");
              }}
            >
              {" "}
              <LogoutIcon />{" "}
              <span style={{ marginLeft: "0.5rem" }}>Log out </span>
            </div>
          </div>
          <div
            className="main_container plus_css"

          >
            <div style={{ minWidth: "100%" }}>
              <h2>{userDetails?.name}</h2>
              <SettingsIcon
                className="settings"
                onClick={() => {
                  setCreateWriter(!createWriter);
                  setEditUser(true);
                  setUser((prevFormData) => ({
                    ...prevFormData,
                    name: currentWriterBlogs.user_info.name,
                    user_uuid: currentWriterBlogs.user_info.user_uuid,
                    email: currentWriterBlogs.user_info.email,
                    roles: currentWriterBlogs.user_info.roles,
                  }));
                  console.log(currentWriterBlogs.user_info.roles);
                  setSelectedOptions(currentWriterBlogs.user_info.roles);
                }}
              />
            </div>
            <div style={{ minWidth: "100%" }}>
              <p>Here you can find all information about your current blogs</p>
            </div>

            <div
              className={"admin_timeslot_header no_hover"}
              style={{
                margin: "2rem 0rem 0rem 0rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={"regular_user"} style={{ padding: "0rem" }}>
                <div
                  className="user_name"
                  style={{
                    padding: "0.2rem 1.5rem",
                    // borderRadius: "50px",
                    fontWeight: "bold",
                    background: "white",
                    fontSize: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#1d73ed",
                    minWidth: "auto",
                  }}
                >
                  {" "}
                  {"Blogs"}
                </div>
              </div>
              <div
                style={{
                  minHeight: "15vh",
                  width: "100%",
                  background: "white",
                }}
              >
                <div
                  className={"admin_timeslot_header no_hover"}
                  style={{
                    margin: "2rem 0rem 0rem 0rem",
                  }}
                >
                  <div className={"regular "} style={{ padding: "0rem" }}>
                    <div
                    className="user_keywords_2"
                    >
                      Keywords
                    </div>
                    <div
   className="user_blog_length"

                    >
                      Length
                    </div>
                    <div
                       className="user_url"

                    >
                      URL
                    </div>
                    <div
                    className="board_hide"
                      style={{
                        padding: "0.2rem 0.5rem",
                        // border: "1px solid gray",
                        borderRadius: "50px",
                        fontSize: "15px",
                        maxWidth: "8%",
                        fontWeight: "bold",
                        minWidth: "8%",
                      }}
                    >
                      Guidline
                    </div>
                    <div
                      className="user_deadline"
                    >
                      Status
                    </div>
                    <div
                     className="board_hide user_deadline"
                    >
                      Deadline
                    </div>
                  </div>
                </div>
                <div
                  className="table table_rows"
                  style={{
                    background: "white",
                    minHeight: "20vh",
                  }}
                >
                  <div style={{ paddingLeft: "3rem" }}>
                    <h2>Writing</h2>
                  </div>
                  <div className="reservation admin-timeslot-container">
                    {blogs.length > 0 ? (
                      blogs.map((blog: UserBlog, index: number) => {
                        let urgent = blog.urgent ? true : false;
                        return (
                          <>
                            <div
                              style={{
                                background: urgent ? "#ffdfe3" : "",
                              }}
                              className={
                                selectedBlogIndex !== index
                                  ? "admin_timeslot"
                                  : "admin_timeslot_opened"
                              }
                            >
                              <div
                                className={"regular"}
                                style={{ padding: "0rem" }}
                              >
                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                className="user_keywords_2"
                                >
                                  {blog.keywords}
                                </div>
                                {/* <div
                                    onClick={() => {
                                      setContact(!contact);

                                      fetchGuidelines()
                                      setCurrentBlog(blog);
                                      setNewBlog(false);
                                    }}
                                    style={{
                                      padding: "0.2rem 0.5rem",
                                      // border: "1px solid gray",
                                      borderRadius: "50px",
                                      fontSize: "15px",
                                      maxWidth: "20%",
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      minWidth: "20%",
                                    }}
                                  >
                                    {blog.blog_title}
                                  </div> */}
                                <div
                                  onClick={() => {
                                    setContact(!contact);

                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="user_blog_length"
                                >
                                  {blog.blog_length}
                                </div>
                                <div
                                   className="user_url"
                                >
                                  <a href={blog.url} target="_blank">
                                    {blog.url.length > 0 ? "Link" : ""}
                                  </a>
                                </div>
                                <div
                                  style={{
                                    // border: "1px solid gray",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  <a href={blog?.guideline} target="_blank">
                                    {blog?.guideline?.length > 0 ? "Link" : ""}
                                  </a>
                                </div>
                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    // setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="user_deadline"
                                >
                                  {getRender(blog.status)}
                                </div>

                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();

                                    // setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="board_hide"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  {blog.deadline}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ paddingLeft: "3rem" }}>
                    <h2>Editing</h2>
                  </div>
                  <div className="reservation admin-timeslot-container">
                    {editBlogs.length > 0 ? (
                      editBlogs.map((blog: UserBlog, index: number) => {

                        let urgent = blog.urgent ? true : false;
                        return (
                          <>
                           <div
                              style={{
                                background: urgent ? "#ffdfe3" : "",
                              }}
                              className={
                                selectedBlogIndex !== index
                                  ? "admin_timeslot"
                                  : "admin_timeslot_opened"
                              }
                            >
                              <div
                                className={"regular"}
                                style={{ padding: "0rem" }}
                              >
                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                className="user_keywords_2"
                                >
                                  {blog.keywords}
                                </div>
                                {/* <div
                                    onClick={() => {
                                      setContact(!contact);

                                      fetchGuidelines()
                                      setCurrentBlog(blog);
                                      setNewBlog(false);
                                    }}
                                    style={{
                                      padding: "0.2rem 0.5rem",
                                      // border: "1px solid gray",
                                      borderRadius: "50px",
                                      fontSize: "15px",
                                      maxWidth: "20%",
                                      fontWeight: "bold",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      minWidth: "20%",
                                    }}
                                  >
                                    {blog.blog_title}
                                  </div> */}
                                <div
                                  onClick={() => {
                                    setContact(!contact);

                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="user_blog_length"
                                >
                                  {blog.blog_length}
                                </div>
                                <div
                                   className="user_url"
                                >
                                  <a href={blog.url} target="_blank">
                                    {blog.url.length > 0 ? "Link" : ""}
                                  </a>
                                </div>
                                <div
                                  style={{
                                    // border: "1px solid gray",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  <a href={blog?.guideline} target="_blank">
                                    {blog?.guideline?.length > 0 ? "Link" : ""}
                                  </a>
                                </div>
                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();
                                    // setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="user_deadline"
                                >
                                  {getRender(blog.status)}
                                </div>

                                <div
                                  onClick={() => {
                                    setContact(!contact);
                                    fetchGuidelines();
                                    fetchAdminGuidelines();

                                    // setCurrentBlog(blog);
                                    setNewBlog(false);
                                  }}
                                  className="board_hide"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  {blog.deadline}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={contact ? "modal-container" : "hidden"}>
        {renderUserBlogs(selectedUserId, currentCompanieBlogs[0])}
      </div>
      <div className={createWriter ? "modal-container" : "hidden"}>
        {renderWriter()}
      </div>
      {/* <div className={contact ? "modal-container" : "hidden"}>
        {renderUserEditorBlogs(selectedUserId, currentCompanieBlogs[0])}
      </div> */}
    </>
  );
};

export default UserComponent;
