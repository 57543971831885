import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "react-html-parser";

import ImageEditor from "./ImageEditor.tsx";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Menu,
  MenuItem,
} from "@mui/material";
import "./../App.css";
import ReactMarkdown from "react-markdown";
import LogoImg from "./../img/wecon-mail.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { marked } from "marked";
import Quill, { RangeStatic } from "quill";
import Delta from "quill-delta";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { formToJSON } from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import ElementorTemplateGenerator from "./ElementorTemplateGenerator.tsx";

interface Heading {
  id: string;
  tag: string;
  title: string;
  subheadings?: Heading[];
  content: string;
  summary: string;
  characters: string;
  keywords: string;
}

interface TableOfContent {
  headings: Heading[];
}

interface LoginParams {
  links: string;
  keyword: string;
  all_keywords: string;
  faq: string;
  wordcount: string;
  heading_keywords: string;
}

interface TemplateElement {
  id: string;
  widgetType: string;
  settings: any;
  elements: TemplateElement[];
}

interface Template {
  id: string;
  settings: { _title: string };
  elements: TemplateElement[];
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

function SidesMedia(): JSX.Element {
  const [formData, setFormData] = useState<LoginParams>({
    links: "",
    keyword: "",
    all_keywords: "",
    faq: "",
    heading_keywords: "",
    wordcount: "",
  });
  const [lastWrappedHtml, setLastWrappedHtml] = useState<string | undefined>(
    ``
  );
  const [regeneratedText, setRegeneratedText] = useState<string | undefined>(
    ``
  );
  const [currentKeywords, setCurrentKeywords] = useState<string>(``);
  const [currentSummary, setCurrentSummary] = useState<string>(``);

  const [selectedRange, setSelectedRange] = useState<RangeStatic | null>(null);

  const [tableOfContent, setTableOfContent] = useState<TableOfContent | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingHeading, setLoadingHeading] = useState<boolean>(false);
  const [introductionWritten, setIntroductionWritten] =
    useState<boolean>(false);
  const [conclusionWritten, setConclusionWritten] = useState<boolean>(false);
  const [faqWritten, setFaqWritten] = useState<boolean>(false);

  const [loadingAllHeading, setLoadingAllHeading] = useState<boolean>(false);
  const [fixLinks, setFixLinks] = useState<boolean>(true);

  const [loadingIntroduction, setLoadingIntroduction] =
    useState<boolean>(false);
  const [loadingConclusion, setLoadingConclusion] = useState<boolean>(false);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(false);
  const [loadingFAQ, setLoadingFAQ] = useState<boolean>(false);

  const [articleGenerated, setArticleGenerated] = useState<string>(``);
  const [TokUpdateContent, setTokUpdateContent] = useState<string>(``);
  const [tretQuillContent, setTretQuillContent] = useState<string>(``);

  const [articleTitle, setArticleTitle] = useState<string>(``);

  const [rewriteAticle, setRewriteArticle] = useState<string[]>([]);
  const [articleStructure, setArticleStructure] = useState<string>(``);

  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [suggestedCategory, setSuggestedCategory] = useState<string | null>(
    null
  );
  const [suggestedTags, setSuggestedTags] = useState<string | null>(null);

  const [showReplaceField, setShowReplaceField] = useState<boolean>(false);
  const [showSmallTool, setShowSmallTool] = useState<boolean>(false);

  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);
  const [isGeneratingByHeader, setIsGeneratingByHeader] = useState(false);

  const [write_prompt, setWritePrompt] = useState<boolean>(false);
  const [smallTooltipPosition, setSmallTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [addedSpans, setAddedSpans] = useState<any[]>([]);
  const [showSmallTooltip, setShowSmallTooltip] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);

  const handleGenerateIntroduction = async () => {
    try {
      setLoadingIntroduction(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-introduction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            main_keyword: formData.keyword,
            content: articleGenerated,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the introduction");
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingIntroduction(false);
      // setArticleGenerated(   + "</br> </br>" + articleGenerated );
      setArticleGenerated((prev) => responseData + prev);
      setIntroductionWritten(true);
      return true;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const handleGenerateFAQ = async () => {
    try {
      // // debugger
      setLoadingFAQ(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-faq",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            faq_questions: formData.faq,
            all_keywords: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the FAQ");
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      let newReps = responseData.replaceAll("\n", ""); // Removing all \n characters
      setLoadingFAQ(false);
      setArticleGenerated((prev) => prev + newReps);
      setFaqWritten(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateTableOfContent = () => {
    if (tableOfContent) {
      const updatedHeadings = tableOfContent.headings.map((h, index) => {
        if (index === currentHeadingIndex) {
          return {
            ...h,
            content: h.content,
            summary: h.summary,
            keywords: h.keywords,
          };
        }
        return h;
      });

      setTableOfContent({ ...tableOfContent, headings: updatedHeadings });
    }
  };
  useEffect(() => {
    updateTableOfContent();
  }, [currentHeadingIndex]);

  const handleGenerateConclusion = async () => {
    try {
      setLoadingConclusion(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-conclusion",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            main_keyword: formData.keyword,
            content: articleGenerated,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the conclusion");
      }
      let responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingConclusion(false);
      // setArticleGenerated( articleGenerated + "</br> </br>" + responseData );
      setArticleGenerated(
        (prev) => prev + "<h2>Conclusion</h2>" + responseData
      );
      setConclusionWritten(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function createKeyWordsJson(input: any) {
    // Split the input string into an array of items
    const all_keywords = input.split(",");
    const processedItems: { keyword: any; limit: number }[] = [];

    // Map each item to an object with the specified structure
    all_keywords.forEach((item: any) => {
      if (item && item.length > 0) {
        // Extract the keyword by removing the numeric part and trim it to remove any leading/trailing whitespace
        const keyword = item.split("0/")[0].trim();

        // Extract the limit, which is the first number after "0/"
        const match = item.match(/0\/(\d+)/);
        if (!match) {
          throw new Error("Invalid format: " + item); // Throw an error if the format is invalid
        }
        const limit = match[1];

        processedItems.push({
          keyword: keyword,
          limit: parseInt(limit, 10), // Convert the string to an integer
        });
      }
    });

    return processedItems;
  }

  const validateKeywordsString = (all_keywords: any) => {
    try {
      let substring = ``;
      let current = 0;
      // Split the input string into an array of items
      for (let i = 0; i < all_keywords.length; i++) {
        if (String(all_keywords[i]) === "/") {
          current = i;
        }
        if (String(all_keywords[i]) === " " && current != 0) {
        } else {
          substring = substring + all_keywords[i];
        }
        if (String(all_keywords[i]) === " " && current != 0) {
          substring = substring + " , ";
          current = 0;
        }
      }

      let keyword_list = createKeyWordsJson(String(substring));
      console.log(keyword_list);

      return true;
    } catch (error) {
      // console.error("Error in validating keywords string:", error.message);
      return false;
    }
  };

  const [generatedTemplate, setGeneratedTemplate] = useState<Template | null>(
    null
  );

  const handleTemplateGenerated = (template: Template) => {
    setGeneratedTemplate(template);
  };

  const downloadTemplate = () => {
    if (generatedTemplate) {
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(generatedTemplate, null, 2)], {
        type: "application/json",
      });
      element.href = URL.createObjectURL(file);
      element.download = "template.json";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };
  const handleGenerateHeaderByHeader = async (): Promise<boolean> => {
    // debugger;
    const currentIndex = currentHeadingIndex; // Get the current index value at the start

    if (!tableOfContent || currentIndex >= tableOfContent.headings.length) {
      setIsGeneratingByHeader(false);
      return false; // Stop if no more headings or if the table of contents is not defined
    }

    let summary = "";
    const heading = tableOfContent.headings[currentIndex];
    summary = tableOfContent.headings
      .slice(0, currentIndex)
      .map((h) => h.content)
      .join(" ");

    let all_keywords =
      currentIndex == 0
        ? formData.all_keywords
        : tableOfContent.headings[currentIndex - 1].keywords;

    let int = Number(heading.characters);

    let char = String(Number(int * 200));
    try {
      setLoadingHeading(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-heading-content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            heading: heading,
            characters: char,
            competitors: formData.links,
            all_keywords: all_keywords,
            main_keyword: formData.keyword,
            all_summary: summary, // Send the constructed summary
          }),
        }
      );

      if (!response.ok) {
        setLoadingHeading(false);
        throw new Error("Failed to generate content for heading");
      }

      const data = await response.json();
      console.log(data.content); // Access the generated content
      console.log(data.summary); // Access the summary
      console.log(data.keywords);

      setArticleGenerated((prev) => prev + data.content);
      const updatedHeadings = tableOfContent.headings.map((h, index) => {
        if (index === currentIndex) {
          return {
            ...h,
            content: data.content,
            summary: data.summary,
            keywords: data.keywords,
          };
        }
        return h;
      });

      console.log("updatedHeadingggg", updatedHeadings);

      // Update the table of content state with the updated headings
      setTableOfContent({ ...tableOfContent, headings: updatedHeadings });

      console.log("....................", tableOfContent);

      setCurrentHeadingIndex((prev) => prev + 1); // Use functional update to ensure proper increment
      setLoadingHeading(false);
      setAbortController(null); // Clear the controller after the request completes
      return true; // Indicate that the generation was successful
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error generating content for heading:", error);
        setIsGeneratingByHeader(false);
        toast.error(String(error), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoadingHeading(false);
      setAbortController(null); // Clear the controller if there is an error
      return false; // Indicate that the generation failed
    }
  };

  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const range = quill.getSelection();
        if (range && range.length > 0) {
          let delta = quill.getContents(range.index, range.length);

          // Convert Delta to HTML
          const tempContainer = document.createElement("div");
          new Quill(tempContainer).setContents(delta);
          let selectedHtml =
            tempContainer.getElementsByClassName("ql-editor")[0].innerHTML;
          console.log("Selected HTML:", selectedHtml);
          if (selectedHtml.startsWith("<p>") && selectedHtml.endsWith("</p>")) {
            selectedHtml = selectedHtml.substring(3, selectedHtml.length - 4);
          }

          // setSelectedText(selectedHtml);
          // const wrappedHtml = `<span style="background-color: #d1ffbd;">${selectedHtml}</span>`;
          // const newDelta = quill.clipboard.convert(wrappedHtml);
          // const Delta = Quill.import('delta');
          // console.log("wrappedHtml", wrappedHtml);
          // setLastWrappedHtml(wrappedHtml);
          // console.log("setLastWrappedHtml", lastWrappedHtml);
          // console.log(range);
          // setSelectedRange(range);
          // console.log(selectedRange)
          // setAddedSpans([...addedSpans, { index: range.index, length: newDelta.length() }]);

          // // Update the content in Quill editor
          // quill.updateContents(new Delta().retain(range.index).delete(range.length).concat(newDelta));

          // // Clear the selection
          // quill.setSelection(range.index + newDelta.length(), 0);
          const scrollX =
            window.pageXOffset !== undefined
              ? window.pageXOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollLeft;
          const scrollY =
            window.pageYOffset !== undefined
              ? window.pageYOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollTop;

          console.log(addedSpans);
          const quillBounds = quill.getBounds(range.index, range.length);
          const editorElement = quill.root;
          const editorRect = editorElement.getBoundingClientRect();
          const tooltipX = editorRect.left + quillBounds.left + scrollX;
          const tooltipY = editorRect.top + quillBounds.bottom + scrollY;

          // setTooltipPosition({ x: tooltipX, y: tooltipY });
          setSmallTooltipPosition({ x: tooltipX, y: tooltipY });
          setShowSmallTool(true);
          setShowReplaceField(true);
        } else {
          if (write_prompt) {
            let oldhtml = lastWrappedHtml?.replace(
              `<span style="background-color: #d1ffbd;">`,
              ""
            );
            oldhtml = oldhtml?.replace(`</span>`, "");
            console.log(
              "oldhtmloldhtml>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
              oldhtml
            );
            const newDelta = quill.clipboard.convert(oldhtml);
            const Delta = Quill.import("delta");

            const lastSpan = addedSpans.pop();
            console.log("lastSpan", lastSpan);
            if (lastSpan) {
              quill.updateContents(
                new Delta()
                  .retain(lastSpan.index)
                  .delete(lastSpan.length)
                  .concat(newDelta)
              );
              setAddedSpans([...addedSpans]);
            }
            setSelectedRange(null);
            console.log(selectedRange);
            setShowReplaceField(false);
            setTooltipPosition(null);
          }

          setShowSmallTool(false);
        }
      }
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [addedSpans]);

  // useEffect(() => {
  //   if (isGeneratingByHeader) {
  //     handleGenerateHeaderByHeader();
  //   }
  // }, [isGeneratingByHeader, currentHeadingIndex]);

  //   useEffect(() => {
  //     const socket = io('https://servercode.wecon-digital.agency'); // Make sure this URL matches your server URL

  //     socket.on('progress', (data) => {
  //       console.log('Progress:', data.progress);
  //     });

  //     socket.on('articleGenerated', (data) => {
  //       console.log('Article Generated:', data.article);
  //     });

  //     return () => {
  //       socket.disconnect();
  //     };
  //   }, []);

  function handleAcceptRegeneratedText() {
    // // debugger
    const newquill = quillGeneratedText.current?.getEditor();
    if (newquill) {
      const length = newquill.getLength(); // Get the length of the content
      newquill.setSelection(0, length); // Select all the content
      let fullContent = newquill.root.innerHTML; // Get the full HTML content
      if (fullContent.startsWith("<p>") && fullContent.endsWith("</p>")) {
        fullContent = fullContent.substring(3, fullContent.length - 4);
      }

      console.log("Full HTML Content:", fullContent);
      console.log(selectedRange);
      const quill = quillRef.current?.getEditor();
      if (quill && selectedRange) {
        const delta = quill.clipboard.convert(fullContent);
        const Delta = Quill.import("delta");
        const updatedDelta = new Delta()
          .retain(selectedRange.index)
          .delete(selectedRange.length)
          .concat(delta);
        quill.updateContents(updatedDelta);

        // Clear the selection
        quill.setSelection(selectedRange.index + delta.length(), 0);
      }

      setSelectedRange(null);
      setLastWrappedHtml(undefined);
      // setExtraText('')
      setRegeneratedText(``);
      setWritePrompt(false);
      // setRegeneratedText(undefined);
      console.log(selectedRange);
      setShowReplaceField(false);
      setTooltipPosition(null);
    }
  }
  function cancelRegen() {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      console.log("cancelRegen", lastWrappedHtml);
      let oldhtml = lastWrappedHtml?.replace(
        `<span style="background-color: #d1ffbd;">`,
        ""
      );
      oldhtml = oldhtml?.replace(`</span>`, "");
      console.log("oldhtmloldhtml>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", oldhtml);
      const newDelta = quill.clipboard.convert(oldhtml);
      const Delta = Quill.import("delta");

      const lastSpan = addedSpans.pop();
      console.log("lastSpan", lastSpan);
      if (lastSpan) {
        quill.updateContents(
          new Delta()
            .retain(lastSpan.index)
            .delete(lastSpan.length)
            .concat(newDelta)
        );
        setAddedSpans([...addedSpans]);
      }
      setSelectedRange(null);
      setLastWrappedHtml(undefined);
      // setExtraText('')
      setWritePrompt(false);
      setRegeneratedText(``);
      console.log(selectedRange);
      setShowReplaceField(false);
      setTooltipPosition(null);
    }
  }

  const [extraText, setExtraText] = useState("");

  const handleExtraTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExtraText(event.target.value);
  };
  async function handleRegenerateSelection(params: any) {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      let range = quill.getSelection();
      if (range == null) {
        range = selectedRange;
      }
      if (range && range.length > 0) {
        const selectedText = quill.getText(range.index, range.length);
        console.log("Selected Text:", selectedText);
        console.log("lastWrappedHtml Text:", lastWrappedHtml);
        try {
          const response = await fetch(
            "https://servercode.wecon-digital.agency/regenerate-prompt",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                prompt: extraText,
                selection: lastWrappedHtml,
              }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
          }

          let responseData = await response.text(); // Getting the response as text (HTML string)
          setRegeneratedText(responseData);
          // const delta = quill.clipboard.convert(responseData);
          // const Delta = Quill.import('delta')
          // const updatedDelta = new Delta().retain(range.index).delete(range.length).concat(delta);
          // quill.updateContents(updatedDelta);

          // // Clear the selection
          // quill.setSelection(range.index + delta.length(), 0);
          setWritePrompt(true);
          // setShowReplaceField(false);
        } catch {
          console.log("ERROR Regenerating prompt");
        }
      }
    }
  }

  function wrapMultipleInHeading2(
    htmlContent: string,
    substringsToWrap: string[]
  ): string {
    // Create a DOM parser to convert the string to an HTML document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Select all <h3> elements
    const h3Elements = doc.querySelectorAll("h3");

    h3Elements.forEach((element) => {
      // Check if the inner content includes any of the specified substrings
      substringsToWrap.forEach((substring) => {
        if (element.innerHTML.toLowerCase().includes(substring.toLowerCase())) {
          // Change the <h3> tag to <h2>
          const newElement = document.createElement("h2");
          newElement.innerHTML = element.innerHTML;
          if (element.parentNode) {
            element.parentNode.replaceChild(newElement, element);
          }
        }
      });
    });

    // Serialize the document back to a string
    return doc.body.innerHTML;
  }

  async function urlIsLive(url: string): Promise<boolean> {
    try {
      const response = await fetch(url, { method: "HEAD", mode: "no-cors" });
      debugger;
      return response.ok;
    } catch (error) {
      console.error(`Error checking URL ${url}:`, error);
      return false;
    }
  }

  async function wrapMultipleInHeading3WithLink(
    htmlContent: string,
    substringsToWrap: string[],
    keyword: string,
    fixLinks: boolean
  ): Promise<string> {
    // Create a DOM parser to convert the string to an HTML document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
  
    // Iterate over each substring that needs to be wrapped in <h3> tags
    for (const substring of substringsToWrap) {
      const h2Elements = doc.querySelectorAll<HTMLElement>("h2");
  
      // @ts-ignore
      for (const element of h2Elements) {
        if (element.innerHTML.toLowerCase().includes(substring.toLowerCase())) {
          let newContent = element.innerHTML;
  
          if (fixLinks) {
            // Generate the new link based on the substring and keyword
            const newLink = `https://www.${substring.toLowerCase()}.com/${keyword
              .toLowerCase()
              .replaceAll(" ", "-")}`;
  
            // Check if the substring is wrapped in a link
            const linkMatch = newContent.match(
              /<a[^>]*href=["']([^"']*)["'][^>]*>(.*?)<\/a>/i
            );
  
            if (linkMatch) {
              const currentLink = linkMatch[1];
              const linkText = linkMatch[2];
  
              // Check if the link is live
              const isLive = await urlIsLive(currentLink);
              if (!isLive) {
                // Replace the incorrect or dead link with the correct one
                element.innerHTML = element.innerHTML.replace(
                  linkMatch[0],
                  `<a href="${newLink}">${linkText}</a>`
                );
              }
            } else {
              // Add a new link if not already linked
              newContent = `<a href="${newLink}">${newContent}</a>`;
            }
          }
  
          // Create the new <h3> element
          const h3 = document.createElement("h3");
          h3.innerHTML = newContent;
          h3.style.marginTop = "4rem";
  
          // Add <br> before the <h3>
          const br = document.createElement("br");
          element.before(br);
  
          // Replace the old <h2> element with the new <h3>
          element.replaceWith(h3);
        }
      }
    }
  
    // Apply margin-top and ensure <br> tags are properly added before each <h2> element
    const h2Elements = doc.querySelectorAll<HTMLElement>("h2");
    h2Elements.forEach((element) => {
      element.style.marginTop = "4rem";
  
      if (!element.previousElementSibling || element.previousElementSibling.tagName !== "BR") {
        const br1 = document.createElement("br");
        const br2 = document.createElement("br");
        element.before(br1, br2);
      }
    });
  
    // Apply margin-top and ensure <br> tags are properly added before each <h3> element
    const h3Elements = doc.querySelectorAll<HTMLElement>("h3");
    h3Elements.forEach((element) => {
      element.style.marginTop = "4rem";
  
      if (!element.previousElementSibling || element.previousElementSibling.tagName !== "BR") {
        const br = document.createElement("br");
        element.before(br);
      }
    });
  
    return doc.body.innerHTML;
  }
  
  
  const substringsToWrap = [
    "UseViral",
    "SidesMedia",
    "Growthoid",
    "Twesocial",
    "TokUpgrade",
  ];
  async function removeUnnecessarySpans(htmlContent: string): Promise<string> {
    // Create a DOM parser to convert the string to an HTML document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    // Select all <span> elements with the style color: rgb(0, 0, 0)
    const spansToRemove = doc.querySelectorAll(
      'span[style="color: rgb(0, 0, 0);"]'
    );

    spansToRemove.forEach((span) => {
      // Replace each <span> with its inner content, effectively removing the span
      const parent = span.parentNode;
      while (span.firstChild) {
        // @ts-ignore
        parent.insertBefore(span.firstChild, span);
      }
      // @ts-ignore
      parent.removeChild(span);
    });

    // Select all other empty or unnecessary spans
    const emptySpans = doc.querySelectorAll('span:empty, span[style=""]');

    emptySpans.forEach((span) => {
      // @ts-ignore
      span.parentNode.removeChild(span);
    });

    // Select all empty list items <li></li>
    const emptyListItems = doc.querySelectorAll("li:empty");

    emptyListItems.forEach((li) => {
      // @ts-ignore
      li.parentNode.removeChild(li);
    });

    // Serialize the document back to a string
    return doc.body.innerHTML;
  }

  async function addLinksToListItems(
    htmlString: string,
    keyword: string
  ): Promise<string> {
    // Create a new DOM parser to convert the HTML string into a document object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // The substrings we want to wrap with a link
    const substringsToWrap = [
      "UseViral",
      "SidesMedia",
      "Growthoid",
      "Twesocial",
      "TokUpgrade",
    ];

    // Get all ordered and unordered lists in the document
    const lists = doc.querySelectorAll("ol, ul");

    let foundAllSubstrings = false; // Flag to stop after finding all substrings in one list

    // Iterate through each list
    lists.forEach((list) => {
      if (foundAllSubstrings) return; // If all substrings were found in a previous list, exit the loop

      // Create a set to track which substrings are found in this list
      const foundSubstrings = new Set<string>();

      // Get all list items in the current list
      const listItems = list.querySelectorAll("li");

      // Iterate through each list item
      listItems.forEach((item) => {
        substringsToWrap.forEach((substring) => {
          // Check if the item contains the substring
          // @ts-ignore
          if (item.textContent.includes(substring)) {
            foundSubstrings.add(substring); // Add the substring to the found set

            // Create a new anchor element
            const link = document.createElement("a");
            link.href = `https://www.${substring.toLowerCase()}.com/${keyword
              .toLowerCase()
              .replaceAll(" ", "-")}`;
            link.textContent = item.textContent; // Preserve the original text

            // Replace the list item's text with the link
            item.innerHTML = "";
            item.appendChild(link);
          }
        });
      });

      // If all substrings have been found in this list, set the flag to true
      if (foundSubstrings.size === substringsToWrap.length) {
        foundAllSubstrings = true;
      }
    });

    // Serialize the updated document back into an HTML string
    return doc.body.innerHTML;
  }


  async function removeEmptyListItems(htmlContent: string): Promise<string> {
    // Create a DOM parser to convert the string to an HTML document
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
  
    // Select all <li> elements
    const listItems = doc.querySelectorAll('li');
  
    listItems.forEach(li => {
        // Check if the <li> is empty or contains only a <br> tag
        if (li.innerHTML.trim() === '' || li.innerHTML.trim() === '<br>') {
            // @ts-ignore
            li.parentNode.removeChild(li);
        }
    });
  
    // Serialize the document back to a string
    return doc.body.innerHTML;
  }
  
  const handleEditorChange = (html: string) => {
    const updatedHtmlContent = wrapMultipleInHeading2(html, substringsToWrap);
    console.log(updatedHtmlContent);
    const cleanedHtmlContent = removeUnnecessarySpans(updatedHtmlContent);

    setArticleGenerated(updatedHtmlContent);
  };

  const handleEditorTokUpdateChange = (html: string) => {
    setTokUpdateContent(html);
  };
  const handleTretChange = (html: string) => {
    setTretQuillContent(html);
  };
  const generateUniqueId = () => Math.random().toString(36).substr(2, 9);

  const addUniqueIds = (headings: any[]): Heading[] => {
    return headings.map((heading) => ({
      ...heading,
      id: generateUniqueId(),
      subheadings: heading.subheadings ? addUniqueIds(heading.subheadings) : [],
      characters: heading.characters / 200,
      content: "",
      summary: "",
      keywords: "",
    }));
  };

  const updateTitleById = (
    headings: Heading[],
    id: string,
    newTitle: string
  ): Heading[] => {
    return headings.map((heading) => {
      if (heading.id === id) {
        return { ...heading, title: newTitle };
      } else if (heading.subheadings) {
        return {
          ...heading,
          subheadings: updateTitleById(heading.subheadings, id, newTitle),
        };
      } else {
        return heading;
      }
    });
  };

  const updateWordCountById = (
    headings: Heading[],
    id: string,
    newWordCount: string
  ): Heading[] => {
    return headings.map((heading) => {
      if (heading.id === id) {
        return { ...heading, characters: newWordCount };
      } else if (heading.subheadings) {
        return {
          ...heading,
          subheadings: updateWordCountById(
            heading.subheadings,
            id,
            newWordCount
          ),
        };
      } else {
        return heading;
      }
    });
  };

  const addNestedHeading = (
    headings: Heading[],
    path: number[],
    heading_tag: string
  ): Heading[] => {
    if (path.length === 0) {
      return [
        ...headings,
        {
          id: generateUniqueId(),
          tag: heading_tag,
          title: "New Subheading",
          subheadings: [],
          content: "",
          summary: "",
          keywords: "",
          characters: "",
        },
      ];
    }

    return headings.map((heading, i) =>
      i === path[0]
        ? {
            ...heading,
            subheadings: addNestedHeading(
              heading.subheadings || [],
              path.slice(1),
              heading_tag
            ),
          }
        : heading
    );
  };

  const deleteNestedHeading = (
    headings: Heading[],
    path: number[]
  ): Heading[] => {
    if (path.length === 1) {
      return headings.filter((_, i) => i !== path[0]);
    }

    return headings.map((heading, i) =>
      i === path[0]
        ? {
            ...heading,
            subheadings: deleteNestedHeading(
              heading.subheadings || [],
              path.slice(1)
            ),
          }
        : heading
    );
  };

  async function handleLogin(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault(); // Prevents the default form submission behavior
    if (
      formData.links.trim().length < 10 ||
      formData.keyword.trim().length < 10 ||
      !formData.all_keywords.trim() ||
      formData.faq.trim().length < 10
    ) {
      toast.error("Some fields are invalid!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop execution if any field is empty
    }
    if (!validateKeywordsString(formData.all_keywords)) {
      toast.error("Keywords are bad copied!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop if the validation fails
    }
    setLoading(true);

    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-toc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            links: formData.links,
            wordcount: formData.wordcount,
            heading_keywords: formData.heading_keywords.replaceAll("0", ","),
            keyword: formData.keyword,
            all_keyword: formData.all_keywords,
            faq: formData.faq,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const responseData = await response.json();
      const tocWithIds = addUniqueIds(responseData.table_of_content.headings);
      setTableOfContent({ headings: tocWithIds });
      setSuggestedTags(responseData.tags);
      setSuggestedCategory(responseData.category);

      setLoading(false);
      setCurrentHeadingIndex(0);
    } catch (error) {
      console.error("Error generating TOC:", error);
      toast.error(String(error), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const generateFullArticle = async () => {
    setIsGeneratingByHeader(true);
    let continueGenerating: boolean = true;
    let localIndex = currentHeadingIndex; // Use a local index to track the current heading index
    let currentKeywords = formData.all_keywords;
    let currentSummary = "";

    if (tableOfContent) {
      const controller = new AbortController(); // Create a new AbortController
      setAbortController(controller); // Track the controller

      while (
        continueGenerating &&
        localIndex < tableOfContent.headings.length
      ) {
        try {
          setLoadingAllHeading(true);
          const heading = tableOfContent.headings[localIndex];

          const response = await fetch(
            "https://servercode.wecon-digital.agency/generate-heading-content",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                heading: heading,
                characters: heading.characters,
                competitors: formData.links,
                all_keywords: currentKeywords,
                main_keyword: formData.keyword,
                all_summary: currentSummary, // Send the constructed summary
              }),
              signal: controller.signal, // Attach the abort signal to the fetch request
            }
          );

          if (!response.ok) {
            setLoadingAllHeading(false);
            throw new Error("Failed to generate content for heading");
          }

          const data = await response.json();
          console.log(data.content); // Access the generated content
          console.log(data.summary); // Access the summary
          console.log(data.keywords);

          setArticleGenerated((prev) => prev + data.content);

          const updatedHeadings = tableOfContent.headings.map((h, idx) => {
            if (idx === localIndex) {
              return {
                ...h,
                content: data.content,
                summary: data.summary,
                keywords: data.keywords,
              };
            }
            return h;
          });

          setTableOfContent((prev) => ({
            ...prev,
            headings: updatedHeadings,
          }));

          currentKeywords = data.keywords;
          currentSummary = data.summary;

          setCurrentHeadingIndex(localIndex + 1); // Ensure currentHeadingIndex is updated
          localIndex += 1; // Increment the local index after each generation
          setLoadingAllHeading(false);
        } catch (error) {
          console.error("Error generating content for heading:", error);
          setLoadingAllHeading(false);
          toast.error(String(error), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          continueGenerating = false; // Stop further processing on error
        }
      }
      let introduction = await handleGenerateIntroduction();
      let concl1 = await handleGenerateFAQ();
      let concl = await handleGenerateConclusion();

      setIsGeneratingByHeader(false); // Done generating all headings
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    if (tableOfContent) {
      const items = Array.from(tableOfContent.headings);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setTableOfContent({ headings: items });
    }
  };

  const addHeading = () => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: [
          {
            id: generateUniqueId(),
            tag: "h2",
            title: "New Heading",
            subheadings: [],
            content: "",
            summary: "",
            keywords: "",
            characters: "",
          },
          ...prevToc!.headings,
        ],
      }));
    }
  };

  const deleteHeading = (index: number) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: prevToc!.headings.filter((_, i) => i !== index),
      }));
    }
  };

  const addSubheading = (heading: string, path: number[]) => {
    // // debugger
    if (tableOfContent) {
      if (heading === "h2") {
        heading = "h3";
      } else if (heading === "h3") {
        heading = "h4";
      } else if (heading == "h1") {
        heading = "h3";
      }
      setTableOfContent((prevToc) => ({
        headings: addNestedHeading(prevToc!.headings, path, heading),
      }));
    }
  };

  const deleteSubheading = (path: number[]) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: deleteNestedHeading(prevToc!.headings, path),
      }));
    }
  };

  const convertMarkdownToHtml = (markdown: string): string => {
    return marked(markdown).toString();
  };

  const updateTitle = (id: string, newTitle: string) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: updateTitleById(prevToc!.headings, id, newTitle),
      }));
    }
  };
  const updateWordCount = (id: string, newTitle: string) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: updateWordCountById(prevToc!.headings, id, newTitle),
      }));
    }
  };

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const handleStopGeneration = () => {
    // debugger
    if (abortController) {
      abortController.abort(); // Abort the ongoing request
      setAbortController(null); // Clear the controller
      setLoading(false);
      setIsGeneratingByHeader(false); // Update state to stop further processing
      setIntroductionWritten(false);
      setConclusionWritten(false);
      setFaqWritten(false);
      setLoadingIntroduction(false);
      setLoadingConclusion(false);
      setLoadingFAQ(false);
      toast.error("Generation stopped", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderSubheadings = (
    subheadings: Heading[] | undefined,
    path: number[] = []
  ) => {
    if (!subheadings) return null;
    return (
      <Box pl={2} p={1}>
        {subheadings.map((subheading, subIndex) => (
          <Box key={subheading.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                {subheading.tag.toUpperCase() + " "}
              </div>
              <TextField
                style={{ minWidth: "35rem" }}
                value={subheading.title}
                onChange={(e) => updateTitle(subheading.id, e.target.value)}
                fullWidth
              />
              {subheading.tag === "h4" ? (
                ``
              ) : (
                <Button
                  style={{
                    minWidth: "7rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    addSubheading(subheading.tag, [...path, subIndex])
                  }
                >
                  Add H4
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => deleteSubheading([...path, subIndex])}
              >
                Delete
              </Button>
            </Box>

            {renderSubheadings(subheading.subheadings, [...path, subIndex])}
          </Box>
        ))}
      </Box>
    );
  };
  const quillRef = useRef<ReactQuill | null>(null);
  const quillGeneratedText = useRef<ReactQuill | null>(null);
  const TokUpdateQuill = useRef<ReactQuill | null>(null);
  const tretQuill = useRef<ReactQuill | null>(null);

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleGenerateArticle = async () => {
    if (!tableOfContent) return;
    setArticleGenerated("");
    setLoadingArticle(true);
    setProgress(0); // Reset progress

    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-article",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            headings: tableOfContent,
            keyword: formData.keyword,
            competitors: formData.links,
            all_keywords: formData.all_keywords,
            faq: formData.faq,
            main_keyword: formData.keyword,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      setArticleGenerated(responseData);
      setLoadingArticle(false);
    } catch (error) {
      console.error("Error generating article:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGrowthoidUploadPost = async () => {
    try {
      //
      setLoading(true);
      const response = await fetch(
        "https://featured-image.wecon-digital.agency/generate-wordpress-articles-growthoid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            articleGenerated: articleGenerated,
            title: articleTitle,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save articles");
      }

      const responseData = await response.json();

      console.log("Articles saved successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error saving articles:", error);
    }
  };

  const handleConsoleLog = async () => {
    console.log(articleGenerated);
  };

  interface Section {
    type: "text" | "heading" | "list";
    content: string;
    headingType?: "h2" | "h3" | "h4";
  }

  function parseContent(html: string): Section[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.childNodes;
    const sections: Section[] = [];
    let currentText = "";

    elements.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (["H2", "H3", "H4"].includes(element.tagName)) {
          if (currentText) {
            sections.push({ type: "text", content: currentText.trim() });
            currentText = "";
          }
          sections.push({
            type: "heading",
            content: element.outerHTML.trim(),
            headingType: element.tagName.toLowerCase() as "h2" | "h3" | "h4",
          });
        } else if (element.tagName === "UL" || element.tagName === "OL") {
          if (currentText) {
            sections.push({ type: "text", content: currentText.trim() });
            currentText = "";
          }
          sections.push({ type: "list", content: element.outerHTML.trim() });
        } else {
          currentText += element.outerHTML + " ";
        }
      }
    });

    if (currentText) {
      sections.push({ type: "text", content: currentText.trim() });
    }

    return sections;
  }

  async function handlePreviewStructure(service: string) {
    console.log(articleGenerated);
    let sections = parseContent(articleGenerated);
    console.log(sections);
    let fullContent = [];
    let previewContent = ``;

    let current_heading = ``;

    for (let i = 0; i < sections.length; i++) {
      let current_section = sections[i];
      if (current_section.content === `<p><br></p>`) {
        continue;
      }

      if (current_section.headingType === "h2" && current_heading.length > 0) {
        // fullContent.push(current_heading);
        current_heading.replaceAll("<p><br></p>", "");
        fullContent.push(current_heading);

        current_heading = ``;
        current_heading += " " + current_section.content;
        previewContent += " " + current_section.content;
      } else {
        current_heading += " " + current_section.content;
      }
    }
    fullContent.push(current_heading);
    setArticleStructure(previewContent);
    setRewriteArticle(fullContent);

    try {
      //
      // setLoading(true)
      // const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-articles-growthoid", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ articleGenerated }),
      // });

      // if (!response.ok) {
      //   throw new Error("Failed to save articles");
      // }

      // const responseData = await response.json();

      // console.log("Articles saved successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error saving articles:", error);
    }
  }

  async function processHtmlWithHeadingsAndBreaks(
    htmlContent: string
  ): Promise<string> {
    // Helper function to insert break lines for readability
    function insertBreaksForReadability(text: string): string {
      const sentences = text.match(/[^.!?]+[.!?]+[\s]*/g) || []; // Split text into sentences

      let result = "";
      let sentenceCount = 0;
      let characterCount = 0;

      sentences.forEach((sentence) => {
        sentenceCount++;
        characterCount += sentence.length;

        result += sentence.trim() + " ";

        // Insert breakline after a long sentence or every 2-3 short sentences
        if (characterCount > 120 || sentenceCount === 2) {
          result += "<br>"; // Add a break line
          sentenceCount = 0;
          characterCount = 0;
        }
      });

      return result.trim();
    }

    // Helper function to process sections by ensuring no new lines are added in list items or headings
    function processSection(section: string): string {
      // Create a DOM parser to convert the section string to a document object
      const parser = new DOMParser();
      const doc = parser.parseFromString(section, "text/html");

      // Loop over all non-list and non-heading elements to add breaks for readability
      doc.body.childNodes.forEach((node) => {
        const element = node as HTMLElement;

        // Skip lists and headings from being processed for breaks
        if (
          element.nodeName === "OL" ||
          element.nodeName === "UL" ||
          element.nodeName === "H1" ||
          element.nodeName === "H2" ||
          element.nodeName === "H3" ||
          element.nodeName === "H4" ||
          element.nodeName === "H5" ||
          element.nodeName === "H6"
        ) {
          return; // Skip processing for lists and headings
        }

        // Process text content of the node for readability breaks
        if (element.innerHTML) {
          element.innerHTML = insertBreaksForReadability(element.innerHTML);
        }
      });

      return doc.body.innerHTML;
    }

    // Split the HTML content into sections by <h2> or <h3> tags
    const sections = htmlContent.split(
      /(<h2[^>]*>.*?<\/h2>|<h3[^>]*>.*?<\/h3>)/g
    );

    // Iterate through each section and process content between <h2> and <h3> tags
    let processedContent = "";

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      // If it's an <h2> or <h3> tag, add it to the final content without modification
      if (section.startsWith("<h2") || section.startsWith("<h3")) {
        processedContent += section;
      } else {
        // Process the content that is not within <h2> or <h3> sections
        processedContent += processSection(section);
      }
    }

    return processedContent;
  }

  async function handleFineTuning() {
    console.log(articleGenerated);
    debugger;
    let first_text = ``;
    // First html
    first_text = await processHtmlWithHeadingsAndBreaks(TokUpdateContent);

    console.log(first_text);
    first_text = await removeUnnecessarySpans(first_text);
    first_text = await addLinksToListItems(first_text, articleTitle);
    first_text = await removeEmptyListItems(first_text);
    first_text = await wrapMultipleInHeading3WithLink(
      first_text,
      substringsToWrap,
      articleTitle,
      fixLinks
    );
    // Second html
    const separate_sentences = await processHtmlWithHeadingsAndBreaks(
      tretQuillContent
    );

    const second_text = await wrapMultipleInHeading3WithLink(
      separate_sentences,
      substringsToWrap,
      articleTitle,
      fixLinks
    );

    let cleanedHtmlContent_second_text = await removeUnnecessarySpans(
      second_text
    );
    cleanedHtmlContent_second_text = await addLinksToListItems(
      cleanedHtmlContent_second_text,
      articleTitle
    );

    setTokUpdateContent(first_text);
    setTretQuillContent(cleanedHtmlContent_second_text);
  }

  async function handleRewriteText(service: string) {
    try {
      let rewritedContent = ``;
      let tret_content = ``;
      for (let j = 0; j < rewriteAticle.length; j++) {
        console.log(rewriteAticle[j]);
        let first_text = ``;
        try {
          const response = await fetch(
            "https://servercode.wecon-digital.agency/regenerate-prompt-rewrites",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                prompt: `I need you to rewrite and paraphrase it in a more professional but simple language. Dont use adjectives. Please keep the text ( example: sentence1.sentece2 </br> </br> sentence1 sentence2…).`,
                selection: rewriteAticle[j],
                keyword: articleTitle,
              }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
          }

          // setTokUpdateContent(previewContent)
          let responseData = await response.text(); // Getting the response as text (HTML string)
          // setRegeneratedText(responseData);
          rewritedContent += responseData;
          // setTokUpdateContent(previewContent )
          first_text = responseData;
          setWritePrompt(true);
          // setShowReplaceField(false);
          // try {
          //   const response2 = await fetch(
          //     "https://servercode.wecon-digital.agency/regenerate-prompt-rewrites",
          //     {
          //       method: "POST",
          //       headers: {
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify({
          //         prompt: `remember that the main keyword is "${articleTitle}" so dont rephrase this word. Please keep the new and empty rows as in the original as a markdown text ( example: sentence1.sentece2 </br> </br> sentence1 sentence2…).`,
          //         selection: rewriteAticle[j],
          //         keyword:articleTitle
          //       }),
          //     }
          //   );

          //   if (!response2.ok) {
          //     const errorData = await response2.json();
          //     throw new Error(errorData.message);
          //   }

          //   // setTokUpdateContent(previewContent)
          //   let responseData = await response2.text(); // Getting the response as text (HTML string)
          //   // setRegeneratedText(responseData);
          //   tret_content +=  responseData
          //   // setTokUpdateContent(previewContent )

          //   setWritePrompt(true);
          //   // setShowReplaceField(false);
          // } catch {
          //   console.log("ERROR Regenerating prompt");
          // }
          // setTretQuillContent(tret_content)
        } catch {
          console.log("ERROR Regenerating prompt");
        }
        setTokUpdateContent(rewritedContent);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error saving articles:", error);
    }
  }

  return (
    <div style={{ margin: "3rem 3rem" }}>
      {smallTooltipPosition && showSmallTool && (
        <Box
          ref={tooltipRef}
          position="absolute"
          left={smallTooltipPosition.x}
          top={smallTooltipPosition.y} // Adjust position below the selection
          bgcolor="black"
          color="white"
          p={0.5}
          zIndex={10}
          display="block"
          alignItems="start"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <IconButton
            size="small"
            onClick={() => {
              const quill = quillRef.current?.getEditor();
              if (quill) {
                const range = quill.getSelection();
                if (range && range.length > 0) {
                  let delta = quill.getContents(range.index, range.length);

                  // Convert Delta to HTML
                  const tempContainer = document.createElement("div");
                  new Quill(tempContainer).setContents(delta);
                  let selectedHtml =
                    tempContainer.getElementsByClassName("ql-editor")[0]
                      .innerHTML;
                  console.log("Selected HTML:", selectedHtml);
                  if (
                    selectedHtml.startsWith("<p>") &&
                    selectedHtml.endsWith("</p>")
                  ) {
                    selectedHtml = selectedHtml.substring(
                      3,
                      selectedHtml.length - 4
                    );
                  }

                  setSelectedText(selectedHtml);
                  const wrappedHtml = `<span style="background-color: #d1ffbd;">${selectedHtml}</span>`;
                  const newDelta = quill.clipboard.convert(wrappedHtml);
                  const Delta = Quill.import("delta");
                  console.log("wrappedHtml", wrappedHtml);
                  setLastWrappedHtml(wrappedHtml);
                  console.log("setLastWrappedHtml", lastWrappedHtml);
                  console.log(range);
                  setSelectedRange(range);
                  console.log(selectedRange);
                  setAddedSpans([
                    ...addedSpans,
                    { index: range.index, length: newDelta.length() },
                  ]);

                  // Update the content in Quill editor
                  quill.updateContents(
                    new Delta()
                      .retain(range.index)
                      .delete(range.length)
                      .concat(newDelta)
                  );

                  // Clear the selection
                  quill.setSelection(range.index + newDelta.length(), 0);
                  const scrollX =
                    window.pageXOffset !== undefined
                      ? window.pageXOffset
                      : (
                          document.documentElement ||
                          document.body.parentNode ||
                          document.body
                        ).scrollLeft;
                  const scrollY =
                    window.pageYOffset !== undefined
                      ? window.pageYOffset
                      : (
                          document.documentElement ||
                          document.body.parentNode ||
                          document.body
                        ).scrollTop;

                  console.log(addedSpans);
                  const quillBounds = quill.getBounds(
                    range.index,
                    range.length
                  );
                  const editorElement = quill.root;
                  const editorRect = editorElement.getBoundingClientRect();
                  const tooltipX = editorRect.left + scrollX + quillBounds.left;
                  const tooltipY =
                    editorRect.top + scrollY + quillBounds.bottom;

                  setTooltipPosition({ x: tooltipX, y: tooltipY });
                  // setSmallTooltipPosition({ x: tooltipX, y: tooltipY });
                  setShowSmallTool(false);
                  setShowReplaceField(true);
                }
              }
            }}
            sx={{ width: "20px", height: "20px", color: "white" }} // Customize the width and height here
          >
            <EditIcon />
          </IconButton>
        </Box>
      )}
      {tooltipPosition && showReplaceField && (
        <Box
          ref={tooltipRef}
          position="absolute"
          left={tooltipPosition.x}
          top={tooltipPosition.y + 10} // Adjust position below the selection
          bgcolor="white"
          // color="white"
          border="1px solid #ccc"
          p={5}
          zIndex={10}
          display="block"
          alignItems="start"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <div>
            <ReactQuill
              ref={quillGeneratedText}
              value={regeneratedText}
              theme="snow"
              style={{ maxWidth: "50rem" }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </div>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Write prompt"
              multiline={true}
              rows={5}
              placeholder=""
              style={{ marginRight: "1rem", minWidth: "30rem" }}
              value={extraText}
              onChange={handleExtraTextChange}
            />
            {!write_prompt ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleRegenerateSelection}
                >
                  Re-Generate
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  style={{ marginLeft: "1rem" }}
                  size="small"
                  onClick={cancelRegen}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleAcceptRegeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={handleRegenerateSelection}
                >
                  Try Again
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={cancelRegen}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Box>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: "100%",
          // gap:'10px'
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "32.5%",
            gap: "20px",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Article Focus Keyphrase"
            multiline={true}
            placeholder=""
            style={{ minWidth: "30rem" }}
            value={articleTitle}
            onChange={(e) => {
              setArticleTitle(e.target.value);
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={(e) => {
                handlePreviewStructure("growthoid");
              }}
            >
              {" "}
              Check Structure
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                handleRewriteText("growthoid");
              }}
            >
              {" "}
              Rewrite text
            </Button>
            <Button
              variant="contained"
              onClick={(e) => {
                handleFineTuning();
              }}
            >
              {" "}
              Fix structure
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <Checkbox
                checked={fixLinks}
                onChange={(e) => {
                  setFixLinks(!fixLinks);
                }}
              ></Checkbox>
              Fix Links ?
            </div>
          </div>
          <div
            style={{ border: "1px solid gray" }}
            dangerouslySetInnerHTML={{ __html: articleStructure }}
          />

          <ReactQuill
            ref={quillRef}
            value={articleGenerated}
            onChange={handleEditorChange}
            theme="snow"
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          {/* <Button
            variant="contained"
            onClick={(e) => {
              handlePreviewStructure("growthoid");
            }}
          >
            {" "}
            Check Structure
          </Button>
          <div dangerouslySetInnerHTML={{ __html: articleStructure }} />
          <Button
            variant="contained"
            onClick={(e) => {
              handleRewriteText("growthoid");
            }}
          >
            {" "}
            Rewrite text
          </Button> */}
          {/* <Button variant="contained" onClick={handleGrowthoidUploadPost}> Upload On Growthoid </Button> */}
          {/* <Button variant="contained" onClick={handleConsoleLog}> Print </Button> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "45.5%",
            marginTop: "0rem",
          }}
        >
           <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={()=>{
                    // let remove= TokUpdateContent.replaceAll('<p><br></p>','')
                    navigator.clipboard.writeText(TokUpdateContent  ).then(() => {
                      console.log("Text copied to clipboard!");
                    }).catch(err => {
                      console.error("Failed to copy text: ", err);
                    });
                  }}
                >
                  Copy to clipboard
                </Button>
          <ReactQuill
            ref={TokUpdateQuill}
            value={TokUpdateContent}
            onChange={handleEditorTokUpdateChange}
            theme="snow"
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />

          {/* <Button onClick={handleGrowthoidUploadPost}>
            {" "}
            Upload On TokUpdate{" "}
          </Button> */}
        </div>
        {/* <div
          style={{ display: "flex", flexDirection: "column", minWidth: "32.5%",          marginTop:'55rem'
        }}
        >
          <ReactQuill
            ref={tretQuill}
            value={tretQuillContent}
            onChange={handleTretChange}
            theme="snow"
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />

          <Button onClick={handleGrowthoidUploadPost}>
            {" "}
            Upload On TokUpdate{" "}
          </Button>
        </div> */}
      </div>

      {/* <ImageEditor imgurl={""} main_keyword={articleTitle} preview={false}  /> */}

      <div>
        <h1>Elementor Template Generator</h1>
        {generatedTemplate && (
          <div>
            <h2>Generated Template:</h2>
            <pre>{JSON.stringify(generatedTemplate, null, 2)}</pre>
            <button onClick={downloadTemplate}>Download Template</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SidesMedia;
