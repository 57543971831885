import React, { useState, ChangeEvent, CSSProperties } from "react";
import { Button, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from "@mui/icons-material/Delete";

interface RowData {
  name: string;
  url: string;
  text_url: string;
}

interface SneakerTableProps {
  rows: RowData[];
  setRows: React.Dispatch<React.SetStateAction<RowData[]>>;
}

const SneakerTable: React.FC<SneakerTableProps> = ({ rows, setRows }) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editField, setEditField] = useState<string | null>(null);
  const [editRow, setEditRow] = useState<RowData>({ name: '', url: '', text_url: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentLinkIndex, setCurrentLinkIndex] = useState<number | null>(null);
  const [affiliateLink, setAffiliateLink] = useState('');

  const handleAddRow = () => {
    setRows([...rows, { name: '', url: '', text_url: '' }]);
  };

  const handleEditRow = (index: number, field: string) => {
    setEditIndex(index);
    setEditField(field);
    setEditRow({ ...rows[index] });
  };

  const handleSaveRow = (index: number) => {
    const updatedRows = [...rows];
    updatedRows[index] = editRow;
    setRows(updatedRows);
    setEditIndex(null);
    setEditField(null);
    setEditRow({ name: '', url: '', text_url: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof RowData) => {
    setEditRow({ ...editRow, [field]: e.target.value });
  };

  const handleDeleteRow = (index: number) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleOpenDialog = (index: number) => {
    setCurrentLinkIndex(index);
    setAffiliateLink(rows[index].url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentLinkIndex(null);
    setAffiliateLink('');
  };

  const handleSaveLink = () => {
    if (currentLinkIndex !== null) {
      const updatedRows = [...rows];
      updatedRows[currentLinkIndex].url = affiliateLink;
      setRows(updatedRows);
    }
    handleCloseDialog();
  };

  const generateTableHTML = (rows: RowData[]): string => {
    const tableStyle = `
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    `;

    const thStyle = `
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #eab308;
      color: black;
      border: 1px solid #ccc;
    `;

    const tdStyle = `
      padding: 10px;
      border: 1px solid #ccc;
    `;

    return `
      <table style="${tableStyle}">
        <thead>
          <tr>
            <th style="${thStyle}">Product</th>
            <th style="${thStyle}"></th>
            <th style="${thStyle}">Deals</th>
          </tr>
        </thead>
        <tbody>
          ${rows
            .map(
              (row) => `
            <tr>
              <td style="${tdStyle}"><a href="${row.url}" target="_blank">${row.name}</a></td>
              <td style="${tdStyle}">Стандардно</td>
              <td style="${tdStyle}"><a href="${row.url}" target="_blank">${row.text_url}</a></td>
            </tr>
          `
            )
            .join('')}
        </tbody>
      </table>
    `;
  };

  const tableStyle: React.CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  };

  const thStyle: React.CSSProperties = {
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#eab308',
    color: 'black',
    border: '1px solid #ccc',
  };

  const tdStyle: React.CSSProperties = {
    padding: '10px',
    border: '1px solid #ccc',
    position: 'relative',
  };

  const inputStyle: React.CSSProperties = {
    width: '100%',
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <div className="prod-cat-body">
        <div className="prod-counrywise-tbl">
          <table className="prod-table" style={tableStyle}>
            <thead>
              <tr>
                <th className="retailer-col" style={thStyle}>Product</th>
                <th className="release-type" style={thStyle}></th>
                <th className="btn-col" style={thStyle}></th>
                <th className="btn-col" style={thStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index} className="where-to-buy-container">
                  <td className="retailer-col" style={tdStyle}>
                    {editIndex === index && editField === 'name' ? (
                      <>
                        <TextField
                          value={editRow.name}
                          onChange={(e: any) => handleChange(e, 'name')}
                          style={inputStyle}
                          size="small"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          onClick={() => handleSaveRow(index)}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <a href={row.url} target="_blank">
                          {row.name}
                        </a>
                        <IconButton
                          size="small"
                          style={{ position: 'absolute', right: '5px', top: '5px' }}
                          onClick={() => handleEditRow(index, 'name')}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </td>
                  <td className="release-type" style={tdStyle}>Additonal text</td>
                  <td className="btn-col" style={tdStyle}>
                    {editIndex === index && editField === 'url' ? (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <TextField
                          value={editRow.text_url}
                          onChange={(e: any) => handleChange(e, 'text_url')}
                          style={inputStyle}
                          size="small"
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          onClick={() => handleSaveRow(index)}
                        >
                          Save
                        </Button>
                      </div>
                    ) : (
                      <>
                        <a href={row.url} target="_blank">
                          {row.text_url}
                        </a>
                        <IconButton
                          size="small"
                          style={{ position: 'absolute', right: '5px', top: '5px' }}
                          onClick={() => handleEditRow(index, 'url')}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </td>
                  <td className="btn-col" style={tdStyle}>
                    <IconButton size="small" onClick={() => handleOpenDialog(index)}>
                      <LinkIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      style={{ position: 'absolute', right: '5px', top: '5px' }}
                      onClick={() => handleDeleteRow(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Button variant="contained" color="primary" onClick={handleAddRow} style={{ marginTop: '10px' }}>
        Add Row
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Paste Affiliate Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Affiliate Link"
            type="url"
            fullWidth
            value={affiliateLink}
            onChange={(e) => setAffiliateLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveLink} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SneakerTable;
