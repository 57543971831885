import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import { setDefaultLanguage, getTranslation } from "./../../translation";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import axios from "axios";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,

  OutlinedInput,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-calendar/dist/Calendar.css';
import "../../styles/Calendar.css";
import YouTube from "react-youtube";
import "../../styles/Reservation.css";
import SettingsIcon from "@mui/icons-material/Settings";
import "../../styles/AdminWelcome.css";
import LogoImg from "./../../img/wecon-mail.png"; // Adjust the path accordingly
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
// import Checkbox from 'material-ui/Checkbox';
import { FormValues } from "../../interfaces";
import ReactQuill from "react-quill";
import {
  CompanyData,
  Guideline,
  SuperAdminBlog,
  UserBlog,
  UserLite,
} from "../../types";
import CompanyView from "./CompanyView";
import CompanyGuidelines from "./CompanyGuidelines";
import CompanySettings from "./CompanySettings";
import CompanyViewAll from "./CompanyViewAll";

interface Company {
  company_name: string;
  company_uuid: string;
}
interface Writer {
  writer_name: string;
  writer_uuid: string;
}

interface User {
  user_uuid: string;
  name: string;
  password: string;
  roles: string[];
  email: string;
}

interface UserInfoShort {
  name: string;
  user_uuid: string;
  roles: string[];
  email: string;
}

interface BlogDataResponse {
  user_info: UserInfoShort;
  blogs: SuperAdminBlog[];
}

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface HomepageProps {
  userDetails: any | null;
  company_data: any;
  all_users_p: any;
  login: any;
}
interface HomepageProps {}

const CompanyLogin: React.FC<HomepageProps> = ({
  userDetails,
  company_data,
  all_users_p,
  login,
}) => {
  const navigate = useNavigate();
  const handleChangeCompany = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`company_name`]: event.target.value,
    }));
  };
  const handleChangeStatus = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`status`]: event.target.value,
    }));
  };
  const handleChange = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`writer`]: event.target.value,
    }));
  };

  const [currentView, setCurrentView] = useState<String>("");

  function getRender(status: string): React.ReactNode {
    let backgroundColor = "lightgreen";
    let color = "white";

    switch (status) {
      case "Pending": {
        backgroundColor = "#A4ABB6";
        break;
      }
      case "In Progress": {
        backgroundColor = "#FCE83A";
        break;
      }
      case "Written": {
        backgroundColor = "green";
        break;
      }
      case "Editor": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Completed": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Paid": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Failed": {
        backgroundColor = "#FF3838";
        break;
      }
    }
    return (
      <>
        <div
          style={{
            padding: "0.2rem 0.5rem",
            // border: "1px solid gray",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "3vh",
            color: color,
            background: backgroundColor,
          }}
        >
          {status}
        </div>
      </>
    );
  }

  const [toast_message, setToastMessage] = useState("empty_fields");

  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  const [services, setServices] = useState([]);
  // const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [blogs, setBlogs] = useState<UserBlog[]>([]);
  const [editBlogs, setEditBlogs] = useState<UserBlog[]>([]);
  const [currentCompanieBlogs, setCurrentCompanieBlogs] = useState<
    SuperAdminBlog[]
  >([]);
  const [currentWriterBlogs, setCurrentWriterBlogs] =
    useState<BlogDataResponse>({
      blogs: [],
      user_info: { email: "", user_uuid: "", name: "", roles: [] },
    });

  const [all_users, setAllUsers] = useState<UserLite[]>([]);
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();

  const fetchUserBlogs = async () => {
    try {
      const response = await fetch("https://servercode.wecon-digital.agency/api/users");
      const data = await response.json();
      // ""
      setAllUsers(data); // Update the services state with fetched data
      try {
        const response = await fetch(
          `https://servercode.wecon-digital.agency/api/current-company/blogs/${userDetails.uuid}?companyView=${true}`
        );
        const data = await response.json();
        setCurrentCompanieBlogs(data.current_project_blogs);
        setCurrentCompanyData(data); // Update the services state with fetched data
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const renderCompanyView = () => {
    ("");
    switch (currentView) {
      case "":
        return (
          <CompanyView
          login={true}
          all_users={all_users}
          company={currentCompanyData}
        />
        );

      case "guidelines":
        return (
          <CompanyGuidelines
            all_users={all_users}
            company={currentCompanyData}
          />
        );
      case "settings":
        return (
          <CompanySettings
            // saveChanges={() => {
            //   fetchCompanies();
            // }}
            all_users={all_users}
            company={currentCompanyData}
          />
        );
      default:
        return null; // Return null if no case matches
    }
  };

  const fetchGuidelines = async () => {
    if (currentBlog.company_name) {
      const requestBody = {
        company_name: currentBlog.company_name,
      };

      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/company/guidelines/name",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);
        // ""
        // setSelectedProject(data[selectedGuideline]);
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      fetchUserBlogs();
    }
    // ... other data fetching calls
  }, []);
  // Use effect hook to fetch services whenever activeView changes
  const [guidelines, setGuidelines] = useState([]);

  const [currentBlog, setCurrentBlog] = useState<UserBlog>({
    blog_uuid: "",
    blog_title: "",
    deadline: "",
    keywords: "",
    guideline: "",
    additional_guidelines: "",
    company_guidelines: [],
    urgent: false,
    status: "Pending",
    url: "",
    writer_uuid: "",
    company_name: "",
    additional_info: "",
    blog_length: 0,
    example_article: [],
  });

  const getCompanyBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-company/blogs/${company_uuid}`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.blogs); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getWriterBlogs = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/writer/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setCurrentWriterBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const renderWriter = () => {
    return (
      <>
        <CompanyView
          login={true}
          all_users={all_users}
          company={company_data}
        />
      </>
    );
  };

  return (
    <>
      <div
        className="container minh-100"
        // , borderTop:'3vh solid #1d73ed',borderLeft:'3vh solid #1d73ed'
        style={{ display: "flex", width: "100%" }}
      >
        <div
          className="admin_header minh-100"
          style={{
            minHeight: "100%",
            width: "15%",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <div
            className="top_view"
            style={{
              minHeight: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid lightgray",
            }}
          >
            <img
              src={LogoImg}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
          </div>
          <div
          className="hover"
          onClick={()=>{
            setCurrentView("")
          }}
              style={{
                userSelect: "none",
                padding: "1rem",
                // background: "blue",
                alignItems: "center",
                background: currentView == "" ? "blue":"",
                color: currentView == "" ? "white":"",
                display: "flex",
                borderBottom:'1px solid lightgray',
                justifyContent: "space-between",
              }}
            >
              Home
            </div>
          <div
                    className="hover"
                    onClick={()=>{
                      setCurrentView("guidelines")
                    }}
              style={{
                userSelect: "none",
                padding: "1rem",
                background: currentView == "guidelines" ? "blue":"",
                color: currentView == "guidelines" ? "white":"",
                alignItems: "center",
                borderBottom:'1px solid lightgray',
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Guidelines
              {/* <ArrowDropDownIcon /> */}
            </div>
        </div>

        <div
          className="admin_header minh-100"
          style={{ background: "#f3f3f3", minHeight: "100%", width: "85%" }}
        >
          <div
            className="top_view"
            style={{
              minHeight: "10vh",
              background: "white",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "2rem",
            }}
          >
            {/* <div style={{ minWidth: "100%" }}> */}
            <h2>{currentCompanyData?.company_name}</h2>
            {/* </div> */}
            <div
              className="logout"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");

                navigate("/login");
              }}
            >
              {" "}
              <LogoutIcon />{" "}
              <span style={{ marginLeft: "0.5rem" }}>Log out </span>
            </div>
          </div>
          {renderCompanyView()}

        </div>
      </div>
    </>
  );
};

export default CompanyLogin;
