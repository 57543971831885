import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { getTranslation } from "../../translation";
import ReactQuill from "react-quill";
import axios from "axios";
import {CompanyInfo} from './../../types.tsx'

interface SuperAdminBlog {
  multiple: boolean;
  urgent: boolean;
  project_name: string;
  blog_uuid: string;
  blog_title: string;
  company_name: string;
  company_uuid: string;
  words: number;
  writer: string;
  writer_uuid: string;
  editor_name: string;
  editor_uuid: string;
  amount: number;
  general_guidelines: boolean;
  company_guidelines: string[];
  additional_guidelines: string;
  keywords: string;
  deadline: string;
  url: string;
  status: string;
  blog_length: number;
  tools: string[];
  additional_info: string;
  example_article: string;
}

interface UserLite {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
}

interface User {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
  password: string;
}

interface CompanyData {
  company_name: string;
  company_uuid: string;
  company_projects: string[];
  current_project_blogs: SuperAdminBlog[];
}

interface Guideline {
  guideline_uuid: string;
  guideline_data: string;
  guideline_name: string;
}
type AlertColor = "success" | "info" | "warning" | "error"

export default function CompanySettings({ company, all_users, saveChanges }: any) {
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
    email:'',
    name:'',
    password:''
  });
  const [toast_message, setToastMessage] = useState("empty_fields");
  const [toast_type, setToastType] = useState<AlertColor>("success");


  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const showToast=(type:string, message:string) => {
    setToastType(type as AlertColor)
    setToastMessage(message)
    setOpen(true);

  }

  const fetchCompanyInfo = async (companyUUID:string) => {
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/company/info/${companyUUID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCompanyInfo(data); // Update state with the fetched company info
    } catch (error) {
      console.error("Error fetching company info:", error);
      // Handle error if necessary
    }
  };

  const handleSaveChanges = async () => {
    try {
      StartLoading();
      let jsonBody = {
        email:companyInfo.email,
        name:companyInfo.name,
        password:companyInfo.password,
        company_uuid:company.company_uuid,
      }
     const response = await fetch(
        "https://servercode.wecon-digital.agency/api/company/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonBody)
        }
      );
      const data = await response.json();
      StopLoading();
      saveChanges()

      showToast("success", "SUCCESSFULLY UPDATED")
      // Addd Toast
      // Handle any success logic here
    } catch (error) {
      StopLoading();
      console.error("Error updating company:", error);
      // Handle errors here
    }
  };


  useEffect(() => {
    // ""
    if (company) {
      setCurrentCompanyData(company);
      fetchCompanyInfo(company.company_uuid);
    }

    // getCompanyBlogs(company_uuid);
  }, [company]);

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyInfo((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  return (
    <>
      <div
        className="main_container"
        style={{
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="settings_input">
          <h2>{currentCompanyData?.company_name} Settings</h2>
          <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label=" Name"
                    placeholder="Name"
                    value={companyInfo.name.length > 0 ? companyInfo.name : ""}
                    onChange={handleValueChange}

                  />

                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="email"
                    className="textfield"
                    required
                    id="outlined-required"
                    label=" Email"
                    placeholder=" Email"
                    value={companyInfo.email.length > 0 ? companyInfo.email : ""}
                    onChange={handleValueChange}

                  />

                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Password"
                    placeholder="Password"
                    value={companyInfo.password.length > 0 ? companyInfo.password:''}
                    onChange={handleValueChange}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                </div>
          <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  {" "}
                  <Button
                    className="guideline_button hover"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    onClick={()=>{

                      handleSaveChanges()
                    }}
                  >
                    {getTranslation("buttons.save_changes")}
                  </Button>
                </div>
        </div>
        `
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast_type} variant="filled" >
          {toast_message}
        </Alert>
      </Snackbar>
    </>
  );
}
