import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  useRef,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ImageEditor, { ImageEditorHandle } from "./ImageEditor.tsx";

import "./../App.css";
import ReactMarkdown from "react-markdown";
import LogoImg from "./../img/wecon-mail.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { marked } from "marked";
import Quill, { RangeStatic } from "quill";
import Delta from "quill-delta";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { formToJSON } from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import { debuglog } from "util";
import { Checkbox } from "@mui/material";
interface Heading {
  id: string;
  tag: string;
  title: string;
  subheadings?: Heading[];
  content: string;
  summary: string;
  characters: string;
  keywords: string;
}

interface TableOfContent {
  headings: Heading[];
}

interface LoginParams {
  links: string;
  keyword: string;
  all_keywords: string;
  faq: string;
  wordcount: string;
  heading_keywords: string;
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

function TweSocial(): JSX.Element {
  const [formData, setFormData] = useState<LoginParams>({
    links: "",
    keyword: "",
    all_keywords: "",
    faq: "",
    heading_keywords: "",
    wordcount: "",
  });
  const [lastWrappedHtml, setLastWrappedHtml] = useState<string | undefined>(
    ``
  );
  const [regeneratedText, setRegeneratedText] = useState<string | undefined>(
    ``
  );

  const [selectedRange, setSelectedRange] = useState<RangeStatic | null>(null);

  const [tableOfContent, setTableOfContent] = useState<TableOfContent | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [includeFAQ, setIncludeFAQ] = useState<boolean>(true);

  const [loadingHeading, setLoadingHeading] = useState<boolean>(false);
  const [introductionWritten, setIntroductionWritten] =
    useState<boolean>(false);
  const [conclusionWritten, setConclusionWritten] = useState<boolean>(false);
  const [faqWritten, setFaqWritten] = useState<boolean>(false);

  const [loadingAllHeading, setLoadingAllHeading] = useState<boolean>(false);

  const [loadingIntroduction, setLoadingIntroduction] =
    useState<boolean>(false);
  const [loadingConclusion, setLoadingConclusion] = useState<boolean>(false);
  const [loadingArticle, setLoadingArticle] = useState<boolean>(false);
  const [loadingFAQ, setLoadingFAQ] = useState<boolean>(false);

  const [articleGenerated, setArticleGenerated] = useState<string>(``);
  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [suggestedCategory, setSuggestedCategory] = useState<string | null>(
    null
  );
  const [suggestedTags, setSuggestedTags] = useState<string | null>(null);
  const [post_heading, setPostHeading] = useState<string | null>(null);

  const [showReplaceField, setShowReplaceField] = useState<boolean>(false);
  const [showSmallTool, setShowSmallTool] = useState<boolean>(false);

  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);
  const [isGeneratingByHeader, setIsGeneratingByHeader] = useState(false);

  const [write_prompt, setWritePrompt] = useState<boolean>(false);
  const [smallTooltipPosition, setSmallTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [addedSpans, setAddedSpans] = useState<any[]>([]);
  const [showSmallTooltip, setShowSmallTooltip] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [extraText, setExtraText] = useState("");
  const quillRef = useRef<ReactQuill | null>(null);
  const quillGeneratedText = useRef<ReactQuill | null>(null);
  const [loadingUploadingPage, setLoadingUploadingPage] = useState(false);

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const handleGenerateIntroduction = async () => {
    try {
      setLoadingIntroduction(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-introduction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            main_keyword: formData.keyword,
            content: articleGenerated,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the introduction");
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingIntroduction(false);
      // setArticleGenerated(   + "</br> </br>" + articleGenerated );
      setArticleGenerated((prev) => responseData + prev);
      setIntroductionWritten(true);
      return true;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const handleGenerateFAQ = async () => {
    try {
      // // debugger
      setLoadingFAQ(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-faq",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            faq_questions: formData.faq,
            all_keywords: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the FAQ");
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      let newReps = responseData.replaceAll("\n", ""); // Removing all \n characters
      setLoadingFAQ(false);
      setArticleGenerated((prev) => prev + newReps);
      setFaqWritten(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateTableOfContent = () => {
    if (tableOfContent) {
      const updatedHeadings = tableOfContent.headings.map((h, index) => {
        if (index === currentHeadingIndex) {
          return {
            ...h,
            content: h.content,
            summary: h.summary,
            keywords: h.keywords,
          };
        }
        return h;
      });

      setTableOfContent({ ...tableOfContent, headings: updatedHeadings });
    }
  };

  useEffect(() => {
    updateTableOfContent();
  }, [currentHeadingIndex]);

  const handleGenerateConclusion = async () => {
    try {
      setLoadingConclusion(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-conclusion",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            main_keyword: formData.keyword,
            content: articleGenerated,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch the conclusion");
      }
      let responseData = await response.text(); // Getting the response as text (HTML string)
      setLoadingConclusion(false);
      // setArticleGenerated( articleGenerated + "</br> </br>" + responseData );
      setArticleGenerated(
        (prev) => prev + "<h2>Conclusion</h2>" + responseData
      );
      setConclusionWritten(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function createKeyWordsJson(input: any) {
    // Split the input string into an array of items
    const all_keywords = input.split(",");
    const processedItems: { keyword: any; limit: number }[] = [];

    // Map each item to an object with the specified structure
    all_keywords.forEach((item: any) => {
      if (item && item.length > 0) {
        // Extract the keyword by removing the numeric part and trim it to remove any leading/trailing whitespace
        const keyword = item.split("0/")[0].trim();

        // Extract the limit, which is the first number after "0/"
        const match = item.match(/0\/(\d+)/);
        if (!match) {
          throw new Error("Invalid format: " + item); // Throw an error if the format is invalid
        }
        const limit = match[1];

        processedItems.push({
          keyword: keyword,
          limit: parseInt(limit, 10), // Convert the string to an integer
        });
      }
    });

    return processedItems;
  }

  const validateKeywordsString = (all_keywords: any) => {
    try {
      let substring = ``;
      let current = 0;
      // Split the input string into an array of items
      for (let i = 0; i < all_keywords.length; i++) {
        if (String(all_keywords[i]) === "/") {
          current = i;
        }
        if (String(all_keywords[i]) === " " && current != 0) {
        } else {
          substring = substring + all_keywords[i];
        }
        if (String(all_keywords[i]) === " " && current != 0) {
          substring = substring + " , ";
          current = 0;
        }
      }

      let keyword_list = createKeyWordsJson(String(substring));
      console.log(keyword_list);

      return true;
    } catch (error) {
      // console.error("Error in validating keywords string:", error.message);
      return false;
    }
  };

  const handleGenerateHeaderByHeader = async (): Promise<boolean> => {
    // debugger;
    const currentIndex = currentHeadingIndex; // Get the current index value at the start

    if (!tableOfContent || currentIndex >= tableOfContent.headings.length) {
      setIsGeneratingByHeader(false);
      return false; // Stop if no more headings or if the table of contents is not defined
    }

    let summary = "";
    const heading = tableOfContent.headings[currentIndex];
    summary = tableOfContent.headings
      .slice(0, currentIndex)
      .map((h) => h.content)
      .join(" ");

    let all_keywords =
      currentIndex == 0
        ? formData.all_keywords
        : tableOfContent.headings[currentIndex - 1].keywords;

    let int = Number(heading.characters);

    let char = String(Number(int * 200));
    try {
      setLoadingHeading(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-heading-content",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            heading: heading,
            characters: char,
            competitors: formData.links,
            all_keywords: all_keywords,
            main_keyword: formData.keyword,
            all_summary: summary, // Send the constructed summary
          }),
        }
      );

      if (!response.ok) {
        setLoadingHeading(false);
        throw new Error("Failed to generate content for heading");
      }

      const data = await response.json();
      console.log(data.content); // Access the generated content
      console.log(data.summary); // Access the summary
      console.log(data.keywords);

      setArticleGenerated((prev) => prev + data.content);
      const updatedHeadings = tableOfContent.headings.map((h, index) => {
        if (index === currentIndex) {
          return {
            ...h,
            content: data.content,
            summary: data.summary,
            keywords: data.keywords,
          };
        }
        return h;
      });

      console.log("updatedHeadingggg", updatedHeadings);

      // Update the table of content state with the updated headings
      setTableOfContent({ ...tableOfContent, headings: updatedHeadings });

      console.log("....................", tableOfContent);

      setCurrentHeadingIndex((prev) => prev + 1); // Use functional update to ensure proper increment
      setLoadingHeading(false);
      setAbortController(null); // Clear the controller after the request completes
      return true; // Indicate that the generation was successful
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error generating content for heading:", error);
        setIsGeneratingByHeader(false);
        toast.error(String(error), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoadingHeading(false);
      setAbortController(null); // Clear the controller if there is an error
      return false; // Indicate that the generation failed
    }
  };

  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const range = quill.getSelection();
        if (range && range.length > 0) {
          let delta = quill.getContents(range.index, range.length);

          // Convert Delta to HTML
          const tempContainer = document.createElement("div");
          new Quill(tempContainer).setContents(delta);
          let selectedHtml =
            tempContainer.getElementsByClassName("ql-editor")[0].innerHTML;
          console.log("Selected HTML:", selectedHtml);
          if (selectedHtml.startsWith("<p>") && selectedHtml.endsWith("</p>")) {
            selectedHtml = selectedHtml.substring(3, selectedHtml.length - 4);
          }

          // setSelectedText(selectedHtml);
          // const wrappedHtml = `<span style="background-color: #d1ffbd;">${selectedHtml}</span>`;
          // const newDelta = quill.clipboard.convert(wrappedHtml);
          // const Delta = Quill.import('delta');
          // console.log("wrappedHtml", wrappedHtml);
          // setLastWrappedHtml(wrappedHtml);
          // console.log("setLastWrappedHtml", lastWrappedHtml);
          // console.log(range);
          // setSelectedRange(range);
          // console.log(selectedRange)
          // setAddedSpans([...addedSpans, { index: range.index, length: newDelta.length() }]);

          // // Update the content in Quill editor
          // quill.updateContents(new Delta().retain(range.index).delete(range.length).concat(newDelta));

          // // Clear the selection
          // quill.setSelection(range.index + newDelta.length(), 0);
          const scrollX =
            window.pageXOffset !== undefined
              ? window.pageXOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollLeft;
          const scrollY =
            window.pageYOffset !== undefined
              ? window.pageYOffset
              : (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollTop;

          console.log(addedSpans);
          const quillBounds = quill.getBounds(range.index, range.length);
          const editorElement = quill.root;
          const editorRect = editorElement.getBoundingClientRect();
          const tooltipX = editorRect.left + quillBounds.left + scrollX;
          const tooltipY = editorRect.top + quillBounds.bottom + scrollY;

          // setTooltipPosition({ x: tooltipX, y: tooltipY });
          setSmallTooltipPosition({ x: tooltipX, y: tooltipY });
          setShowSmallTool(true);
          setShowReplaceField(true);
        } else {
          if (write_prompt) {
            let oldhtml = lastWrappedHtml?.replace(
              `<span style="background-color: #d1ffbd;">`,
              ""
            );
            oldhtml = oldhtml?.replace(`</span>`, "");
            console.log(
              "oldhtmloldhtml>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
              oldhtml
            );
            const newDelta = quill.clipboard.convert(oldhtml);
            const Delta = Quill.import("delta");

            const lastSpan = addedSpans.pop();
            console.log("lastSpan", lastSpan);
            if (lastSpan) {
              quill.updateContents(
                new Delta()
                  .retain(lastSpan.index)
                  .delete(lastSpan.length)
                  .concat(newDelta)
              );
              setAddedSpans([...addedSpans]);
            }
            setSelectedRange(null);
            console.log(selectedRange);
            setShowReplaceField(false);
            setTooltipPosition(null);
          }

          setShowSmallTool(false);
        }
      }
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [addedSpans]);

  function handleAcceptRegeneratedText() {
    // // debugger
    const newquill = quillGeneratedText.current?.getEditor();
    if (newquill) {
      const length = newquill.getLength(); // Get the length of the content
      newquill.setSelection(0, length); // Select all the content
      let fullContent = newquill.root.innerHTML; // Get the full HTML content
      if (fullContent.startsWith("<p>") && fullContent.endsWith("</p>")) {
        fullContent = fullContent.substring(3, fullContent.length - 4);
      }

      console.log("Full HTML Content:", fullContent);
      console.log(selectedRange);
      const quill = quillRef.current?.getEditor();
      if (quill && selectedRange) {
        const delta = quill.clipboard.convert(fullContent);
        const Delta = Quill.import("delta");
        const updatedDelta = new Delta()
          .retain(selectedRange.index)
          .delete(selectedRange.length)
          .concat(delta);
        quill.updateContents(updatedDelta);

        // Clear the selection
        quill.setSelection(selectedRange.index + delta.length(), 0);
      }

      setSelectedRange(null);
      setLastWrappedHtml(undefined);
      // setExtraText('')
      setRegeneratedText(``);
      setWritePrompt(false);
      // setRegeneratedText(undefined);
      console.log(selectedRange);
      setShowReplaceField(false);
      setTooltipPosition(null);
    }
  }

  function cancelRegen() {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      console.log("cancelRegen", lastWrappedHtml);
      let oldhtml = lastWrappedHtml?.replace(
        `<span style="background-color: #d1ffbd;">`,
        ""
      );
      oldhtml = oldhtml?.replace(`</span>`, "");
      console.log("oldhtmloldhtml>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", oldhtml);
      const newDelta = quill.clipboard.convert(oldhtml);
      const Delta = Quill.import("delta");

      const lastSpan = addedSpans.pop();
      console.log("lastSpan", lastSpan);
      if (lastSpan) {
        quill.updateContents(
          new Delta()
            .retain(lastSpan.index)
            .delete(lastSpan.length)
            .concat(newDelta)
        );
        setAddedSpans([...addedSpans]);
      }
      setSelectedRange(null);
      setLastWrappedHtml(undefined);
      // setExtraText('')
      setWritePrompt(false);
      setRegeneratedText(``);
      console.log(selectedRange);
      setShowReplaceField(false);
      setTooltipPosition(null);
    }
  }

  const handleExtraTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExtraText(event.target.value);
  };

  async function handleRegenerateSelection(params: any) {
    const quill = quillRef.current?.getEditor();
    if (quill) {
      let range = quill.getSelection();
      if (range == null) {
        range = selectedRange;
      }
      if (range && range.length > 0) {
        const selectedText = quill.getText(range.index, range.length);
        console.log("Selected Text:", selectedText);
        console.log("lastWrappedHtml Text:", lastWrappedHtml);
        try {
          const response = await fetch(
            "https://servercode.wecon-digital.agency/regenerate-prompt",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                prompt: extraText,
                selection: lastWrappedHtml,
              }),
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message);
          }

          let responseData = await response.text(); // Getting the response as text (HTML string)
          setRegeneratedText(responseData);
          // const delta = quill.clipboard.convert(responseData);
          // const Delta = Quill.import('delta')
          // const updatedDelta = new Delta().retain(range.index).delete(range.length).concat(delta);
          // quill.updateContents(updatedDelta);

          // // Clear the selection
          // quill.setSelection(range.index + delta.length(), 0);
          setWritePrompt(true);
          // setShowReplaceField(false);
        } catch {
          console.log("ERROR Regenerating prompt");
        }
      }
    }
  }

  const imageEditorRef = useRef<ImageEditorHandle>(null);

  async function handleCreateImageOnServer() {
    if (imageEditorRef.current) {
      imageEditorRef.current.createImageOnServer();
    }
  }

  const handleEditorChange = (html: string) => {
    console.log(html);
    setArticleGenerated(html);
  };

  const generateUniqueId = () => Math.random().toString(36).substr(2, 9);

  const addUniqueIds = (headings: any[]): Heading[] => {
    return headings.map((heading) => ({
      ...heading,
      id: generateUniqueId(),
      subheadings: heading.subheadings ? addUniqueIds(heading.subheadings) : [],
      characters: heading.characters / 200,
      content: "",
      summary: "",
      keywords: "",
    }));
  };

  const updateTitleById = (
    headings: Heading[],
    id: string,
    newTitle: string
  ): Heading[] => {
    return headings.map((heading) => {
      if (heading.id === id) {
        return { ...heading, title: newTitle };
      } else if (heading.subheadings) {
        return {
          ...heading,
          subheadings: updateTitleById(heading.subheadings, id, newTitle),
        };
      } else {
        return heading;
      }
    });
  };

  const updateWordCountById = (
    headings: Heading[],
    id: string,
    newWordCount: string
  ): Heading[] => {
    return headings.map((heading) => {
      if (heading.id === id) {
        return { ...heading, characters: newWordCount };
      } else if (heading.subheadings) {
        return {
          ...heading,
          subheadings: updateWordCountById(
            heading.subheadings,
            id,
            newWordCount
          ),
        };
      } else {
        return heading;
      }
    });
  };

  const addNestedHeading = (
    headings: Heading[],
    path: number[],
    heading_tag: string
  ): Heading[] => {
    if (path.length === 0) {
      return [
        ...headings,
        {
          id: generateUniqueId(),
          tag: heading_tag,
          title: "New Subheading",
          subheadings: [],
          content: "",
          summary: "",
          keywords: "",
          characters: "",
        },
      ];
    }

    return headings.map((heading, i) =>
      i === path[0]
        ? {
            ...heading,
            subheadings: addNestedHeading(
              heading.subheadings || [],
              path.slice(1),
              heading_tag
            ),
          }
        : heading
    );
  };

  const deleteNestedHeading = (
    headings: Heading[],
    path: number[]
  ): Heading[] => {
    if (path.length === 1) {
      return headings.filter((_, i) => i !== path[0]);
    }

    return headings.map((heading, i) =>
      i === path[0]
        ? {
            ...heading,
            subheadings: deleteNestedHeading(
              heading.subheadings || [],
              path.slice(1)
            ),
          }
        : heading
    );
  };

  async function handleCreateTOC(
    event: FormEvent<HTMLFormElement>
  ): Promise<void> {
    // setTableOfContent({
    //   headings: [
    //     {
    //       id: "heading1",
    //       tag: "h1",
    //       title: "Introduction to Photoshop",
    //       subheadings: [
    //         {
    //           id: "subheading1-1",
    //           tag: "h2",
    //           title: "What is Photoshop?",
    //           content:
    //             "Photoshop is a popular image editing software developed by Adobe.",
    //           summary: "A brief overview of Photoshop and its uses.",
    //           characters: "Photoshop, image editing, Adobe",
    //           keywords: "Photoshop, image editing, Adobe",
    //         },
    //         {
    //           id: "subheading1-2",
    //           tag: "h2",
    //           title: "Why Use Photoshop?",
    //           content:
    //             "Photoshop is widely used for creating graphics, editing photos, and digital artwork.",
    //           summary: "The main benefits of using Photoshop.",
    //           characters: "graphics, photo editing, digital art",
    //           keywords: "graphics, photo editing, digital art",
    //         },
    //       ],
    //       content: "This section introduces Photoshop and its main features.",
    //       summary: "An introduction to the world of Photoshop.",
    //       characters: "Photoshop, features, image editing",
    //       keywords: "Photoshop, image editing, features",
    //     },
    //     {
    //       id: "heading2",
    //       tag: "h1",
    //       title: "Basic Tools in Photoshop",
    //       subheadings: [
    //         {
    //           id: "subheading2-1",
    //           tag: "h2",
    //           title: "Move Tool",
    //           content:
    //             "The Move Tool allows you to move layers and selections within your project.",
    //           summary: "Overview of the Move Tool and how it works.",
    //           characters: "Move Tool, layers, selection",
    //           keywords: "Move Tool, Photoshop, layers",
    //         },
    //         {
    //           id: "subheading2-2",
    //           tag: "h2",
    //           title: "Brush Tool",
    //           content:
    //             "The Brush Tool is used for painting and drawing on your canvas.",
    //           summary: "Overview of the Brush Tool and its various settings.",
    //           characters: "Brush Tool, painting, drawing",
    //           keywords: "Brush Tool, Photoshop, painting",
    //         },
    //       ],
    //       content:
    //         "This section covers the essential tools that are used frequently in Photoshop.",
    //       summary: "An overview of the basic tools in Photoshop.",
    //       characters: "tools, basics, Photoshop",
    //       keywords: "Photoshop tools, basics",
    //     },
    //   ],
    // });
    // setSuggestedTags("responseData.tags");
    // setSuggestedCategory("responseData.category");
    event.preventDefault(); // Prevents the default form submission behavior
    if (
      formData.links.trim().length < 10 ||
      formData.keyword.trim().length < 10 ||
      !formData.all_keywords.trim() ||
      formData.faq.trim().length < 10
    ) {
      toast.error("Some fields are invalid!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop execution if any field is empty
    }
    if (!validateKeywordsString(formData.all_keywords)) {
      toast.error("Keywords are bad copied!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop if the validation fails
    }
    setLoading(true);

    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-toc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            links: formData.links,
            wordcount: formData.wordcount,
            heading_keywords: formData.heading_keywords.replaceAll("0", ","),
            keyword: formData.keyword,
            all_keyword: formData.all_keywords,
            faq: formData.faq,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const responseData = await response.json();
      const tocWithIds = addUniqueIds(responseData.table_of_content.headings);
      setTableOfContent({ headings: tocWithIds });
      setSuggestedTags(responseData.tags);
      setSuggestedCategory(responseData.category);

      setLoading(false);
      setCurrentHeadingIndex(0);
    } catch (error) {
      console.error("Error generating TOC:", error);
      toast.error(String(error), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const createStyledSection = (heading2: string, content: string): string => {
    console.log(`
      <div style="margin: 20px 0; padding: 20px; border: 1px solid #e0e0e0; border-radius: 10px; background-color: #f9f9f9;">
        <section style="margin-bottom: 20px;">
          <h2 style="font-family: 'Arial', sans-serif; color: #333; font-size: 24px; font-weight: bold; border-bottom: 2px solid #4caf50; padding-bottom: 10px;">
            ${heading2}
          </h2>
          <div style="font-family: 'Arial', sans-serif; color: #555; font-size: 16px; line-height: 1.6; margin-top: 20px;">
            ${content}
          </div>
        </section>
      </div>
    `);
    return "";
  };

  const generateFullArticle = async () => {
    setIsGeneratingByHeader(true);
    let continueGenerating: boolean = true;
    let localIndex = currentHeadingIndex; // Use a local index to track the current heading index
    let currentKeywords = formData.all_keywords;
    let currentSummary = "";

    if (tableOfContent) {
      const controller = new AbortController(); // Create a new AbortController
      setAbortController(controller); // Track the controller

      while (
        continueGenerating &&
        localIndex < tableOfContent.headings.length
      ) {
        try {
          setLoadingAllHeading(true);
          const heading = tableOfContent.headings[localIndex];

          const response = await fetch(
            "https://servercode.wecon-digital.agency/twesocial-generate-heading-content",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                heading: heading,
                characters: heading.characters,
                competitors: formData.links,
                all_keywords: currentKeywords,
                main_keyword: formData.keyword,
                all_summary: currentSummary, // Send the constructed summary
              }),
              signal: controller.signal, // Attach the abort signal to the fetch request
            }
          );

          if (!response.ok) {
            setLoadingAllHeading(false);
            throw new Error("Failed to generate content for heading");
          }

          const data = await response.json();
          console.log(data.content); // Access the generated content
          console.log(data.summary); // Access the summary
          console.log(data.keywords);

          setArticleGenerated((prev) => prev + data.content);

          const updatedHeadings = tableOfContent.headings.map((h, idx) => {
            if (idx === localIndex) {
              return {
                ...h,
                content: data.content,
                summary: data.summary,
                keywords: data.keywords,
              };
            }
            return h;
          });

          setTableOfContent((prev) => ({
            ...prev,
            headings: updatedHeadings,
          }));

          currentKeywords = data.keywords;
          currentSummary = data.summary;

          setCurrentHeadingIndex(localIndex + 1); // Ensure currentHeadingIndex is updated
          localIndex += 1; // Increment the local index after each generation
          setLoadingAllHeading(false);
        } catch (error) {
          console.error("Error generating content for heading:", error);
          setLoadingAllHeading(false);
          toast.error(String(error), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          continueGenerating = false; // Stop further processing on error
        }
      }
      let introduction = await handleGenerateIntroduction();
      let concl1 = await handleGenerateFAQ();
      let concl = await handleGenerateConclusion();

      setIsGeneratingByHeader(false); // Done generating all headings
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    if (tableOfContent) {
      const items = Array.from(tableOfContent.headings);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setTableOfContent({ headings: items });
    }
  };

  const addHeading = () => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: [
          {
            id: generateUniqueId(),
            tag: "h2",
            title: "New Heading",
            subheadings: [],
            content: "",
            summary: "",
            keywords: "",
            characters: "",
          },
          ...prevToc!.headings,
        ],
      }));
    }
  };

  const deleteHeading = (index: number) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: prevToc!.headings.filter((_, i) => i !== index),
      }));
    }
  };

  const addSubheading = (heading: string, path: number[]) => {
    // // debugger
    if (tableOfContent) {
      if (heading === "h2") {
        heading = "h3";
      } else if (heading === "h3") {
        heading = "h4";
      } else if (heading == "h1") {
        heading = "h3";
      }
      setTableOfContent((prevToc) => ({
        headings: addNestedHeading(prevToc!.headings, path, heading),
      }));
    }
  };

  const deleteSubheading = (path: number[]) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: deleteNestedHeading(prevToc!.headings, path),
      }));
    }
  };

  const updateTitle = (id: string, newTitle: string) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: updateTitleById(prevToc!.headings, id, newTitle),
      }));
    }
  };

  const updateWordCount = (id: string, newTitle: string) => {
    if (tableOfContent) {
      setTableOfContent((prevToc) => ({
        headings: updateWordCountById(prevToc!.headings, id, newTitle),
      }));
    }
  };

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const handleStopGeneration = () => {
    // debugger
    if (abortController) {
      abortController.abort(); // Abort the ongoing request
      setAbortController(null); // Clear the controller
      setLoading(false);
      setIsGeneratingByHeader(false); // Update state to stop further processing
      setIntroductionWritten(false);
      setConclusionWritten(false);
      setFaqWritten(false);
      setLoadingIntroduction(false);
      setLoadingConclusion(false);
      setLoadingFAQ(false);
      toast.error("Generation stopped", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderSubheadings = (
    subheadings: Heading[] | undefined,
    path: number[] = []
  ) => {
    if (!subheadings) return null;
    return (
      <Box pl={2} p={1}>
        {subheadings.map((subheading, subIndex) => (
          <Box key={subheading.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                {subheading.tag.toUpperCase() + " "}
              </div>
              <TextField
                style={{ minWidth: "35rem" }}
                value={subheading.title}
                onChange={(e) => updateTitle(subheading.id, e.target.value)}
                fullWidth
              />
              {subheading.tag === "h4" ? (
                ``
              ) : (
                <Button
                  style={{
                    minWidth: "7rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    addSubheading(subheading.tag, [...path, subIndex])
                  }
                >
                  Add H4
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => deleteSubheading([...path, subIndex])}
              >
                Delete
              </Button>
            </Box>

            {renderSubheadings(subheading.subheadings, [...path, subIndex])}
          </Box>
        ))}
      </Box>
    );
  };
  interface Section {
    type: "text" | "heading" | "list";
    content: string;
    headingType?: "h2" | "h3" | "h4";
  }

  function isInternalLink(url: string): boolean {
    const internalDomain = "https://lightroom-photoshop-tutorials.com/";
    return url.startsWith(internalDomain);
  }

  function processLinks(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      const href = link.getAttribute("href");
      if (href) {
        if (isInternalLink(href)) {
          link.removeAttribute("target");
        } else {
          link.setAttribute("target", "_blank");
        }
      }
    });

    return doc.body.innerHTML;
  }

  function parseContent(html: string): Section[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.childNodes;
    const sections: Section[] = [];
    let currentText = "";

    elements.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (["H2", "H3", "H4"].includes(element.tagName)) {
          if (currentText) {
            sections.push({
              type: "text",
              content: processLinks(currentText.trim()),
            });
            currentText = "";
          }
          sections.push({
            type: "heading",
            content: element.innerText.trim(),
            headingType: element.tagName.toLowerCase() as "h2" | "h3" | "h4",
          });
        } else if (["UL", "OL"].includes(element.tagName)) {
          if (currentText) {
            sections.push({
              type: "text",
              content: processLinks(currentText.trim()),
            });
            currentText = "";
          }
          sections.push({
            type: "list",
            content: processLinks(element.innerHTML.trim()),
          });
        } else if (
          element.tagName === "P" &&
          element.innerHTML.trim() === "<br>"
        ) {
          // Ignore empty paragraphs
          return;
        } else {
          currentText += element.outerHTML + " ";
        }
      }
    });

    if (currentText) {
      sections.push({
        type: "text",
        content: processLinks(currentText.trim()),
      });
    }

    return sections;
  }

  function splitStringByCharLimit(
    html: string,
    charLimit: number = 1200
  ): { part1: string; part2: string } {
    // Function to remove special characters
    function removeSpecialChars(text: string): string {
      return text.replace(/[\s\n\r]/g, ""); // Remove spaces, newlines, and carriage returns
    }

    let charCount = 0;
    let splitIndex = 0;

    // Iterate through the HTML string
    for (let i = 0; i < html.length; i++) {
      const currentChar = html[i];

      // Ignore special characters for counting
      if (removeSpecialChars(currentChar).length > 0) {
        charCount++;
      }

      // If charCount exceeds the limit, start looking for the next opening HTML tag
      if (charCount >= charLimit) {
        splitIndex = i; // Set split point
        // Keep moving forward until we find the next opening tag '<'
        while (html[splitIndex] !== "<" && splitIndex < html.length) {
          splitIndex++;
        }
        break; // Exit once we find the opening tag
      }
    }

    // Return two parts: part1 (up to splitIndex), part2 (from splitIndex onwards)
    return {
      part1: html.substring(0, splitIndex),
      part2: html.substring(splitIndex),
    };
  }

  // Unified container creation function
  function createUnifiedContainer(
    heading: string,
    part1: string,
    part2: string,
    headingCounter: number
  ): string {
    const containerType =
      headingCounter <= 5
        ? headingCounter % 2 === 0
          ? "right"
          : "left"
        : "centered";

    return `
        <div class="${containerType}-container">
          <h2>${heading}</h2>
          <div class="content">
            <p>${part1}</p>
            ${part2 ? `<p>${part2}</p>` : ""}
          </div>
        </div>
      `;
  }

  function createBanner() {
    let bb = `
        <div class="banner-block">
            <div class="banner-content">
                <div>
                    <p class="banner-content-heading">Why Choose TweSocial ?</p>
                    <p class="banner-content-description">TweSocial has been featured on various other sites for our high-quality services. We deliver real Twitter followers who are interested in your content</p>
                </div>
                <div class="banner-buttons">
                    <a href="https://dev.twesocial.com/" class="button-link-primary" target="_blank">Check Services →</a>
                </div>
            </div>
    
            <div class="banner-image">
                <img src="https://twesocial.com/wp-content/uploads/2024/10/why-choose-twesocial-1-300x300.webp" alt="ADS illustration" loading="lazy"/>
            </div>
        </div>`;

    console.log(bb);
    return bb;
  }

  function cleanHTML(html: string): string {
    // Remove all instances of specific unwanted patterns
    const patternsToRemove = [
      /style="color:\s*#000000;"/g, // Remove style attributes with color #000000
      /style="color:\s*rgb\(0,\s*0,\s*0\);"/g, // Remove style attributes with color rgb(0, 0, 0)
      /<\/?span[^>]*>/g, // Remove all span tags
    ];

    patternsToRemove.forEach((pattern) => {
      html = html.replace(pattern, "");
    });

    return html.trim();
  }

  const handleSave = async () => {
    // generateHTML();
    // getImageEditorPayload()
    try {
      let fullContent = ``;
      let headingCounter = 0; // Counter to track odd/even
      let new_section = { heading: "", text: "" };
      let bannerAdded = false; // Flag to ensure the banner is added only after the second <h2>

      for (let j = 0; j < 1; j++) {
        let sections = parseContent(articleGenerated);
        let bannerAdded = false; // Flag to track if banner is added

        for (let i = 0; i < sections.length; i++) {
          let current_section = sections[i];

          // Handle sections and save them in one container format
          if (current_section.headingType === "h2") {
            // Save the last section if it exists
            if (new_section.heading) {
              let res = splitStringByCharLimit(new_section.text, 1000);
              if (res.part1 === "") {
                res.part1 = res.part2;
                res.part2 = "";
              }

              // Create the unified container
              let container = createUnifiedContainer(
                new_section.heading,
                res.part1,
                res.part2,
                headingCounter
              );
              fullContent += container;
              if (headingCounter % 2 != 0) {
                let banner = createBanner();
                fullContent += banner;
              }

              // Add the banner immediately after the second heading
              if (headingCounter === 2 && !bannerAdded) {
                // fullContent += createBanner();
                bannerAdded = true; // Mark that the banner has been added
              }

              new_section = { heading: "", text: "" }; // Reset
            }

            // Start a new section
            new_section.heading = cleanHTML(current_section.content);
            headingCounter++; // Increment heading count **after** adding banner
          } else {
            // Collect all content between headings
            let content = cleanHTML(current_section.content);

            // Add <h3> or <h4> tags for smaller headings
            if (
              current_section.headingType === "h3" ||
              current_section.headingType === "h4"
            ) {
              content = `<${current_section.headingType}><strong>${content}</strong></${current_section.headingType}>`;
            }

            // Replace <li> with custom bullet points
            content = content.replace(/<li>(.*?)<\/li>/g, "<p>•$1</p>");

            new_section.text += content;
          }
        }

        // Push the last section, if any
        if (new_section.heading) {
          let res = splitStringByCharLimit(new_section.text, 1000);
          if (res.part1 === "") {
            res.part1 = res.part2;
            res.part2 = "";
          }

          // Create the last container
          let container = createUnifiedContainer(
            new_section.heading,
            res.part1,
            res.part2,
            headingCounter
          );
          fullContent += container;
        }

        if (headingCounter % 2 != 0) {
          let banner = createBanner();
          fullContent += banner;
        }

        // Add a banner at the end, just in case it wasn't added before
        if (!bannerAdded) {
        }
      }

      let payload;
      if (imageEditorRef.current != null) {
        payload = imageEditorRef.current.generatePayload(); // This would return the payload from ImageEditor
      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("allContent", articleGenerated); // Add WordPress content

      console.log(fullContent);
      debugger;
      try {
        setLoadingUploadingPage(true); // Set loading state to true at the start
        const response = await fetch(
          "https://featured-image.wecon-digital.agency/create-twesocial-post",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              allContent: fullContent, // Send allContent
              data: JSON.stringify(payload), // Send payload as a string
              normalPost: true,
              post_heading: post_heading,
            }),
          }
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          console.log("Success:", jsonResponse);
          setLoadingUploadingPage(false); // Set loading to false after successful upload
        } else {
          throw new Error("Upload failed");
        }
      } catch (error) {
        window.alert("Error uploading image: " + error);
        setLoadingUploadingPage(false); // Set loading to false in case of error
      } finally {
        setLoadingUploadingPage(false); // Set loading page to false once everything is done
      }

      console.log("Articles saved successfully");
      // SetLoading(false)
    } catch (error) {
      // SetLoading(false)
      setLoadingUploadingPage(!loadingUploadingPage);

      console.error("Error saving articles:", error);
    }
  };

  const handleGenerateArticle = async () => {
    if (!tableOfContent) return;
    setArticleGenerated("");
    setLoadingArticle(true);
    setProgress(0); // Reset progress

    try {
      const response = await fetch(
        "https://servercode.wecon-digital.agency/generate-article",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            headings: tableOfContent,
            keyword: formData.keyword,
            competitors: formData.links,
            all_keywords: formData.all_keywords,
            faq: formData.faq,
            main_keyword: formData.keyword,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      let responseData = await response.text(); // Getting the response as text (HTML string)
      setArticleGenerated(responseData);
      setLoadingArticle(false);
    } catch (error) {
      console.error("Error generating article:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0rem 3rem" }}>
      {smallTooltipPosition && showSmallTool && (
        <Box
          ref={tooltipRef}
          position="absolute"
          left={smallTooltipPosition.x}
          top={smallTooltipPosition.y} // Adjust position below the selection
          bgcolor="black"
          color="white"
          p={0.5}
          zIndex={10}
          display="block"
          alignItems="start"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <IconButton
            size="small"
            onClick={() => {
              const quill = quillRef.current?.getEditor();
              if (quill) {
                const range = quill.getSelection();
                if (range && range.length > 0) {
                  let delta = quill.getContents(range.index, range.length);

                  // Convert Delta to HTML
                  const tempContainer = document.createElement("div");
                  new Quill(tempContainer).setContents(delta);
                  let selectedHtml =
                    tempContainer.getElementsByClassName("ql-editor")[0]
                      .innerHTML;
                  console.log("Selected HTML:", selectedHtml);
                  if (
                    selectedHtml.startsWith("<p>") &&
                    selectedHtml.endsWith("</p>")
                  ) {
                    selectedHtml = selectedHtml.substring(
                      3,
                      selectedHtml.length - 4
                    );
                  }

                  setSelectedText(selectedHtml);
                  const wrappedHtml = `<span style="background-color: #d1ffbd;">${selectedHtml}</span>`;
                  const newDelta = quill.clipboard.convert(wrappedHtml);
                  const Delta = Quill.import("delta");
                  console.log("wrappedHtml", wrappedHtml);
                  setLastWrappedHtml(wrappedHtml);
                  console.log("setLastWrappedHtml", lastWrappedHtml);
                  console.log(range);
                  setSelectedRange(range);
                  console.log(selectedRange);
                  setAddedSpans([
                    ...addedSpans,
                    { index: range.index, length: newDelta.length() },
                  ]);

                  // Update the content in Quill editor
                  quill.updateContents(
                    new Delta()
                      .retain(range.index)
                      .delete(range.length)
                      .concat(newDelta)
                  );

                  // Clear the selection
                  quill.setSelection(range.index + newDelta.length(), 0);
                  const scrollX =
                    window.pageXOffset !== undefined
                      ? window.pageXOffset
                      : (
                          document.documentElement ||
                          document.body.parentNode ||
                          document.body
                        ).scrollLeft;
                  const scrollY =
                    window.pageYOffset !== undefined
                      ? window.pageYOffset
                      : (
                          document.documentElement ||
                          document.body.parentNode ||
                          document.body
                        ).scrollTop;

                  console.log(addedSpans);
                  const quillBounds = quill.getBounds(
                    range.index,
                    range.length
                  );
                  const editorElement = quill.root;
                  const editorRect = editorElement.getBoundingClientRect();
                  const tooltipX = editorRect.left + scrollX + quillBounds.left;
                  const tooltipY =
                    editorRect.top + scrollY + quillBounds.bottom;

                  setTooltipPosition({ x: tooltipX, y: tooltipY });
                  // setSmallTooltipPosition({ x: tooltipX, y: tooltipY });
                  setShowSmallTool(false);
                  setShowReplaceField(true);
                }
              }
            }}
            sx={{ width: "20px", height: "20px", color: "white" }} // Customize the width and height here
          >
            <EditIcon />
          </IconButton>
        </Box>
      )}
      {tooltipPosition && showReplaceField && (
        <Box
          ref={tooltipRef}
          position="absolute"
          left={tooltipPosition.x}
          top={tooltipPosition.y + 10} // Adjust position below the selection
          bgcolor="white"
          // color="white"
          border="1px solid #ccc"
          p={5}
          zIndex={10}
          display="block"
          alignItems="start"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <div>
            <ReactQuill
              ref={quillGeneratedText}
              value={regeneratedText}
              theme="snow"
              style={{ maxWidth: "50rem" }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
              }}
            />
          </div>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Write prompt"
              multiline={true}
              rows={5}
              placeholder=""
              style={{ marginRight: "1rem", minWidth: "30rem" }}
              value={extraText}
              onChange={handleExtraTextChange}
            />
            {!write_prompt ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleRegenerateSelection}
                >
                  Re-Generate
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  style={{ marginLeft: "1rem" }}
                  size="small"
                  onClick={cancelRegen}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleAcceptRegeneratedText}
                >
                  Accept
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={handleRegenerateSelection}
                >
                  Try Again
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  size="small"
                  onClick={cancelRegen}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Box>
      )}

      <form className="" onSubmit={handleCreateTOC}>
        <Box display="flex" justifyContent="center" padding="1rem 0">
          <h2>TweSocial</h2>
        </Box>
        <Box minWidth="100%" display="flex" flexDirection="row" gap="20px">
          <TextField
            name="wordcount"
            style={{ margin: "1rem 0rem", maxWidth: "7rem" }}
            className="textfield"
            required
            id="outlined-required"
            label="Wordcount"
            placeholder=""
            onChange={handleValueChange}
          />
          <TextField
            name="keyword"
            style={{ margin: "1rem 0rem" }}
            className="textfield"
            required
            id="outlined-required"
            label="Target Keyword"
            placeholder=""
            onChange={handleValueChange}
          />

          <TextField
            style={{ margin: "1rem 0rem" }}
            name="all_keywords"
            className="textfield"
            required
            id="outlined-required"
            label="All Keywords"
            placeholder="buy quora views 0/12–14 quora account 0/4–5 quora answer 0/10–11 ( select from surfer and paste here )"
            onChange={handleValueChange}
          />
          <TextField
            style={{ margin: "1rem 0rem" }}
            name="heading_keywords"
            className="textfield"
            required
            id="outlined-required"
            label="Heading Keywords"
            placeholder="buy quora views 0 quora account 0 quora answer 0 quora views 0"
            onChange={handleValueChange}
          />
        </Box>

        <Box minWidth="100%">
          <TextField
            style={{ margin: "1rem 0rem" }}
            name="links"
            className="textfield"
            required
            id="outlined-required"
            label="Competitor links"
            placeholder="Link1, Link2"
            onChange={handleValueChange}
          />
        </Box>

        <Box
          minWidth=""
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            name="faq"
            style={{ margin: "1rem 0rem" }}
            className="textfield"
            required
            id="outlined-required"
            label="FAQ"
            placeholder="Question1, Question2 , Question3"
            onChange={handleValueChange}
          />
          <Checkbox
            checked={includeFAQ}
            onChange={() => setIncludeFAQ(!includeFAQ)}
            color="primary"
          />
          <h3>INCLUDE?</h3>
        </Box>
        <Box
          style={{ margin: "1rem 0rem" }}
          minWidth="100%"
          display="flex"
          justifyContent="end"
        >
          <Button
            className="login_button"
            type="submit"
            variant="contained"
            color="primary"
            style={{ height: "47px", minWidth: "10rem" }}
          >
            {loading ? <>Loading...</> : <>Create TOC</>}
          </Button>
        </Box>
      </form>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <p style={{ fontSize: "20px", margin: "0rem", fontWeight: "bold" }}>
          {"Category: "}
        </p>
        <p style={{ fontSize: "15px", margin: "0rem 0rem 0rem 1rem" }}>
          {suggestedCategory}
        </p>
      </div> */}
      {/* <div
        style={{
          display: "flex",
          margin: "0.5rem 0rem 1.5rem 0rem",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <p style={{ fontSize: "20px", margin: "0rem", fontWeight: "bold" }}>
          {"Tags: "}
        </p>
        <p style={{ fontSize: "15px", margin: "0rem 0rem 0rem 1rem" }}>
          {suggestedTags}
        </p>
      </div> */}

      <div
        style={{
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "row-reverse",
          minWidth: "100%",
        }}
      >
        {tableOfContent && (
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            minWidth="100%"
            //   alignItems="center"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "100%",
              }}
            >
              <Box
                p={1}
                border={2}
                display="flex"
                flexDirection="column"
                borderColor="grey.300"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addHeading}
                >
                  Add Heading 2
                </Button>
              </Box>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="toc">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {tableOfContent.headings.map((heading, index) => (
                        <Draggable
                          key={heading.id}
                          draggableId={heading.id}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              display="flex"
                              flexDirection="column"
                              p={1}
                              border={2}
                              borderColor="grey.300"
                              style={{
                                background:
                                  currentHeadingIndex > index
                                    ? "lightgreen"
                                    : "",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <TextField
                                  name="wordcount"
                                  style={{ maxWidth: "7rem" }}
                                  value={String(Number(heading.characters))}
                                  className="textfield"
                                  required
                                  id="outlined-required"
                                  onChange={(e) =>
                                    updateWordCount(heading.id, e.target.value)
                                  }
                                />
                                <div
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                  }}
                                >
                                  {"H2"}
                                </div>
                                <TextField
                                  value={heading.title}
                                  onChange={(e) =>
                                    updateTitle(heading.id, e.target.value)
                                  }
                                  fullWidth
                                />
                                <Button
                                  style={{
                                    minWidth: "7rem",
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                  }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    addSubheading(heading.tag, [index])
                                  }
                                >
                                  ADD H3
                                </Button>
                                <Button
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => deleteHeading(index)}
                                >
                                  Delete
                                </Button>
                              </Box>
                              {heading.subheadings &&
                                heading.subheadings.length > 0 &&
                                renderSubheadings(heading.subheadings, [index])}
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Box>
        )}
      </div>
      <Box
        style={{ margin: "1rem 0rem" }}
        minWidth="100%"
        display="flex"
        justifyContent="end"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "2rem",
            maxWidth: "20%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              className="login_button"
              type="submit"
              variant="contained"
              color="primary"
              style={{ height: "47px", minWidth: "10rem" }}
              onClick={generateFullArticle}
            >
              {loadingAllHeading ? <>Loading...</> : <>Generate Full Article</>}
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            ></div>
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0rem",
                color: loadingAllHeading ? "green" : "",
              }}
            >
              1/4 Writing all headings...{" "}
              {tableOfContent != null &&
              currentHeadingIndex === tableOfContent.headings.length ? (
                <>
                  <span style={{ color: "green", marginLeft: "1rem" }}>
                    DONE
                  </span>
                </>
              ) : (
                <></>
              )}{" "}
            </h5>
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0rem",
                color: loadingIntroduction ? "green" : "",
              }}
            >
              2/4 Writing introduction...{" "}
              {introductionWritten ? (
                <>
                  <span style={{ color: "green", marginLeft: "1rem" }}>
                    DONE
                  </span>
                </>
              ) : (
                <></>
              )}{" "}
            </h5>
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0rem",
                color: loadingFAQ ? "green" : "",
              }}
            >
              4/4 Writing faq...
              {faqWritten ? (
                <>
                  <span style={{ color: "green", marginLeft: "1rem" }}>
                    DONE
                  </span>
                </>
              ) : (
                <></>
              )}{" "}
            </h5>
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0rem",
                color: loadingConclusion ? "green" : "",
              }}
            >
              3/4 Writing conclusion...
              {conclusionWritten ? (
                <>
                  <span style={{ color: "green", marginLeft: "1rem" }}>
                    DONE
                  </span>
                </>
              ) : (
                <></>
              )}{" "}
            </h5>

            {loadingAllHeading ? (
              <>
                <Button
                  variant="contained"
                  color="error"
                  style={{
                    minHeight: "1rem",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    marginTop: "1rem",
                  }}
                  onClick={handleStopGeneration} // Call the stop function
                >
                  Stop Writing
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Box>
      {articleGenerated.length > 0 ? (
        <>
        <h3>Title</h3>
          <TextField
            label="Title"
            value={post_heading}
            onChange={(e) => {
              // debugger
              setPostHeading(e.target.value);
            }}
            variant="outlined"
            fullWidth
            style={{ marginBottom: "10px" }}
          />
          <h3>Content</h3>
          <ReactQuill
            ref={quillRef}
            value={articleGenerated}
            onChange={handleEditorChange}
            theme="snow"
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
          />
          <h3>Featured Image</h3>

          <ImageEditor
            ref={imageEditorRef}
            teamLogos={{ team1Logo: "team1.logo", team2Logo: "team2.logo" }}
            imgurl={
              "https://dotalive.org/wp-content/uploads/2024/08/background_wep.webp"
            }
            preview={false}
            provider="twesocial"
            aspect_ratio="16:9"
            main_keyword={"asdsad"}
          />
        </>
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        disabled={articleGenerated.length > 0 ? false : true}
        onClick={handleSave}
      >
        {loadingUploadingPage ? <>Loading...</> : <>Create Post</>}
      </Button>

      {/* <Button
            variant="contained"
            onClick={handleGetJson}
            color="success"
            style={{ minWidth: "15rem" }}
          >
            Download JSON
          </Button> */}
      {/* <ToastContainer /> */}

      {/* <ImageEditor
        teamLogos={{
          team1Logo: "",
          team2Logo: "",
        }}
        imgurl={""}
        main_keyword={formData.keyword}
        preview={false}
      /> */}
    </div>
  );
}

export default TweSocial;
