// TeamList.tsx

export interface Player {
    role: string;
    name: string;
    rank: string;
  }
  
  export interface Team {
    name: string;
    rank: string;
    winrate: string;
    avgKills: string;
    logo: string;
    players: Player[];
  }
  
  export const teams: Team[] = [
    {
      name: "MOUZ",
      rank: "#20",
      winrate: "57%",
      avgKills: "21.9",
      logo: "https://dltv.org/uploads/teams/small/XvST6sGtx30WVvvQJJaAMqpYzwzlLdvN.png.webp",
      players: [
        { role: "Core", name: "Ulnit", rank: "127" },
        { role: "Mid", name: "Supream^", rank: "27" },
        { role: "Offlane", name: "Force", rank: "144" },
        { role: "Support", name: "NARMAN", rank: "90" },
        { role: "Full Support", name: "Bengan", rank: "292" },
      ],
    },
    {
      name: "DMS",
      rank: "#51",
      winrate: "61%",
      avgKills: "24.3",
      logo: "https://dltv.org/uploads/teams/medium/XClxAfoXHo3pQ4sgS369UIOnnmtaDa91.png.webp",
      players: [
        { role: "Core", name: "SHIGETSU", rank: "51" },
        { role: "Mid", name: "MELLOJUL^", rank: "38" },
        { role: "Offlane", name: "DAXAK", rank: "283" },
        { role: "Support", name: "PRBLMS", rank: "359" },
        { role: "Full Support", name: "AIK", rank: "117" },
      ],
    },
    {
        name: "TEAM FALCONS",
        rank: "#1",
        winrate: "61%",
        avgKills: "28.1",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM_FALCONS.png",
        players: [
          { role: "Core", name: "SKITTER", rank: "96" },
          { role: "Mid", name: "MARLINE", rank: "1" },
          { role: "Offlane", name: "ATF", rank: "13" },
          { role: "Support", name: "CRIT-", rank: "54" },
          { role: "Full Support", name: "SNEYKING", rank: "56" },
        ],
      },
      {
        name: "GAIMIN GLADIATORS",
        rank: "#2",
        winrate: "63%",
        avgKills: "22.8",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/GAIMIN-GLADIATORS.png",
        players: [
          { role: "Core", name: "WATSON", rank: "3" },
          { role: "Mid", name: "QUINN", rank: "16" },
          { role: "Offlane", name: "ACE", rank: "430" },
          { role: "Support", name: "TOFU", rank: "36" },
          { role: "Full Support", name: "SELERI", rank: "331" },
        ],
      },
      {
        name: "XTREME GAMING",
        rank: "#3",
        winrate: "67%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/XTREME-GAMING.png",
        players: [
          { role: "Core", name: "AME", rank: "26" },
          { role: "Mid", name: "XM", rank: "10" },
          { role: "Offlane", name: "XXS", rank: "22" },
          { role: "Support", name: "XINQ", rank: "215" },
          { role: "Full Support", name: "DY", rank: "442" },
        ],
      },
      {
        name: "TEAM LIQUID",
        rank: "#4",
        winrate: "58%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/team_liquid.png",
        players: [
          { role: "Core", name: "MICKE", rank: "-" },
          { role: "Mid", name: "NISHA", rank: "-" },
          { role: "Offlane", name: "SaberLight", rank: "128" },
          { role: "Support", name: "BOXI", rank: "289" },
          { role: "Full Support", name: "INSANIA", rank: "239" },
        ],
      },
      {
        name: "TEAM SPIRIT  ",
        rank: "#5",
        winrate: "58%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM-SPIRIT.png",
        players: [
          { role: "Core", name: "RADDAN", rank: "21" },
          { role: "Mid", name: "LARL", rank: "108" },
          { role: "Offlane", name: "COLLAPSE", rank: "27" },
          { role: "Support", name: "MIRA", rank: "347" },
          { role: "Full Support", name: "MIPOSHKA", rank: "127" },
        ],
      },
      {
        name: "BETBOOM TEAM",
        rank: "#6",
        winrate: "44%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM_BB.png",
        players: [
          { role: "Core", name: "SAIKA", rank: "2" },
          { role: "Mid", name: "GPJ", rank: "5" },
          { role: "Offlane", name: "MEIRA", rank: "-" },
          { role: "Support", name: "SAVE", rank: "52" },
          { role: "Full Support", name: "TORONTOTOKYO", rank: "37" },
        ],
      },
      {
        name: "TUNDRA ESPORTS",
        rank: "#7",
        winrate: "65%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/THUNDRA-TEAM.png",
        players: [
          { role: "Core", name: "SHAD", rank: "44" },
          { role: "Mid", name: "MIDONE", rank: "53" },
          { role: "Offlane", name: "33", rank: "175" },
          { role: "Support", name: "SAKSA", rank: "11" },
          { role: "Full Support", name: "WHITEMON", rank: "134" },
        ],
      },
      {
        name: "G2.IG",
        rank: "#8",
        winrate: "56%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/G2IG.png",
        players: [
          { role: "Core", name: "MONET", rank: "161" },
          { role: "Mid", name: "NOTHINGTOSAY", rank: "10" },
          { role: "Offlane", name: "JT-", rank: "94" },
          { role: "Support", name: "BOBOKA", rank: "208" },
          { role: "Full Support", name: "XNOVA", rank: "378" },
        ],
      },
      {
        name: "1WIN",
        rank: "#9",
        winrate: "63%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/1WIN-IMAGE.png",
        players: [
          { role: "Core", name: "MUNKUSHI", rank: "83" },
          { role: "Mid", name: "CHIRA_JUNIOR", rank: "57" },
          { role: "Offlane", name: "CLOUD", rank: "102" },
          { role: "Support", name: "SWEDENSTRONG", rank: "48" },
          { role: "Full Support", name: "RESPECT", rank: "228" },
        ],
      },
      {
        name: "NOUNS",
        rank: "#12",
        winrate: "55%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/nouns.png",
        players: [
          { role: "Core", name: "YUMA", rank: "43" },
          { role: "Mid", name: "COPY", rank: "107" },
          { role: "Offlane", name: "GUNNAR", rank: "321" },
          { role: "Support", name: "LELIS", rank: "146" },
          { role: "Full Support", name: "FLY", rank: "190" },
        ],
      },
      {
        name: "HEROIC",
        rank: "#13",
        winrate: "55%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/HEROIC.png",
        players: [
          { role: "Core", name: "K1", rank: "-" },
          { role: "Mid", name: "4NALOG", rank: "-" },
          { role: "Offlane", name: "DAVAI LAMA", rank: "18" },
          { role: "Support", name: "SCOFIELD", rank: "11" },
          { role: "Full Support", name: "KJ", rank: "271" },
        ],
      },
      {
        name: "AURORA",
        rank: "#14",
        winrate: "56%",
        avgKills: "33",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/AURORA.png",
        players: [
          { role: "Core", name: "23SAVAGE", rank: "-" },
          { role: "Mid", name: "LORENOF", rank: "-" },
          { role: "Offlane", name: "JABZ", rank: "-" },
          { role: "Support", name: "Q", rank: "-" },
          { role: "Full Support", name: "OLI", rank: "-" },
        ],
      },
      {
        name: "CLOUD9",
        rank: "#15",
        winrate: "33%",
        avgKills: "21",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/CLOUD9.png",
        players: [
          { role: "Core", name: "WATSON", rank: "4" },
          { role: "Mid", name: "NO[0]ONE", rank: "65" },
          { role: "Offlane", name: "DM", rank: "32" },
          { role: "Support", name: "KATAOMI", rank: "124" },
          { role: "Full Support", name: "FISHMAN", rank: "85" },
        ],
      },
      {
        name: "BEASTCOAST",
        rank: "#18",
        winrate: "47%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/BEASTCOAST.png",
        players: [
          { role: "Core", name: "PAYK", rank: "162" },
          { role: "Mid", name: "ALONE", rank: "-" },
          { role: "Offlane", name: "VITALY", rank: "304" },
          { role: "Support", name: "ELMISHO", rank: "63" },
          { role: "Full Support", name: "MOOZ", rank: "415" },
        ],
      },
      {
        name: "TALON ESPORTS",
        rank: "#21",
        winrate: "56%",
        avgKills: "32",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/TALON-ESPORTS.png",
        players: [
          { role: "Core", name: "AKASHI", rank: "187" },
          { role: "Mid", name: "MIKOTO", rank: "23" },
          { role: "Offlane", name: "WS", rank: "3" },
          { role: "Support", name: "JHOCAM", rank: "242" },
          { role: "Full Support", name: "PONYO", rank: "368" },
        ],
      },
      {
        name: "TEAM ZERO",
        rank: "#26",
        winrate: "45%",
        avgKills: "32",
        logo: "https://dotalive.org/wp-content/uploads/2024/08/TEAM-ZERO.png",
        players: [
          { role: "Core", name: "ERIKA", rank: "34" },
          { role: "Mid", name: "7E", rank: "-" },
          { role: "Offlane", name: "BEYOND", rank: "87" },
          { role: "Support", name: "PONLO", rank: "97" },
          { role: "Full Support", name: "ZZQ", rank: "368" },
        ],
      },
      {
        name: "AVALUS",
        rank: "#22",
        winrate: "66%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/avalus_dota2.webp",
        players: [
          { role: "Core", name: "Smiling Knight", rank: "87" },
          { role: "Mid", name: "Stormstormer", rank: "132" },
          { role: "Offlane", name: "Xibbe", rank: "335" },
          { role: "Support", name: "Ekki", rank: "54" },
          { role: "Full Support", name: "SoNNeikO", rank: "145" },
        ],
      },
      {
        name: "Invictus Gaming",
        rank: "#22",
        winrate: "62%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/Invictus-Gaming-dota-2-image.webp",
        players: [
          { role: "Core", name: "flyfly", rank: "78" },
          { role: "Mid", name: "Emo", rank: "6" },
          { role: "Offlane", name: "zeal", rank: "-" },
          { role: "Support", name: "BoBoKa", rank: "160" },
          { role: "Full Support", name: "Oli", rank: "208" },
        ],
      },
      {
        name: "Azure Ray",
        rank: "#22",
        winrate: "62%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/Azure-Ray-image-dota2-.webp",
        players: [
          { role: "Core", name: "LOU", rank: "39" },
          { role: "Mid", name: "HAN", rank: "115" },
          { role: "Offlane", name: "niu", rank: "11" },
          { role: "Support", name: "PLANET", rank: "62" },
          { role: "Full Support", name: "Siamese.C", rank: "129" },
        ],
      },
      {
        name: "Alliance",
        rank: "#22",
        winrate: "62%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/alliance-dota-2-logo-image.webp",
        players: [
          { role: "Core", name: "NANDE", rank: "401" },
          { role: "Mid", name: "  MO13E1", rank: "153" },
          { role: "Offlane", name: "SKYLARK", rank: "225" },
          { role: "Support", name: "DESIRE", rank: "136" },
          { role: "Full Support", name: "KIDARO", rank: "355" },
        ],
      },
      {
        name: "Natus Vincere",
        rank: "#18",
        winrate: "62%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/NAVI-DOTA2-IMAGE.webp",
        players: [
          { role: "Core", name: "YURAGI", rank: "34" },
          { role: "Mid", name: "  BZM", rank: "7" },
          { role: "Offlane", name: "BOOM", rank: "61" },
          { role: "Support", name: "ZAYAC", rank: "249" },
          { role: "Full Support", name: "PUPPEY", rank: "819" },
        ],
      },
      {
        name: "Infinity",
        rank: "#36",
        winrate: "54%",
        avgKills: "28",
        logo: "https://dotalive.org/wp-content/uploads/2024/10/Infinity-dota2-image.webp",
        players: [
          { role: "Core", name: "Costabile", rank: "221" },
          { role: "Mid", name: "  Pipi", rank: "546" },
          { role: "Offlane", name: "ILICH", rank: "228" },
          { role: "Support", name: "N1GHT", rank: "833" },
          { role: "Full Support", name: "PANDA", rank: "586" },
        ],
      },
    // Add more teams here
  ];
