import React, { useState } from "react";
import "./../styles/SoldMagazine.css";
import {
  Alert,
  Button,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { teams } from "./Teamlist.tsx"; // Import the teams

const HeroCategory = ({
  title,
  icon,
  heroes,
  onAddToRadiant,
  onAddToDire,
  teamRadiant,
  teamDire,
}: {
  title: string;
  icon: string;
  heroes: string[];
  onAddToRadiant: (hero: string, role: string) => void;
  onAddToDire: (hero: string, role: string) => void;
  teamRadiant: string[];
  teamDire: string[];
}) => {
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [showRadiantRoles, setShowRadiantRoles] = useState<boolean>(false);
  const [showDireRoles, setShowDireRoles] = useState<boolean>(false);

  return (
    <div id={title.toLowerCase()}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignContent: "center",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <img
          style={{ width: "2rem" }}
          src={`/assets/img/heroes/${icon}`}
          alt={title}
        />
        <span style={{ fontSize: "2rem", fontWeight: "bold", color: "gray" }}>
          {title}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px",
        }}
      >
        {heroes.map((hero) => {
          const isUsed =
            teamRadiant.includes(hero.replace(".png", "")) ||
            teamDire.includes(hero.replace(".png", ""));

          return (
            <div
              key={hero}
              style={{
                position: "relative",
                display: "inline-block",
                width: "4.2rem",
                height: "2.6rem",
                opacity: isUsed ? "0.3" : "1",
              }}
              className={isUsed ? "0.3" : "hero_img"}
            >
              <img
                src={`/assets/img/heroes/${hero}`}
                alt={hero}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "5px",
                  transition: "transform 0.2s ease, z-index 0.2s",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                onMouseEnter={() => {
                  if (!isUsed) {
                    setCurrentId(hero); // Update the current hero ID
                  }
                }}
                onMouseLeave={() => {
                  setCurrentId(null); // Clear the hero ID
                  setShowRadiantRoles(false); // Clear the hero ID
                  setShowDireRoles(false);
                }}
              />
              {/* Overlay message when hovering */}
              {currentId === hero && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    bottom:
                      !showRadiantRoles && !showDireRoles
                        ? "-1.5rem"
                        : "-5.5rem",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "rgba(0, 0, 0, 0.8)",
                    color: "red",
                    fontWeight: "bold",
                    padding: "0.2rem 0.2rem",
                    borderRadius: "5px",
                    zIndex: 2,
                    gap: "5px",
                  }}
                  onMouseEnter={() => {
                    setCurrentId(hero); // Update the current hero ID
                  }}
                  onMouseLeave={() => {
                    setCurrentId(null); // Clear the hero ID
                    setShowRadiantRoles(false); // Clear the hero ID
                    setShowDireRoles(false);
                  }}
                >
                  <div style={{ fontSize: "0.5rem", display: "flex" }}>
                    {showRadiantRoles ? (
                      <>
                        <div className="role_div">
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(hero.replace(".png", ""), "carry");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Carry
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(hero.replace(".png", ""), "mid");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Mid
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "offlane"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Offlane
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Support
                          </div>
                          <div
                            className="current_role"
                            onClick={() => {
                              onAddToRadiant(
                                hero.replace(".png", ""),
                                "hard_support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Hard Support
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setShowRadiantRoles(true);
                            setShowDireRoles(false);
                          }}
                          style={{
                            fontSize: "0.5rem",
                            padding: "0rem",
                            margin: "0rem",
                            background: "green",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            fontWeight: "bold",
                            alignContent: "center",
                            alignItems: "center",
                            maxHeight: "1.5rem",
                          }}
                        >
                          <AddIcon
                            style={{
                              maxWidth: "1rem",
                              minWidth: "1rem",
                              width: "1rem",
                              maxHeight: "1.5rem",
                            }}
                          />
                          Radiant
                        </Button>
                      </>
                    )}
                  </div>
                  <div style={{ fontSize: "0.5rem", display: "flex" }}>
                    {showDireRoles ? (
                      <>
                        <div className="role_div">
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "carry");
                            }}
                          >
                            Carry
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "mid");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Mid
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "offlane");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Offlane
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(hero.replace(".png", ""), "support");
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Support
                          </div>
                          <div
                            className="dire_current_role"
                            onClick={() => {
                              onAddToDire(
                                hero.replace(".png", ""),
                                "hard_support"
                              );
                              setShowRadiantRoles(false); // Clear the hero ID
                              setShowDireRoles(false);
                              setCurrentId(null); // Clear the hero ID
                            }}
                          >
                            Hard Support
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            // onAddToDire(hero.replace(".png", "")); // Add to Dire without .png
                            setShowRadiantRoles(false);
                            setShowDireRoles(true);
                          }}
                          style={{
                            fontSize: "0.5rem",
                            padding: "0rem",
                            margin: "0rem",
                            background: "red",
                            color: "white",
                            display: "flex",
                            flexDirection: "row",
                            maxHeight: "1.5rem",
                            fontWeight: "bold",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AddIcon
                            style={{
                              maxWidth: "1rem",
                              minWidth: "1rem",
                              width: "1rem",
                              maxHeight: "1.5rem",
                            }}
                          />
                          Dire
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface Hero {
  heroName: string;
  role: string;
}

interface Player {
  role: string;
  name: string;
  rank: string;
}
interface Team {
  name: string;
  rank: string;
  winrate: string;
  avgKills: string;
  logo: string;
  players: Player[];
}

const DraftPicker: React.FC = () => {
  const [isAnalyseClicked, setIsAnalyseClicked] = useState<boolean>(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState<boolean>(false);
  const [showAnalysis, setShowAnalysis] = useState<boolean>(false);
  const [teamRadiant, setTeamRadiant] = useState<string[]>([]);
  const [teamDire, setTeamDire] = useState<string[]>([]);
  const [teamFullRadiant, setTeamFullRadiant] = useState<Hero[]>([]);
  const [teamFullDire, setTeamFullDire] = useState<Hero[]>([]);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [team1, setTeam1] = useState<Team>(teams[0]);
  const [team2, setTeam2] = useState<Team>(teams[1]);
  const handleTeamChange = (index: number, teamName: string) => {
    const selectedTeam = teams.find((team) => team.name === teamName);
    if (selectedTeam) {
      if (index === 1) setTeam1(selectedTeam);
      else setTeam2(selectedTeam);
    }
  };

  const handleAddToRadiant = (heroName: string, role: string) => {
    if (teamRadiant.length > 4) {
      console.log(">>>>>>>>>>>>>>>>>");
      setToastMessage("Already 5 Radiant Heroes");
      handleOpen();
      return;
    }
    if (!teamRadiant.includes(heroName)) {
      setTeamRadiant((prev) => [...prev, heroName]);
      setTeamFullRadiant((prev) => {
        if (prev.some((hero) => hero.heroName === heroName)) {
          return prev;
        }
        return [...prev, { heroName, role }]; // This works if heroName and role are variables in scope
      });
      setSearchQuery("");
    }
  };

  const handleAddToDire = (heroName: string, role: string) => {
    if (teamDire.length > 4) {
      console.log(">>>>>>>>>>>>>>>>>");
      setToastMessage("Already 5 Dire Heroes");
      handleOpen();
      return;
    }

    if (!teamDire.includes(heroName)) {
      setTeamDire((prev) => [...prev, heroName]);
      setTeamFullDire((prev) => {
        if (prev.some((hero) => hero.heroName === heroName)) {
          return prev;
        }
        return [...prev, { heroName, role }]; // This works if heroName and role are variables in scope
      });
      setSearchQuery("");
    }
  };

  const [open, setOpen] = useState(false);
  const [toast_message, setToastMessage] = useState("empty_fields");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveFromRadiant = (heroName: string) => {
    setTeamRadiant((prev) => prev.filter((hero) => hero !== heroName));
    setTeamFullRadiant((prev) =>
      prev.filter((hero) => hero.heroName !== heroName)
    );
    setIsAnalyseClicked(false)
  };

  const handleRemoveFromDire = (heroName: string) => {
    setTeamDire((prev) => prev.filter((hero) => hero !== heroName));
    setTeamFullDire((prev) =>
      prev.filter((hero) => hero.heroName !== heroName)
    );
    setIsAnalyseClicked(false)
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase()); // Convert to lowercase for case-insensitive search
  };

  const filterHeroes = (heroes: string[]) => {
    return heroes.filter((hero) =>
      hero.replace(".png", "").toLowerCase().includes(searchQuery)
    );
  };

  const handleAnalysingDraft = async () => {
    debugger;
    setIsAnalyseClicked(true);
    setLoadingAnalysis(true)
    setTimeout(()=>{
      setLoadingAnalysis(false)
      setShowAnalysis(true)
    }, 4000)
    //     try {
    //       let payload
    //       let postContentFrom
    //       if(imageInstagramPost.current !=null){
    //         payload = imageInstagramPost.current.generatePayload(); // This would return the payload from ImageEditor
    //         postContentFrom = imageInstagramPost.current.returnPostContent(); // This would return the payload from ImageEditor
    // debugger
    //       }

    //       const formData = new FormData();
    //       formData.append("data", JSON.stringify(payload));
    //       formData.append("allContent", htmlContent); // Add WordPress content

    //       //
    //       // SetLoading(true)
    //       // const response = await fetch("https://featured-image.wecon-digital.agency/generate-wordpress-dota-page", {
    //       //   method: "POST",
    //       //   headers: {
    //       //     "Content-Type": "application/json",
    //       //   },
    //       //   body: JSON.stringify({ allContent }),
    //       // });
    //       try {
    //         SetLoading(true)
    //         const response = await fetch("https://featured-image.wecon-digital.agency/upload-dota-instagram-post", {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify({
    //             promotion:promotionImage,
    //             publish:createInstagramPost,
    //             post_content:postContentFrom,  // Send allContent
    //             scheduled_date:imageInstagramPostDate,
    //             data: JSON.stringify(payload),  // Send payload as a string
    //           }),
    //         });

    //         if (response.ok) {
    //           const jsonResponse = await response.json();
    //           console.log("Success:", jsonResponse);
    //         } else {
    //           console.error("Failed to create image on server");
    //         }
    //       } catch (error) {
    //         console.error("Error uploading image:", error);
    //       }

    //       console.log("Articles saved successfully");
    //       // SetLoading(false)
    //     } catch (error) {
    //       // SetLoading(false)
    //       console.error("Error saving articles:", error);
    //     }
  };
  const strength = [
    "alchemist.png",
    "axe.png",
    "bristleback.png",
    "centaur.png",
    "chaos_knight.png",
    "dawnbreaker.png",
    "doom_bringer.png",
    "dragon_knight.png",
    "earth_spirit.png",
    "earthshaker.png",
    "elder_titan.png",
    "huskar.png",
    "kunkka.png",
    "ogre_magi.png",
    "legion_commander.png",
    "life_stealer.png",
    "mars.png",
    "night_stalker.png",
    "omniknight.png",
    "primal_beast.png",
    "pudge.png",
    "slardar.png",
    "spirit_breaker.png",
    "sven.png",
    "tidehunter.png",
    "shredder.png",
    "tiny.png",
    "treant.png",
    "tusk.png",
    "abyssal_underlord.png",
    "undying.png",
    "skeleton_king.png",
  ];

  const intelligence = [
    "ancient_apparition.png",
    "crystal_maiden.png",
    "death_prophet.png",
    "disruptor.png",
    "enchantress.png",
    "grimstroke.png",
    "jakiro.png",
    "keeper_of_the_light.png",
    "leshrac.png",
    "lich.png",
    "lina.png",
    "lion.png",
    "muerta.png",
    "furion.png",
    "necrolyte.png",
    "oracle.png",
    "obsidian_destroyer.png",
    "puck.png",
    "pugna.png",
    "queenofpain.png",
    "rubick.png",
    "shadow_demon.png",
    "shadow_shaman.png",
    "silencer.png",
    "skywrath_mage.png",
    "storm_spirit.png",
    "tinker.png",
    "warlock.png",
    "witch_doctor.png",
    "zuus.png",
  ];

  const agility = [
    "antimage.png",
    "arc_warden.png",
    "bloodseeker.png",
    "bounty_hunter.png",
    "clinkz.png",
    "drow_ranger.png",
    "ember_spirit.png",
    "faceless_void.png",
    "gyrocopter.png",
    "hoodwink.png",
    "juggernaut.png",
    "luna.png",
    "medusa.png",
    "meepo.png",
    "monkey_king.png",
    "morphling.png",
    "naga_siren.png",
    "phantom_assassin.png",
    "phantom_lancer.png",
    "razor.png",
    "riki.png",
    "shadow_fiend.png",
    "slark.png",
    "sniper.png",
    "spectre.png",
    "templar_assassin.png",
    "terrorblade.png",
    "troll_warlord.png",
    "ursa.png",
    "viper.png",
    "weaver.png",
  ];

  const universal = [
    "abaddon.png",
    "bane.png",
    "batrider.png",
    "beastmaster.png",
    "brewmaster.png",
    "broodmother.png",
    "chen.png",
    "rattletrap.png",
    "dark_seer.png",
    "dark_willow.png",
    "dazzle.png",
    "enigma.png",
    "invoker.png",
    "wisp.png",
    "lone_druid.png",
    "lycan.png",
    "magnataur.png",
    "marci.png",
    "mirana.png",
    "nyx_assassin.png",
    "pangolier.png",
    "phoenix.png",
    "sand_king.png",
    "snapfire.png",
    "techies.png",
    "vengefulspirit.png",
    "venomancer.png",
    "visage.png",
    "void_spirit.png",
    "windrunner.png",
    "winter_wyvern.png",
  ];

  return (
    <div style={{ minHeight: "100vh" }}>
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <TextField
          label="Search Heroes"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ background: "white", width: "50%" }}
        />
      </div>
      {/* -----------------------------------------------HEROES-------------------------------------------------------- */}
      {isAnalyseClicked ? (
        <></>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 3rem 0.5rem 3rem",
            }}
          >
            <HeroCategory
              title="Strength"
              icon="str.png"
              heroes={filterHeroes(strength)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
            <HeroCategory
              title="Intelligence"
              icon="int.png"
              heroes={filterHeroes(intelligence)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 3rem 0rem 3rem",
              marginBottom: "1rem",
            }}
          >
            <HeroCategory
              title="Agility"
              icon="agi.png"
              heroes={filterHeroes(agility)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
            <HeroCategory
              title="Universal"
              icon="all.png"
              heroes={filterHeroes(universal)}
              onAddToRadiant={handleAddToRadiant}
              onAddToDire={handleAddToDire}
              teamRadiant={teamRadiant}
              teamDire={teamDire}
            />
          </div>
        </>
      )}

      {/* -----------------------------------------------DRAFT-------------------------------------------------------- */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "50px",
          padding: "1rem 3rem 1rem 3rem",
          marginBottom: "1rem",
          justifyContent: "center",
          borderTop:'1px solid lightgray',
          borderBottom:"2px solid lightgray"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            minWidth: "38rem",
            justifyContent: "center",
          }}
        >
          {teamFullRadiant.map((radiant_hero) => {
            function renderRole(role: string): string {
              switch (role) {
                case "carry":
                  return "Carry";
                case "mid":
                  return "Mid";
                case "offlane":
                  return "Offlane";
                case "support":
                  return "Support";
                case "hard_support":
                  return "Hard support";
              }
              return "";
            }

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    userSelect: "none",
                  }}
                >
                  <div
                    className="radient_hero_img"
                    onClick={() => {
                      handleRemoveFromRadiant(radiant_hero.heroName);
                    }}
                  >
                    X
                  </div>
                  <img
                    key={radiant_hero.heroName}
                    src={
                      `/assets/portraits/npc_dota_hero_` +
                      radiant_hero.heroName +
                      `.png`
                    }
                    alt={radiant_hero.heroName}
                    style={{
                      width: "7rem",
                      height: "10rem",
                      // borderRadius: "5px",
                      transition: "transform 0.2s ease, z-index 0.2s",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      padding: "0.2rem 0.5rem",
                      background: "lightgray",
                      textAlign: "center",
                    }}
                  >
                    {renderRole(radiant_hero.role)}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div
          style={{
            maxHeight: "5rem",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "28rem",
          }}
        >
          <h2 style={{ color: "white", margin: "0px", textAlign: "center" }}>
            Teams
          </h2>
          <div
            style={{
              background: "white",
              maxHeight: "2rem",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              minWidth: "30rem",
            }}
          >
            <Select
              value={team1.name}
              onChange={(e) => handleTeamChange(1, e.target.value as string)}
              fullWidth
              renderValue={(selected) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={teams.find((team) => team.name === selected)?.logo}
                    alt=""
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                    }}
                  />
                  {selected}
                </div>
              )}
            >
              {teams.map((team) => (
                <MenuItem key={team.name} value={team.name}>
                  <img
                    src={team.logo}
                    alt={team.name}
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                    }}
                  />
                  {team.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={team2.name}
              onChange={(e) => handleTeamChange(2, e.target.value as string)}
              fullWidth
              renderValue={(selected) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={teams.find((team) => team.name === selected)?.logo}
                    alt=""
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                    }}
                  />
                  {selected}
                </div>
              )}
            >
              {teams.map((team) => (
                <MenuItem key={team.name} value={team.name}>
                  <img
                    src={team.logo}
                    alt={team.name}
                    style={{
                      maxWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      marginRight: "10px",
                    }}
                  />
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            minWidth: "38rem",
            justifyContent: "center",
          }}
        >
          {teamFullDire.map((dire_hero) => {
            function renderRole(role: string): string {
              switch (role) {
                case "carry":
                  return "Carry";
                case "mid":
                  return "Mid";
                case "offlane":
                  return "Offlane";
                case "support":
                  return "Support";
                case "hard_support":
                  return "Hard support";
              }
              return "";
            }

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    userSelect: "none",
                  }}
                >
                  <div
                    className="radient_hero_img"
                    onClick={() => {
                      handleRemoveFromDire(dire_hero.heroName);
                    }}
                  >
                    X
                  </div>
                  <img
                    key={dire_hero.heroName}
                    src={
                      `/assets/portraits/npc_dota_hero_` +
                      dire_hero.heroName +
                      `.png`
                    }
                    alt={dire_hero.heroName}
                    style={{
                      width: "7rem",
                      height: "10rem",
                      // borderRadius: "5px",
                      transition: "transform 0.2s ease, z-index 0.2s",
                      zIndex: 1,
                    }}
                  />
                  <div
                    style={{
                      padding: "0.2rem 0.5rem",
                      background: "lightgray",
                      textAlign: "center",
                    }}
                  >
                    {renderRole(dire_hero.role)}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      {/* -----------------------------------------------ANALYTICS-------------------------------------------------------- */}

      {teamDire.length == 5 && teamRadiant.length == 5 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              padding: "1rem 3rem 0rem 3rem",
              marginBottom: "1rem",
              justifyContent: "end",
            }}
          >
            <Button variant="contained" onClick={handleAnalysingDraft}>
              {loadingAnalysis ? <>Loading...</>:<>Analyse this draft</>}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
      {showAnalysis ? <>      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)", // Three equal-width columns
          gap: "1.5rem", // Spacing between sections
          padding: "2rem",
          background: "#162e1e", // Dark green container background
          color: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          maxWidth: "1400px",
          margin: "2rem auto",
        }}
      >
        {/* Header */}
        <h2
          style={{
            gridColumn: "1 / -1", // Span the header across all columns
            textAlign: "center",
            fontSize: "2rem",
            marginBottom: "1.5rem",
            color:"white", // Darker green for the header text
            fontWeight: "bold",
          }}
        >
          Draft Analytics Dashboard
        </h2>

        {/* Sections */}
        {[
          {
            title: "Draft Analytics",
            items: [
              {
                subtitle: "Hero Matchups",
                description:
                  "Understand how heroes match up in lanes. Some heroes are naturally stronger in certain matchups.",
              },
              {
                subtitle: "Synergy and Counters",
                description:
                  "Assess team synergy (e.g., AoE abilities, stuns) and counter-picks.",
              },
              {
                subtitle: "Lane Dominance",
                description:
                  "Identify which heroes are likely to win their lanes based on the draft.",
              },
            ],
          },
          {
            title: "Lane Performance",
            items: [
              {
                subtitle: "Last Hits and Denies",
                description:
                  "Monitor the creep score (CS) of cores. A significant lead can translate into faster item timings.",
              },
              {
                subtitle: "Experience Advantage",
                description:
                  "Early kills or denied creeps can lead to level disparities that snowball into lane dominance.",
              },
              {
                subtitle: "Lane Equilibrium",
                description:
                  "Check if lanes are pushed or controlled near a team’s tower, which can impact safety and farming efficiency.",
              },
            ],
          },
          {
            title: "Map Control and Vision",
            items: [
              {
                subtitle: "Ward Placements",
                description:
                  "Early vision can reveal rotations and gank attempts.",
              },
              {
                subtitle: "Jungle Control",
                description:
                  "Assess whether supports are stacking camps or securing bounty runes effectively.",
              },
            ],
          },
          {
            title: "Player Movements",
            items: [
              {
                subtitle: "Roaming Supports",
                description:
                  "Notice if supports are successful in rotating and securing kills or putting pressure on cores.",
              },
              {
                subtitle: "Core Positioning",
                description:
                  "Core players farming efficiently and avoiding ganks indicate a strong early game.",
              },
            ],
          },
          {
            title: "First Blood and Early Kills",
            items: [
              {
                subtitle: "Kill Score",
                description:
                  "The kill score alone doesn’t dictate the game but shows which team is applying pressure effectively.",
              },
              {
                subtitle: "Gold and XP Swing",
                description:
                  "Early kills can lead to faster key item pickups or significant level advantages.",
              },
            ],
          },
          {
            title: "Objective Control",
            items: [
              {
                subtitle: "Tower Pushes",
                description:
                  "Taking an early tower can open up the map for aggressive plays.",
              },
              {
                subtitle: "Bounty Runes",
                description:
                  "Securing a majority of the bounty runes provides a critical early gold injection.",
              },
              {
                subtitle: "Neutral Objectives",
                description:
                  "Early neutral creeps and jungle farm efficiency can also indicate which team is scaling better.",
              },
            ],
          },
          {
            title: "Item Timings",
            items: [
              {
                subtitle: "Early Items",
                description:
                  "Watch for the completion of early items like Phase Boots, Magic Wand, or Bottle. These items often dictate lane sustainability and skirmishing ability.",
              },
              {
                subtitle: "Big Items",
                description:
                  "A quick Hand of Midas or Power Treads on cores can signal a team pulling ahead.",
              },
            ],
          },
          {
            title: "Pressure and Tempo",
            items: [
              {
                subtitle: "Aggression Levels",
                description:
                  "Teams applying early aggression or successfully defending against it often control the tempo.",
              },
              {
                subtitle: "Team Rotations",
                description:
                  "Coordinated movements to secure kills or objectives show better map control and planning.",
              },
            ],
          },
          {
            title: "Resource Management",
            items: [
              {
                subtitle: "Economy",
                description:
                  "Check net worth charts for the distribution of farm. A well-farmed core or greedy supports getting items can hint at future strength.",
              },
              {
                subtitle: "Mana and Health",
                description:
                  "Teams that conserve resources for key fights or regen faster can sustain pressure longer.",
              },
            ],
          },
        ].map((section, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #0f5132", // Dark green border for sections
              padding: "1rem",
              borderRadius: "8px",
              background: "#204a36", // Darker green for section background
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
            }}
          >
            <h3
              style={{
                margin: "0 0 0.5rem 0",
                color: "#1abc9c", // Brighter green for section titles
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {section.title}
            </h3>
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                margin: 0,
              }}
            >
              {section.items.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  style={{
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    borderRadius: "5px",
                    background: "#1c3a29", // Subtle darker green for items
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h4
                    style={{
                      margin: "0 0 0.2rem 0",
                      color: "#ffffff", // White for item subtitles
                      fontWeight: "bold",
                    }}
                  >
                    {item.subtitle}
                  </h4>
                  <p
                    style={{
                      margin: 0,
                      color: "#b7d4c3", // Light green for item descriptions
                      fontSize: "0.9rem",
                    }}
                  >
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            <textarea
              placeholder={`Provide your feedback on ${section.title}`}
              style={{
                marginTop: "1rem",
                padding: "0.5rem",
                width: "100%",
                height: "4rem",
                borderRadius: "5px",
                border: "1px solid #1abc9c", // Brighter green for feedback box border
                background: "#2b4f3c",
                color: "#ecf0f1", // Light text color for feedback input
                fontSize: "0.9rem",
                resize: "none",
              }}
            />
          </div>
        ))}
      </div></>:<></>}

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DraftPicker;
