import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useInsertionEffect,
} from "react";
import {
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
} from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  ArrowBack,
  ArrowForward,
  Add,
  Delete,
  ExpandMore,
  ZoomIn,
  ZoomOut,
  Refresh,
} from "@mui/icons-material";
import { HexColorPicker } from "react-colorful";
import "../fonts/font.css"; // Import the CSS file for the custom font
import { title } from "process";

interface Title {
  text: string;
  fontSize: number;
  fontColor: string;
  position: { x: number; y: number };
  padding: number;
  paddingColor: string;
  borderRadius: number;
  addPadding: boolean;
}

interface Image {
  url: string;
  position: { x: number; y: number };
  width: number;
  height: number;
}

interface ImageEditorProps {
  imgurl?: string;
  main_keyword?: string;
  preview?: boolean;
  aspect_ratio?: string;
  provider?: string;
  add_gradient?: boolean;
  add_template?: boolean;
  teamLogos: { team1Logo: string; team2Logo: string }; // Add this line
}

export interface ImageEditorHandle {
  createImageOnServer: () => void;
  generatePayload: () => void;
  returnPostContent:()=> void;
}

const ImageEditor = forwardRef<ImageEditorHandle, ImageEditorProps>(
  (props, ref) => {

    const {
      imgurl,
      main_keyword,
      preview,
      aspect_ratio,
      provider,
      add_gradient,
      add_template,
      teamLogos,
    } = props;

    const [titles, setTitles] = useState<Title[]>([]);
    const [mainImageUrl, setMainImageUrl] = useState(imgurl || "");
    const [images, setImages] = useState<Image[]>([
      {
        url: "",
        position: { x: 45, y: 16.67 },
        width: 16.67,
        height: 16.67,
      },
    ]);
    const [imageWidthSize, setImageWidthSize] = useState(0);
    const [imageHeightSize, setImageHeightSize] = useState(0);

    const [canvasWidth, setCanvasWidth] = useState(640);
    const [canvasHeight, setCanvasHeight] = useState(640);
    const [selectedAspectRatio, setSelectedAspectRatio] = useState("16:9");
    const [postContent, setPostContent] = useState("");

    const [mainImagePosition, setMainImagePosition] = useState({ x: 0, y: 0 });
    const [mainImageSize, setMainImageSize] = useState({
      width: 640,
      height: 640,
    });
    const [customImageSize, setCustomImageSize] = useState({
      width: 0,
      height: 0,
    });
    const [addTemplate, setAddTemplate] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [applyGradient, setApplyGradient] = useState(false); // New state for gradient effect

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<string>("");

    const ASPECT_RATIOS = {
      "1:1": {
        display: { width: 360, height: 360 },
        backend: { width: 1080, height: 1080 },
      },
      "16:9": {
        display: { width: 640, height: 360 },
        backend: { width: 1920, height: 1080 },
      },
      "9:16": {
        display: { width: 360, height: 640 },
        backend: { width: 1080, height: 1920 },
      },
      "4:3": {
        display: { width: 360, height: 270 },
        backend: { width: 1080, height: 810 },
      },
    } as any;

    useEffect(() => {
      if (aspect_ratio) {
        const { display } = ASPECT_RATIOS[aspect_ratio];
        setCanvasWidth(display.width);
        setCanvasHeight(display.height);
        setSelectedAspectRatio(aspect_ratio);
      }
    }, [aspect_ratio]);

    useEffect(() => {
      // debugger
      // if(provider =="dotalive" ) {
      // let { display } = ASPECT_RATIOS["16:9"];
      // setCanvasWidth(display.width);
      // setCanvasHeight(display.height);
      // setSelectedAspectRatio("16:9");
      // }
    });

    const TEMPLATE = {
      images: [
        {
          url: "https://www.soldmagazine.mk/wp-content/uploads/2024/07/Lightroom-Photoshop-Tutorials-Logo-1.png",
          position: { x: 560, y: 296.67 },
          width: 50,
          height: 40,
        },
        {
          url: "https://www.soldmagazine.mk/wp-content/uploads/2024/07/adobe-photoshop_logo-freelogovectors.net_.png",
          position: { x: 20, y: 290 },
          width: 50,
          height: 50,
        },
      ],
      titles: [
        {
          text: "Movie Poster Photoshop",
          fontSize: 35,
          fontColor: "#FFFFFF",
          position: { x: 320, y: 280 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ],
    };

    const growthoid_template = {
      images: [
        {
          url: "https://growthoid.com/wp-content/uploads/2023/05/g-logo-2048x371.png",
          position: { x: 135, y: 10 },
          width: 97,
          height: 16.7,
        },
      ],
      titles: [
        {
          text: "BUY YOUTUBE SUBSCRIBERS",
          fontSize: 40,
          fontColor: "#000000",
          position: { x: canvasWidth / 2, y: 110 },
          padding: 30,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        // {
        //   text: "SUBSCRIBERS",
        //   fontSize: 25,
        //   fontColor: "#000000",
        //   position: { x: 320, y: 280 },
        //   padding: 10,
        //   paddingColor: "#FFFFFF",
        //   borderRadius: 5,
        //   addPadding: false,
        // },
      ],
    };

    const formatDate = () => {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = today.getFullYear();
      return `${day}.${month}.${year}`;
    };
    const dotalive_template = {
      images: [
        {
          url: "https://dotalive.org/wp-content/uploads/2024/09/The_International_2024.png",
          position: { x: 250, y: 20 },
          width: 140,
          height: 114,
        },
        {
          url: teamLogos.team1Logo,
          position: { x: 105, y: 125 },
          width: 109.67,
          height: 102.67,
        },
        {
          url: teamLogos.team2Logo,
          position: { x: 420, y: 130 },
          width: 110,
          height: 107,
        },
      ],
      titles: [
        {
          text: "VS",
          fontSize: 84,
          fontColor: "#FFFFFF",
          position: {
            x: 320,
            y: 215,
          },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: formatDate(),
          fontSize: 17,
          fontColor: "#FFFFFF",
          position: {
            x: 320,
            y: 255,
          },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: "( 15:00 CET )",
          fontSize: 16,
          fontColor: "#FFFFFF",
          position: {
            x: 320,
            y: 275,
          },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ],
    };


    const dotalive_instagram_template = {
      images: [
        {
          url: "https://dotalive.org/wp-content/uploads/2024/10/pgl_logo.png",
          position: { x: 135, y: 16.67 },
          width: 90.67,
          height: 143.67,
        },
        {
          url: teamLogos.team1Logo,
          position: { x: 45, y: 200 },
          width: 69.67,
          height: 62.67,
        },
        {
          url: teamLogos.team2Logo,
          position: { x: 240, y: 200 },
          width: 78,
          height: 75,
        },
      ],
      titles: [
        {
          text: "VS",
          fontSize: 43,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 240 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: formatDate(),
          fontSize: 16,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 265 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: "( 15:00 CET )",
          fontSize: 13,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 280 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ],
      canvasSize: { width: 360, height: 360 },
      backgroundColor: "#000000",
      applyGradient: false,
      customSize: { width: 0, height: 0 },
      filename: "MOUZ_vs_DMS",
    };

    const dotalive_instagram_story_template = {
      images: [
        {
          url: "https://dotalive.org/wp-content/uploads/2024/10/pgl_logo.png",
          position: { x: 135, y: 116.67 },
          width: 90.67,
          height: 143.67,
        },
        {
          url: teamLogos.team1Logo,
          position: { x: 45, y: 300 },
          width: 69.67,
          height: 62.67,
        },
        {
          url: teamLogos.team2Logo,
          position: { x: 240, y: 300 },
          width: 78,
          height: 75,
        },
      ],
      titles: [
        {
          text: "VS",
          fontSize: 43,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 340 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: formatDate(),
          fontSize: 16,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 365 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
        {
          text: "( 15:00 CET )",
          fontSize: 13,
          fontColor: "#FFFFFF",
          position: { x: 180, y: 380 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ],
      canvasSize: { width: 360, height: 360 },
      backgroundColor: "#000000",
      applyGradient: false,
      customSize: { width: 0, height: 0 },
      filename: "MOUZ_vs_DMS",
    };

    const sidesmedia_template = {
      images: [
        {
          url: "https://sidesmedia.com/wp-content/uploads/2023/09/sides-media-logo-2-250px.png",
          position: { x: 115, y: 10 },
          width: 120,
          height: 36.7,
        },
      ],
      titles: [],
    };

    const tweSocial_template = {
      images: [
        {
          url: "https://twesocial.com/wp-content/uploads/2023/05/logo.png",
          position: { x: 135, y: 10 },
          width: 97,
          height: 16.7,
        },
      ],
      titles: [],
    };

    const SOLD_TEMPLATE = {
      images: [
        // {
        //   url: "https://www.soldmagazine.mk/wp-content/uploads/2024/07/Lightroom-Photoshop-Tutorials-Logo-1.png",
        //   position: { x: 560, y: 296.67 },
        //   width: 50,
        //   height: 40,
        // },
        // {
        //   url: "https://www.soldmagazine.mk/wp-content/uploads/2024/07/adobe-photoshop_logo-freelogovectors.net_.png",
        //   position: { x: 20, y: 290 },
        //   width: 50,
        //   height: 50,
        // },
      ],
      titles: [
        {
          text: "SOLD MAGAZINE",
          fontSize: 7,
          fontColor: "#FFFFFF",
          position: { x: canvasWidth / 2, y: 340 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ],
    };

    const SOLD_MAGAZINE_STORY_TEMPLATE = {
      mainImageUrl: "https://www.nicekicks.com/files/2024/07/IMG_9966.jpeg",
      mainImagePosition: { x: 30 / 3, y: 930 / 3 },
      mainImageSize: { width: 1026 / 3, height: 684 / 3 },
      images: [
        {
          url: "",
          position: { x: 135 / 3, y: 50.01 / 3 },
          width: 50.01 / 3,
          height: 50.01 / 3,
        },
      ],
      titles: [
        {
          text: "SOLD MAGAZINE",
          fontSize: 21 / 3,
          fontColor: "#000000",
          position: { x: 540 / 3, y: 270 / 3 },
          padding: 30 / 3,
          paddingColor: "#FFFFFF",
          borderRadius: 15 / 3,
          addPadding: false,
        },
        {
          text: "Your story text",
          fontSize: 123 / 3,
          fontColor: "#020202",
          position: { x: 540 / 3, y: 690 / 3 },
          padding: 45 / 3,
          paddingColor: "#FFFFFF",
          borderRadius: 15 / 3,
          addPadding: false,
        },
      ],
      canvasSize: { width: 1080 / 3, height: 1920 / 3 },
      backgroundColor: "#ffffff",
      applyGradient: false,
    };
    const handleTemplateChange = (templateName: string) => {
      setSelectedTemplate(templateName);
      setImages([]); // Clear existing images
      setTitles([]); // Clear existing titles

      switch (templateName) {
        case "growthoid":
          setImages((prevImages) => [
            ...prevImages,
            ...growthoid_template.images,
          ]);
          break;
        case "sidesmedia":
          setImages((prevImages) => [
            ...prevImages,
            ...sidesmedia_template.images,
          ]);
          break;
        case "tweSocial":
          setImages((prevImages) => [
            ...prevImages,
            ...tweSocial_template.images,
          ]);
          break;
        case "dotalive":
          {
            setImages((prevImages) => [
              ...prevImages,
              ...dotalive_template.images,
            ]);
            setTitles((prevTitles) => [
              ...prevTitles,
              ...dotalive_template.titles,
            ]);
          }
          break;
        case "dotalive_instagram":
          {
            setImages((prevImages) => [
              ...prevImages,
              ...dotalive_instagram_template.images,
            ]);
            setTitles((prevTitles) => [
              ...prevTitles,
              ...dotalive_instagram_template.titles,
            ]);
          }
          break;
          case "dotalive_instagram_story":
            {
              setImages((prevImages) => [
                ...prevImages,
                ...dotalive_instagram_story_template.images,
              ]);
              setTitles((prevTitles) => [
                ...prevTitles,
                ...dotalive_instagram_story_template.titles,
              ]);
            }
            break;
          
        default:
          break;
      }
    };

    const handleAddTitle = () => {
      setTitles((prevTitles) => [...prevTitles, ...growthoid_template.titles]);
    };
    const drawCanvas = (ctx: CanvasRenderingContext2D, scale: number) => {
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvasWidth * scale, canvasHeight * scale);

      const image = new Image();
      image.src = mainImageUrl;

      image.onload = () => {
        // Calculate the aspect ratio of the main image
        const aspectRatio = image.width / image.height;
        let drawWidth, drawHeight;

        // Adjust the width and height based on the aspect ratio and current zoom level
        if (aspectRatio > 1) {
          drawWidth = mainImageSize.width * scale;
          drawHeight = drawWidth / aspectRatio;
        } else {
          drawHeight = mainImageSize.height * scale;
          drawWidth = drawHeight * aspectRatio;
        }

        const drawX = mainImagePosition.x * scale;
        const drawY = mainImagePosition.y * scale;

        // Draw the main image
        ctx.drawImage(image, drawX, drawY, drawWidth, drawHeight);

        // Draw the black gradient from bottom of the image to 100 pixels above
        if (add_gradient) {
          const bottomGradient = ctx.createLinearGradient(
            0,
            canvasHeight * scale,
            0,
            canvasHeight * scale - 200 * scale
          );
          bottomGradient.addColorStop(0, "rgba(0, 0, 0, 1)");
          bottomGradient.addColorStop(1, "rgba(0, 0, 0, 0)");

          ctx.fillStyle = bottomGradient;
          ctx.fillRect(
            0,
            canvasHeight * scale - 200 * scale,
            canvasWidth * scale,
            200 * scale
          );
          setApplyGradient(true);
        }

        images.forEach((image) => {
          const img = new Image();
          img.src = image.url;
          img.onload = () => {
            ctx.drawImage(
              img,
              image.position.x * scale,
              image.position.y * scale,
              image.width * scale,
              image.height * scale
            );
          };
        });

        titles.forEach((title) => {
          ctx.fillStyle = title.paddingColor;
          ctx.textAlign = "center";
          ctx.font = `${
            title.fontSize * scale
          }px 'CustomFont', Arial, sans-serif`;

          const words = title.text.split(" ");
          const lines: string[] = [];
          let currentLine = words[0];

          for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = ctx.measureText(currentLine + " " + word).width;
            if (width < canvasWidth * scale - 2 * title.padding * scale) {
              currentLine += " " + word;
            } else {
              lines.push(currentLine);
              currentLine = word;
            }
          }
          lines.push(currentLine);

          const textHeight = title.fontSize * scale;
          const totalTextHeight = textHeight * lines.length;

          if (title.addPadding) {
            const rectX =
              title.position.x * scale -
              (canvasWidth / 2) * scale -
              title.padding * scale;
            const rectY =
              title.position.y * scale -
              totalTextHeight / 2 -
              title.padding * scale;
            const rectWidth = canvasWidth * scale;
            const rectHeight = totalTextHeight + 2 * title.padding * scale;

            ctx.fillStyle = title.paddingColor;
            ctx.beginPath();
            ctx.moveTo(rectX + title.borderRadius * scale, rectY);
            ctx.lineTo(rectX + rectWidth - title.borderRadius * scale, rectY);
            ctx.quadraticCurveTo(
              rectX + rectWidth,
              rectY,
              rectX + rectWidth,
              rectY + title.borderRadius * scale
            );
            ctx.lineTo(
              rectX + rectWidth,
              rectY + rectHeight - title.borderRadius * scale
            );
            ctx.quadraticCurveTo(
              rectX + rectWidth,
              rectY + rectHeight,
              rectX + rectWidth - title.borderRadius * scale,
              rectY + rectHeight
            );
            ctx.lineTo(rectX + title.borderRadius * scale, rectY + rectHeight);
            ctx.quadraticCurveTo(
              rectX,
              rectY + rectHeight,
              rectX,
              rectY + rectHeight - title.borderRadius * scale
            );
            ctx.lineTo(rectX, rectY + title.borderRadius * scale);
            ctx.quadraticCurveTo(
              rectX,
              rectY,
              rectX + title.borderRadius * scale,
              rectY
            );
            ctx.closePath();
            ctx.fill();
          }

          ctx.fillStyle = title.fontColor;
          lines.forEach((line, index) => {
            ctx.fillText(
              line,
              title.position.x * scale,
              title.position.y * scale +
                (index - lines.length / 2 + 0.5) * textHeight
            );
          });
        });
      };

      if (add_template && !addTemplate) {
        setAddTemplate(true);
      }
    };

    useEffect(() => {
      if (imgurl) {
        setMainImageUrl(imgurl);
      }
    }, [imgurl]);

    useEffect(() => {
      if (addTemplate) {
        if (provider === "soldmagazine_story") {
          setMainImageUrl(mainImageUrl);
          setMainImagePosition(SOLD_MAGAZINE_STORY_TEMPLATE.mainImagePosition);
          setMainImageSize(SOLD_MAGAZINE_STORY_TEMPLATE.mainImageSize);
          setCanvasWidth(SOLD_MAGAZINE_STORY_TEMPLATE.canvasSize.width);
          setCanvasHeight(SOLD_MAGAZINE_STORY_TEMPLATE.canvasSize.height);
          setBackgroundColor(SOLD_MAGAZINE_STORY_TEMPLATE.backgroundColor);
          setApplyGradient(SOLD_MAGAZINE_STORY_TEMPLATE.applyGradient);
          setTitles((prevTitles) => [
            ...prevTitles,
            ...SOLD_MAGAZINE_STORY_TEMPLATE.titles,
          ]);
          setImages((prevImages) => [
            ...prevImages,
            ...SOLD_MAGAZINE_STORY_TEMPLATE.images,
          ]);
        } else if (provider === "soldmagazine") {
          setTitles((prevTitles) => [...prevTitles, ...SOLD_TEMPLATE.titles]);
          setImages((prevImages) => [...prevImages, ...SOLD_TEMPLATE.images]);
        } else if (provider === "growthoid") {
          // setTitles((prevTitles) => [...prevTitles, ...growthoid_template.titles]);
          // setImages((prevImages) => [...prevImages, ...growthoid_template.images]);
        } else {
          setTitles((prevTitles) => [...prevTitles, ...TEMPLATE.titles]);
          setImages((prevImages) => [...prevImages, ...TEMPLATE.images]);
        }
      }
    }, [addTemplate, provider]);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext("2d");
        if (ctx) {
          document.fonts.ready.then(() => {
            drawCanvas(ctx, 1);
          });
        }
      }
    }, [
      titles,
      images,
      mainImageUrl,
      mainImagePosition,
      mainImageSize,
      canvasWidth,
      canvasHeight,
      backgroundColor,
      applyGradient,
    ]);

    const handleTitleChange = (
      index: number,
      field: keyof Title,
      value: any
    ) => {
      const newTitles = [...titles];
      newTitles[index] = { ...newTitles[index], [field]: value };
      setTitles(newTitles);
    };

    const handleImageChange = (
      index: number,
      field: keyof Image,
      value: any
    ) => {
      const newImages = [...images];
      newImages[index] = { ...newImages[index], [field]: value };
      setImages(newImages);
    };

    const handleMainImageUrlChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setMainImageUrl(event.target.value);
    };

    const handleMainImageUpload = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setMainImageUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleImageUpload = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          handleImageChange(index, "url", reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleCanvasWidthChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setCanvasWidth(Number(event.target.value));
    };

    const handleCanvasHeightChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setCanvasHeight(Number(event.target.value));
    };

    const handleAspectRatioChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const aspectRatio = event.target.value;
      setSelectedAspectRatio(aspectRatio);

      setCanvasWidth(ASPECT_RATIOS[aspectRatio].display.width);
      setCanvasHeight(ASPECT_RATIOS[aspectRatio].display.height);
    };

    const handleAddTemplateChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setAddTemplate(event.target.checked);
    };

    const handleBackgroundColorChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setBackgroundColor(event.target.value);
    };

    const handleApplyGradientChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setApplyGradient(event.target.checked);
    };

    const addTitle = () => {
      setTitles([
        ...titles,
        {
          text: "New Title",
          fontSize: 5,
          fontColor: "#FFFFFF",
          position: { x: canvasWidth / 2, y: canvasHeight - 200 },
          padding: 10,
          paddingColor: "#FFFFFF",
          borderRadius: 5,
          addPadding: false,
        },
      ]);
    };

    const addImage = () => {
      setImages([
        ...images,
        {
          url: "",
          position: { x: canvasWidth / 2, y: canvasHeight / 2 },
          width: 16.67,
          height: 16.67,
        },
      ]);
    };

    const removeTitle = (index: number) => {
      const newTitles = titles.filter((_, i) => i !== index);
      setTitles(newTitles);
    };

    const removeImage = (index: number) => {
      const newImages = images.filter((_, i) => i !== index);
      setImages(newImages);
    };

    const moveTitle = (index: number, direction: string) => {
      const newTitles = [...titles];
      switch (direction) {
        case "up":
          newTitles[index].position.y -= 5;
          break;
        case "down":
          newTitles[index].position.y += 5;
          break;
        case "left":
          newTitles[index].position.x -= 5;
          break;
        case "right":
          newTitles[index].position.x += 5;
          break;
        default:
          break;
      }
      setTitles(newTitles);
    };

    const moveImage = (index: number, direction: string) => {
      const newImages = [...images];
      switch (direction) {
        case "up":
          newImages[index].position.y -= 5;
          break;
        case "down":
          newImages[index].position.y += 5;
          break;
        case "left":
          newImages[index].position.x -= 5;
          break;
        case "right":
          newImages[index].position.x += 5;
          break;
        default:
          break;
      }
      setImages(newImages);
    };

    const moveMainImage = (direction: string) => {
      const newPosition = { ...mainImagePosition };
      switch (direction) {
        case "up":
          newPosition.y -= 5;
          break;
        case "down":
          newPosition.y += 5;
          break;
        case "left":
          newPosition.x -= 5;
          break;
        case "right":
          newPosition.x += 5;
          break;
        default:
          break;
      }
      setMainImagePosition(newPosition);
    };

    const zoomImage = (index: number, zoomIn: boolean) => {
      const newImages = [...images];
      const scaleFactor = zoomIn ? 1.1 : 0.9;
      newImages[index].width *= scaleFactor;
      newImages[index].height *= scaleFactor;
      setImages(newImages);
    };

    const zoomMainImage = (zoomIn: boolean) => {
      const scaleFactor = zoomIn ? 1.05 : 0.95;
      const newSize = {
        width: mainImageSize.width * scaleFactor,
        height: mainImageSize.height * scaleFactor,
      };
      setMainImageSize(newSize);
    };

    const readjustImageSize = () => {
      const image = new Image();
      image.src = mainImageUrl;
      image.onload = () => {
        const aspectRatio = image.width / image.height;
        let newWidth, newHeight;
        if (aspectRatio > 1) {
          newWidth = canvasWidth;
          newHeight = canvasWidth / aspectRatio;
        } else {
          newHeight = canvasHeight;
          newWidth = canvasHeight * aspectRatio;
        }
        setMainImageSize({ width: newWidth, height: newHeight });
      };
    };

    const saveCanvas = () => {
      const canvas = document.createElement("canvas");
      const { width, height } = ASPECT_RATIOS[selectedAspectRatio].backend;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        document.fonts.ready.then(() => {
          drawCanvas(ctx, 3); // Scale by 3 to get higher resolution
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "canvas.png";
          link.click();
        });
      }
    };

    const createImageOnServer = async () => {
      const { width, height } = ASPECT_RATIOS[selectedAspectRatio].backend;

      console.log("images---", images);
      console.log("titles---", titles);

      const payload = {
        mainImageUrl,
        mainImagePosition: {
          x: mainImagePosition.x * 3, // Assuming 3x scaling factor for backend canvas
          y: mainImagePosition.y * 3,
        },
        mainImageSize: {
          width: mainImageSize.width * 3,
          height: mainImageSize.height * 3,
        },
        images: images.map((image) => ({
          url: image.url,
          position: { x: image.position.x * 3, y: image.position.y * 3 },
          width: image.width * 3,
          height: image.height * 3,
        })),
        titles: titles.map((title) => ({
          text: title.text,
          fontSize: title.fontSize * 3,
          fontColor: title.fontColor,
          position: { x: title.position.x * 3, y: title.position.y * 3 },
          padding: title.padding * 3,
          paddingColor: title.paddingColor,
          borderRadius: title.borderRadius * 3,
          addPadding: title.addPadding,
        })),
        canvasSize: { width, height }, // The backend canvas size
        backgroundColor,
        applyGradient,
        customSize: customImageSize,
        filename: String(main_keyword).replaceAll(" ", "_"),
      };

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));

      // Check if the mainImageUrl is a base64 string
      if (mainImageUrl.startsWith("data:image")) {
        const blob = await (await fetch(mainImageUrl)).blob();
        formData.append("uploaded_img", blob, "uploaded_main_image.png");
      }
      setLoadingImage(true);
      const response = await fetch(
        "https://servercode.wecon-digital.agency/create-image",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        let date =
          provider === "dotalive"
            ? titles[2].text.length > 0
              ? "-" + titles[2].text
              : ""
            : "";
        a.download = String(main_keyword).replaceAll(" ", "_") + date + ".webp";
        document.body.appendChild(a);
        setLoadingImage(false);

        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setLoadingImage(false);
        console.error("Failed to create image on server");
      }
    };

    useImperativeHandle(ref, () => ({
      createImageOnServer,
      generatePayload,
      returnPostContent,
    }));

    const returnPostContent = ()=>{
      debugger
      return postContent
    }
    const generatePayload = () => {
      const { width, height } = ASPECT_RATIOS[aspect_ratio || "16:9"].backend;

      return {
        mainImageUrl,
        mainImagePosition: {
          x: mainImagePosition.x * 3, // Assuming 3x scaling factor for backend canvas
          y: mainImagePosition.y * 3,
        },
        mainImageSize: {
          width: mainImageSize.width * 3,
          height: mainImageSize.height * 3,
        },
        images: images.map((image) => ({
          url: image.url,
          position: { x: image.position.x * 3, y: image.position.y * 3 },
          width: image.width * 3,
          height: image.height * 3,
        })),
        titles: titles.map((title) => ({
          text: title.text,
          fontSize: title.fontSize * 3,
          fontColor: title.fontColor,
          position: { x: title.position.x * 3, y: title.position.y * 3 },
          padding: title.padding * 3,
          paddingColor: title.paddingColor,
          borderRadius: title.borderRadius * 3,
          addPadding: title.addPadding,
        })),
        canvasSize: { width, height }, // The backend canvas size
        backgroundColor,
        applyGradient,
        customSize: customImageSize,
        filename: String(main_keyword).replaceAll(" ", "_"),
      };
    };
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            paddingRight: "1rem",
          }}
        >
          <canvas
            style={{ border: "2px solid black" }}
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <IconButton onClick={() => moveMainImage("left")}>
              <ArrowBack />
            </IconButton>
            <IconButton onClick={() => moveMainImage("right")}>
              <ArrowForward />
            </IconButton>
            <IconButton onClick={() => moveMainImage("up")}>
              <ArrowUpward />
            </IconButton>
            <IconButton onClick={() => moveMainImage("down")}>
              <ArrowDownward />
            </IconButton>
            <IconButton onClick={() => zoomMainImage(true)}>
              <ZoomIn />
            </IconButton>
            <IconButton onClick={() => zoomMainImage(false)}>
              <ZoomOut />
            </IconButton>
            <IconButton onClick={readjustImageSize}>
              <Refresh />
            </IconButton>
          </div>
          {provider === "twesocial" ? <></>:<></>}
         
         <div hidden={provider ==="twesocial"}>
         {provider === "dotalive_instagram"  ? (
            <>
              <div style={{display:'flex', flexDirection:'column', textAlign:'start'}}>
              <h3 style={{padding:'0px'}}>Post description</h3>
              <TextField value={postContent} onChange={(e:any)=>{
                debugger
                setPostContent(e.currentTarget.value)
              }} multiline={true} rows={4} style={{ minWidth: "100%" }}></TextField> </div>
            </>
          ) : (
            <></>
          )}
         {provider === "growthoid" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                  justifySelf: "center",
                }}
              >
                <h2>Custom Dimensions</h2>
                <div style={{ maxWidth: "10rem", display: "flex" }}>
                  <TextField
                    label="Dimenzion"
                    type="number"
                    value={customImageSize.width}
                    onChange={(e) => {
                      
                      // handleTitleChange(
                      //   index,
                      //   "fontSize",
                      //   Number(e.target.value)
                      // )

                      const newSize = {
                        width: Number(e.target.value),
                        height: Number(e.target.value),
                      };
                      setCustomImageSize(newSize);
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />
                  <span style={{ margin: "0.3rem" }}>X</span>
                  <TextField
                    label="Dimenzion"
                    type="number"
                    value={customImageSize.height}
                    onChange={(e) => {
                      // handleTitleChange(
                      //   index,
                      //   "fontSize",
                      //   Number(e.target.value)
                      // )
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "10px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
         </div>
        </div>
        <Paper style={{ padding: "20px" }}>
          <input
            accept="image/*"
            type="file"
            onChange={handleMainImageUpload}
            style={{ marginBottom: "20px" }}
          />
          <div hidden={provider==="twesocial"}>
          {provider === "dotalive" ? (
            <></>
          ) : (
            <>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={addTemplate}
                    onChange={handleAddTemplateChange}
                    color="primary"
                  />
                }
                label="Add Template"
                style={{ marginBottom: "20px" }}
              /> */}
            </>
          )}
          {provider === "growthoid" ||
          provider === "sidesmedia" ||
          provider === "twesocial" ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTemplate === "growthoid"}
                    onChange={() => handleTemplateChange("growthoid")}
                    color="primary"
                  />
                }
                label="Add Growthoid Template"
                style={{ marginBottom: "20px" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTemplate === "sidesmedia"}
                    onChange={() => handleTemplateChange("sidesmedia")}
                    color="primary"
                  />
                }
                label="Add SidesMedia Template"
                style={{ marginBottom: "20px" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTemplate === "tweSocial"}
                    onChange={() => handleTemplateChange("tweSocial")}
                    color="primary"
                  />
                }
                label="Add TweSocial Template"
                style={{ marginBottom: "20px" }}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddTitle}
              >
                Add title{" "}
              </Button>
            </>
          ) : (
            <></>
          )}
          {provider === "dotalive_instagram"  ? (
            <>
              {" "}
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addTemplate}
                      onChange={() =>
                        handleTemplateChange("dotalive_instagram")
                      }
                      color="primary"
                    />
                  }
                  label="Add Teams"
                  style={{ marginBottom: "20px" }}
                />
              </>
            </>
          ) : (
            <></>
          )}
          {provider === "dotalive_instagram_story"  ? (
            <>
              {" "}
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addTemplate}
                      onChange={() =>
                        handleTemplateChange("dotalive_instagram_story")
                      }
                      color="primary"
                    />
                  }
                  label="Add Teams"
                  style={{ marginBottom: "20px" }}
                />
              </>
            </>
          ) : (
            <></>
          )}
          {provider === "dotalive" ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addTemplate}
                    onChange={() => handleTemplateChange("dotalive")}
                    color="primary"
                  />
                }
                label="Add Teams"
                style={{ marginBottom: "20px" }}
              />
            </>
          ) : (
            <>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={addTemplate}
                    onChange={handleAddTemplateChange}
                    color="primary"
                  />
                }
                label="Add Story Template"
                style={{ marginBottom: "20px" }}
              /> */}
              {/* <HexColorPicker
                color={backgroundColor}
                onChange={(color) => setBackgroundColor(color)}
              /> */}
              {/* <div>
                <Checkbox
                  checked={applyGradient}
                  onChange={handleApplyGradientChange}
                  color="primary"
                />
                Add Gradient
              </div> */}
            </>
          )}

          <div
            className="image_settings"
            style={{ display: provider === "dotalive" ? "flex" : "flex" }}
          >
            <div className="title_list">
              {titles.map((title, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h6">
                      {" "}
                      {"Text (" + title.text.substring(0, 15) + "...)"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      label="Text"
                      value={title.text}
                      onChange={(e) =>
                        handleTitleChange(index, "text", e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <TextField
                      label="Font Size"
                      type="number"
                      value={title.fontSize}
                      onChange={(e) =>
                        handleTitleChange(
                          index,
                          "fontSize",
                          Number(e.target.value)
                        )
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <HexColorPicker
                      color={title.fontColor}
                      onChange={(color) =>
                        handleTitleChange(index, "fontColor", color)
                      }
                      style={{ marginBottom: "1rem" }}
                    />
                    <TextField
                      label="Font Color"
                      value={title.fontColor}
                      onChange={(e) =>
                        handleTitleChange(index, "fontColor", e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={title.addPadding}
                          onChange={(e) =>
                            handleTitleChange(
                              index,
                              "addPadding",
                              e.target.checked
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Add Padding"
                      style={{ marginBottom: "10px" }}
                    />
                    {title.addPadding && (
                      <>
                        <TextField
                          label="Padding"
                          type="number"
                          value={title.padding}
                          onChange={(e) =>
                            handleTitleChange(
                              index,
                              "padding",
                              Number(e.target.value)
                            )
                          }
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "10px" }}
                        />
                        <TextField
                          label="Padding Color"
                          value={title.paddingColor}
                          onChange={(e) =>
                            handleTitleChange(
                              index,
                              "paddingColor",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "10px" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HexColorPicker
                                  color={title.paddingColor}
                                  onChange={(color) =>
                                    handleTitleChange(
                                      index,
                                      "paddingColor",
                                      color
                                    )
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Border Radius"
                          type="number"
                          value={title.borderRadius}
                          onChange={(e) =>
                            handleTitleChange(
                              index,
                              "borderRadius",
                              Number(e.target.value)
                            )
                          }
                          variant="outlined"
                          fullWidth
                          style={{ marginBottom: "10px" }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <IconButton onClick={() => moveTitle(index, "up")}>
                          <ArrowUpward />
                        </IconButton>
                        <IconButton onClick={() => moveTitle(index, "left")}>
                          <ArrowBack />
                        </IconButton>
                        <IconButton onClick={() => moveTitle(index, "right")}>
                          <ArrowForward />
                        </IconButton>
                        <IconButton onClick={() => moveTitle(index, "down")}>
                          <ArrowDownward />
                        </IconButton>
                      </div>
                      <IconButton onClick={() => removeTitle(index)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={addTitle}
                style={{ marginTop: "20px" }}
              >
                Add Title
              </Button>
            </div>
            <div className="image_list">
              {images.map((image, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="h6">Image {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      label="Image URL"
                      value={image.url}
                      onChange={(e) =>
                        handleImageChange(index, "url", e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <input
                      accept="image/*"
                      type="file"
                      onChange={(e) => handleImageUpload(index, e)}
                      style={{ marginBottom: "10px" }}
                    />
                    <TextField
                      label="Width"
                      type="number"
                      value={image.width}
                      onChange={(e) =>
                        handleImageChange(
                          index,
                          "width",
                          Number(e.target.value)
                        )
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <TextField
                      label="Height"
                      type="number"
                      value={image.height}
                      onChange={(e) =>
                        handleImageChange(
                          index,
                          "height",
                          Number(e.target.value)
                        )
                      }
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "10px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <IconButton onClick={() => moveImage(index, "up")}>
                          <ArrowUpward />
                        </IconButton>
                        <IconButton onClick={() => moveImage(index, "left")}>
                          <ArrowBack />
                        </IconButton>
                        <IconButton onClick={() => moveImage(index, "right")}>
                          <ArrowForward />
                        </IconButton>
                        <IconButton onClick={() => moveImage(index, "down")}>
                          <ArrowDownward />
                        </IconButton>
                        <IconButton onClick={() => zoomImage(index, true)}>
                          <ZoomIn />
                        </IconButton>
                        <IconButton onClick={() => zoomImage(index, false)}>
                          <ZoomOut />
                        </IconButton>
                      </div>
                      <IconButton onClick={() => removeImage(index)}>
                        <Delete />
                      </IconButton>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={addImage}
                style={{ marginTop: "20px" }}
              >
                Add Image
              </Button>
            </div>
          </div>

          <Button
            variant="contained"
            color="secondary"
            onClick={saveCanvas}
            style={{
              marginTop: "20px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            Save Canvas
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={createImageOnServer}
            style={{ marginTop: "20px" }}
          >
            {loadingImage ? <>Loading...</> : <>Create Image</>}
          </Button>
          </div>
        </Paper>
      </div>
    );
  }
);

export default ImageEditor;
