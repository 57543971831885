import enTranslations from './i18/en.json';
import mkTranslations from './i18/mk.json';

type Language = 'en' | 'mk';

interface Translations {
  [key: string]: string | Translations;
}

const getDefaultLanguage = (): Language => {
  return (localStorage.getItem('language') as Language) || 'en';
};

const setDefaultLanguage = (language: Language): void => {
  localStorage.setItem('language', language);
};

const getTranslation = (key: string): string => {
  const defaultLanguage = getDefaultLanguage();
  const translations: Translations =
    defaultLanguage === 'en' ? enTranslations : mkTranslations;

  const keys = key.split('.');
  let translation: string | Translations = translations;

  for (const currentKey of keys) {
    if (typeof translation === 'string') {
      return key; // Return the key itself if translation is not found
    }

    translation = translation[currentKey];

    if (typeof translation === 'undefined') {
      return key; // Return the key itself if translation is not found
    }
  }

  return translation as string;
};

export { getDefaultLanguage, setDefaultLanguage, getTranslation };
