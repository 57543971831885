import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";

import { setDefaultLanguage, getTranslation } from "./../../translation";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import Alert from "@mui/material/Alert/Alert";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import { CompanyInfo, Guideline, SuperAdminBlog } from "./../../types.tsx";
import SettingsIcon from "@mui/icons-material/Settings";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LogoutIcon from "@mui/icons-material/Logout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-calendar/dist/Calendar.css';
import "../../styles/Calendar.css";
import YouTube from "react-youtube";
import "../../styles/Reservation.css";
import "../../styles/AdminWelcome.css";
import LogoImg from "./../../img/wecon-mail.png"; // Adjust the path accordingly
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CompanyView from "./CompanyView";
import CompanyViewAll from "./CompanyViewAll.tsx"
import CompanyGuidelines from "./CompanyGuidelines";
import CompanySettings from "./CompanySettings";
// import Checkbox from 'material-ui/Checkbox';

interface Company {
  company_name: string;
  company_uuid: string;
}

interface User {
  user_uuid: string;
  name: string;
  email: string;
  roles: string[];
  password: string;
}

interface UserLite {
  user_uuid: string;
  name: string;
  verified: boolean;
  password: string;
  email: string;
  roles: string[];
}

interface UserInfoShort {
  name: string;
  user_uuid: string;
  verified: boolean;
  roles: string[];
  email: string;
}

interface BlogDataResponse {
  user_info: UserInfoShort;
  blogs: SuperAdminBlog[];
  editor_blogs: SuperAdminBlog[];
}

interface UserDetails {
  email: string;
  name: string;
  roles: string;
  token: string;
  uuid: string;
}

interface AdminWelcomeProps {
  userDetails: UserDetails | null;
}

interface CompanyData {
  company_name: string;
  company_uuid: string;
  company_projects: string[];
  current_project_blogs: SuperAdminBlog[];
}

const AdminWelcome: React.FC<AdminWelcomeProps> = ({ userDetails }) => {
  const handleChangeCompany = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`company_name`]: event.target.value,
    }));
  };

  const [showGuidelineEditor, setShowGuidelineEditor] = useState<string | null>(
    null
  );
  const [selectedGuidelines, setSelectedGuidelines] = useState<string[]>([]);

  const handleEyeIconClick = (guidelineId: string) => {
    setShowGuidelineEditor((prevId) =>
      prevId === guidelineId ? null : guidelineId
    );
  };

  const handleCheckboxChange = (guidelineUuid: string) => {
    setSelectedGuidelines((prevSelected) =>
      prevSelected.includes(guidelineUuid)
        ? prevSelected.filter((uuid) => uuid !== guidelineUuid)
        : [...prevSelected, guidelineUuid]
    );
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleChangeEditor = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`editor_name`]: event.target.value,
    }));
  };
  const handleChangeStatus = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`status`]: event.target.value,
    }));
  };
  const handleChange = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`writer`]: event.target.value,
    }));
  };

  const [editorContent, setEditorContent] = useState("");

  const handleContentChange = (content: any) => {
    setEditorContent(content);
  };

  const handleSubmit = () => {
    // Here you would typically send the content to your server
    console.log(editorContent);
  };

  const navigate = useNavigate();
  const [getCompanyName, setGetCompanyName] = useState<String>("");
  const [newCompany, setNewCompany] = useState<CompanyInfo>({
    email: "",
    name: "",
    password: "",
  });
  const [currentUser, setUser] = useState<UserLite>({
    email: "",
    name: "",
    user_uuid: "",
    verified: false,
    password: "",
    roles: [],
  });

  const [selectedOptions, setSelectedOptions] = useState([] as any);

  const MultipleSelectDropdown = () => {
    const options = ["Writer", "Editor"]; // Replace with your options

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(selectedValues);

      setUser((prevUser) => ({
        ...prevUser,
        roles: selectedValues,
      }));
    };

    return (
      <FormControl sx={{ width: 300 }}>
        <InputLabel id="multiple-select-label">Roles</InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="Options" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const SelectTools = () => {
    const options = ["Grammarly", "Positional AI", "Copyscape", "Copyleaks"]; // Replace with your options

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      const selectedValues =
        typeof value === "string" ? value.split(",") : value;
      setSelectedOptions(selectedValues);

      setUser((prevUser) => ({
        ...prevUser,
        roles: selectedValues,
      }));
    };

    return (
      <FormControl style={{ width: "100%", padding: "0rem 1rem" }}>
        <InputLabel id="multiple-select-label">Tools</InputLabel>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label="Tools" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const [newBlog, setNewBlog] = useState<Boolean>(false);

  const createNewCompany = async () => {
    try {
      StartLoading();
      await fetch("https://servercode.wecon-digital.agency/api/company/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCompany),
      });
      StopLoading();
      setCreateCompany(!createNewCompany);
      // tuka
      setNewCompany({
        email: "",
        name: "",
        password: "",
      });
      setGetCompanyName("");
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  const createNewWriter = async () => {
    try {
      StartLoading();
      console.log(currentUser);
      await axios.post(
        `https://servercode.wecon-digital.agency/api/writer/create/`,
        currentUser, // Assuming `user` is the object that matches your User interface
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setCreateWriter(!createWriter);
      setGetCompanyName("");
      fetchUsers();
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  const renderCompanyView = () => {
    ("");
    switch (currentView) {
      case "home":
        return (
          <CompanyView all_users={all_users} company={currentCompanyData} allCompanies={currentCompanyUuid =="all" ? true :false}/>
        );
        case "all":
          return (
            <CompanyViewAll
            login={true}
            all_users={all_users}
            company={currentCompanyData}
          />
          );
      case "guidelines":
        return (
          <CompanyGuidelines
            all_users={all_users}
            adminGuidelines={false}
            company={currentCompanyData}
          />
        );
      case "settings":
        return (
          <CompanySettings
            saveChanges={() => {
              fetchCompanies();
            }}
            all_users={all_users}
            company={currentCompanyData}
          />
        );

      default:
        return null; // Return null if no case matches
    }
  };

  const updateUser = async () => {
    try {
      StartLoading();
      console.log(currentUser);
      await axios.post(
        `https://servercode.wecon-digital.agency/api/writer/update/`,
        currentUser, // Assuming `user` is the object that matches your User interface
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setCreateWriter(!createWriter);
      setEditUser(false);
      setGetCompanyName("");
      setSelectedWriterUuid(currentUser.user_uuid);
      getWriterBlogs(currentUser.user_uuid);
      fetchUsers();
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };
  const [showAdditionalGuidelines, setShowAdditionalGuidelines] = useState(
    false
  );
  const [guidelines, setGuidelines] = useState([]);

  const fetchGuidelines = async () => {
    // edit here
    if (true) {
      const requestBody = {
        // company_uuid: company.company_uuid,
      };

      try {
        const response = await fetch(
          "https://servercode.wecon-digital.agency/api/company/guidelines",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setGuidelines(data);

        // setSelectedProject(data[selectedGuideline]);
        // Handle the data as needed
        console.log(data);
      } catch (error) {
        console.error("Error fetching company guidelines:", error);
      }
    }
  };
  const createNewBlog = async () => {
    try {
      StartLoading();
      currentBlog.company_uuid = currentBlog.company_name;
      currentBlog.writer_uuid = currentBlog.writer;
      currentBlog.editor_uuid = currentBlog.editor_name;
      currentBlog.company_name = companies.filter(
        (company) => company.company_uuid === currentBlog.company_uuid
      )[0].company_name;
      // currentBlog.editor_name = all_users.filter(
      //   (user :UserLite) => user.user_uuid === currentBlog.editor_uuid
      // )[0].name;
      currentBlog.writer = all_users.filter(
        (user: UserLite) => user.user_uuid === currentBlog.writer_uuid
      )[0].name;
      currentBlog.status.length > 0
        ? (currentBlog.status = currentBlog.status)
        : (currentBlog.status = "Pending");
      await axios.post(`https://servercode.wecon-digital.agency/api/blog/create`, currentBlog, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      StopLoading();
      // handleOpenSuccessfull()
      // clearSelectedService()
      // refreshServices()
    } catch (error) {
      StopLoading();
      // setError(error.message);
      // handleOpenFailed()
    }
  };

  const [selectedUserId, setSelectedUserId] = useState<Number>(9999);
  const [selectedCompany, setSelectedCompany] = useState<Number>(-1);
  const [currentCompanyUuid, setCurrentCompanyUuid] = useState<String>("");
  const [selectedWriterUuid, setSelectedWriterUuid] = useState<String>("");
  const [currentView, setCurrenteView] = useState<String>("home");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [all_users, setAllUsers] = useState<UserLite[]>([]);
  const [contact, setContact] = useState(false); // Loading state
  const [createCompany, setCreateCompany] = useState(false); // Loading state
  const [createWriter, setCreateWriter] = useState(false); // Loading state
  const [editUser, setEditUser] = useState(false); // Loading state
  const [showCompanyGuidelines, setShowCompanyGuidelines] = useState(false);
  const [generalGuidelines, setGeneralGuidelines] = useState(false);
  const [createEditor, setCreateEditor] = useState(false); // Loading state

  function getRender(status: string): React.ReactNode {
    let backgroundColor = "lightgreen";
    let color = "white";

    switch (status) {
      case "Pending": {
        backgroundColor = "lightgray";
        color = "black";
        break;
      }
      case "In Progress": {
        backgroundColor = "blue";
        break;
      }
      case "Written": {
        backgroundColor = "lightgreen";
        color = "black";
        break;
      }
      case "Editor": {
        backgroundColor = "orange";
        break;
      }
      case "Completed": {
        backgroundColor = "darkgreen";
        break;
      }
      case "Revision": {
        backgroundColor = "purple";
        break;
      }
      case "Paid": {
        backgroundColor = "#2DCCFF";
        break;
      }
      case "Failed": {
        backgroundColor = "red";
        break;
      }
    }
    return (
      <>
        <div
          style={{
            padding: "0.2rem 0.5rem",
            // border: "1px solid gray",
            borderRadius: "50px",
            fontSize: "15px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "3vh",
            color: color,
            background: backgroundColor,
          }}
        >
          {status}
        </div>
      </>
    );
  }

  const [toast_message, setToastMessage] = useState("empty_fields");

  // open dialog
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [loadingSavingBlog, setLoadingSavingBlog] = useState(false);
  const handleCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteOpen(true);
  };
  const [isCreatePackageOpen, setIsCreatePackageOpen] = useState(false);
  const handleCloseCreatePackageDialog = () => {
    setIsCreatePackageOpen(false);
  };

  const handleOpenCreatePackageDialog = () => {
    setIsCreatePackageOpen(true);
  };

  function StartLoading() {
    setLoadingSavingBlog(true);
  }

  function StopLoading() {
    setLoadingSavingBlog(false);
  }

  const [dateChanged, setDateChanged] = useState(true);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showAdminGuidelines, setShowAdminGuidelines] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showExampleArticle, setShowExampleArticle] = useState(false);
  const [createGoogleDoc, setCreateGoogleDoc] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);

  const [allSelected, setAllSelected] = useState(true);
  const [activeView, setActiveView] = useState("booking");

  // const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [blogs, setBlogs] = useState<SuperAdminBlog[]>([]);
  const [currentCompanyData, setCurrentCompanyData] = useState<CompanyData>();
  const [currentCompanieBlogs, setCurrentCompanieBlogs] = useState<
    SuperAdminBlog[]
  >([]);
  const [currentWriterBlogs, setCurrentWriterBlogs] = useState<
    BlogDataResponse
  >({
    blogs: [],
    editor_blogs: [],
    user_info: {
      email: "",
      user_uuid: "",
      name: "",
      roles: [],
      verified: false,
    },
  });

  // Function to fetch blogs
  const fetchBlogs = async () => {
    try {
      const response = await fetch("https://servercode.wecon-digital.agency/api/blogs");
      const data = await response.json();
      setBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  // Function to fetch companies
  const fetchCompanies = async () => {
    try {
      const response = await fetch("https://servercode.wecon-digital.agency/api/companies");
      const data = await response.json();
      setCompanies(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  const fetchUsers = async () => {
    // ""
    try {
      const response = await fetch("https://servercode.wecon-digital.agency/api/users");
      const data = await response.json();
      // ""
      setAllUsers(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  // Function to fetch services
  const saveChanges = async () => {
    try {
      StartLoading();
      await axios.post(
        `https://servercode.wecon-digital.agency/api/save/${currentBlog.blog_uuid}`,
        currentBlog,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      StopLoading();
      setContact(!setContact);
      if (SelectedView === "users") {
        getWriterBlogs(selectedWriterUuid.toString());
      } else {
        getCompanyBlogs(currentCompanyUuid.toString());
      }

      // handleOpenSuccessfull()
      // refreshServices()
    } catch (error) {
      StopLoading();
      setContact(!setContact);
      // handleOpenFailed()
      // Handle error, if necessary
      console.error("Error updating service:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchCompanies();
    fetchUsers();
  }, []);

  const [currentBlog, setCurrentBlog] = useState<SuperAdminBlog>({
    blog_uuid: "",
    amount: 0,
    blog_title: "",
    company_name: "",
    deadline: "",
    keywords: "",
    status: "Pending",
    editor_name: "",
    editor_uuid: "",
    url: "",
    words: 0,
    company_uuid: "",
    writer_uuid: "",
    writer: "",
    additional_info: "",
    blog_length: 0,
    tools: [],
    additional_guidelines: "",
    company_guidelines: [],
    example_article: [],
    general_guidelines: false,
    multiple: false,
    project_name: "",
    urgent: false,
    category:'',
    sub_category:''
  });

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const [SelectedView, setSelectedView] = useState("users");
  const handleAdminTimeslotClick = (index: any) => {
    // Toggle visibility: if clicked again on the same item, it will hide
    // "";
    setContact(!contact);
    // setSelectedBlogIndex(selectedBlogIndex === index ? null : index);
  };

  const getCompanyBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/current-company/blogs/${company_uuid}`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs);
      setCurrentCompanyData(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getAllBlogs = async (company_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/all/blogs/`
      );
      const data = await response.json();
      setCurrentCompanieBlogs(data.current_project_blogs);
      setCurrentCompanyData(
        {company_name:"all",
      company_projects:[],
    company_uuid:'all',
  current_project_blogs:data.current_project_blogs}
      ); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getWriterBlogs = async (writer_uuid: string) => {
    // ""
    try {
      const response = await fetch(
        `https://servercode.wecon-digital.agency/api/writer/blogs/${writer_uuid}`
      );
      const data = await response.json();
      // "";
      setCurrentWriterBlogs(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const renderWriter = () => {
    return (
      <>
        <div className="assign_blogs">
          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {editUser ? (
              <>
                <h1>Edit User</h1>
              </>
            ) : (
              <>
                <h1>Create User</h1>
              </>
            )}

            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="User Name"
                    placeholder="User Name"
                    value={currentUser.name.length > 0 ? currentUser.name : ""}
                    onChange={handleUserChange}
                  />
                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="email"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="User Email"
                    placeholder="User Email"
                    value={
                      currentUser.email.length > 0 ? currentUser.email : ""
                    }
                    onChange={handleUserChange}
                  />
                </div>
                {editUser && currentUser.password.length === 0 ? (
                  <>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <span
                        className="suggest_password"
                        onClick={() => {
                          const length = 10;
                          const charset =
                            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
                          let randomPassword = "";
                          for (let i = 0, n = charset.length; i < length; ++i) {
                            randomPassword += charset.charAt(
                              Math.floor(Math.random() * n)
                            );
                          }

                          setUser((prevUser) => ({
                            ...prevUser,
                            password: randomPassword,
                          }));
                        }}
                      >
                        New password?
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      style={{
                        padding: "1rem 0rem 0.4rem 0rem",
                        alignItems: "center",
                        // display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      {" "}
                      <TextField
                        style={{
                          maxWidth: "100%",
                        }}
                        name="password"
                        className="textfield"
                        required
                        id="outlined-required"
                        label="User Passoword"
                        placeholder="User Password"
                        value={
                          currentUser.password.length > 0
                            ? currentUser.password
                            : ""
                        }
                        onChange={handleUserChange}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <span
                        className="suggest_password"
                        onClick={() => {
                          const length = 10;
                          const charset =
                            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
                          let randomPassword = "";
                          for (let i = 0, n = charset.length; i < length; ++i) {
                            randomPassword += charset.charAt(
                              Math.floor(Math.random() * n)
                            );
                          }

                          setUser((prevUser) => ({
                            ...prevUser,
                            password: randomPassword,
                          }));
                        }}
                      >
                        Suggest password?
                      </span>
                    </div>
                  </>
                )}

                <div
                  style={{
                    padding: "0.5rem 0rem 1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <MultipleSelectDropdown />
                </div>

                <div
                  style={{
                    padding: "0.5rem 0rem 1rem 0rem",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                  }}
                >
                  <h4>Verify user</h4>
                  <input
                    type="checkbox"
                    value="myCheckbox" // Assigning a value to the checkbox
                    checked={currentUser.verified}
                    onChange={() => {
                      setUser((prevUser) => ({
                        ...prevUser,
                        verified: !currentUser.verified,
                      }));
                    }}
                    style={checkboxStyle}
                  />{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      if (editUser) {
                        setEditUser(false);
                      }
                      setCreateWriter(!createWriter);
                      setUser({
                        email: "",
                        name: "",
                        verified: false,
                        user_uuid: "",
                        password: "",
                        roles: [],
                      });
                      setSelectedOptions([]);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      if (selectedOptions.length === 0) {
                        window.alert(
                          "You need at least one Role when you create User"
                        );
                      } else {
                        if (editUser) {
                          updateUser();
                        } else {
                          createNewWriter();
                        }
                      }
                    }}
                    variant="outlined"
                  >
                    {getTranslation(
                      editUser ? "buttons.update" : "buttons.create"
                    )}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderEditor = () => {
    return (
      <>
        <div className="assign_blogs">
          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="writer_name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Writer Name"
                    placeholder="Writer Name"
                    value={getCompanyName.length > 0 ? getCompanyName : ""}
                    onChange={(e) => {
                      setGetCompanyName(e.currentTarget.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setCreateEditor(!createEditor);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      if (selectedOptions.length === 0) {
                        window.alert(
                          "You need at least one Role when you create User"
                        );
                      } else {
                        if (editUser) {
                          updateUser();
                        } else {
                          createNewWriter();
                        }
                      }
                    }}
                    variant="outlined"
                  >
                    {getTranslation(
                      editUser ? "buttons.update" : "buttons.create"
                    )}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderCompany = () => {
    return (
      <>
        <div className="assign_blogs">
          <div
            className="additional-div"
            style={{
              minHeight: "15vh",
              padding: "2rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loadingSavingBlog ? (
              <>
                <div className="container_loading">
                  <div className="loading"></div>
                </div>
              </>
            ) : (
              <>
                <h1>Create New Company</h1>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="name"
                    className="textfield"
                    required
                    id="outlined-required"
                    label=" Name"
                    placeholder="Name"
                    value={newCompany.name.length > 0 ? newCompany.name : ""}
                    onChange={(e) => {
                      setNewCompany((prevCompanyData) => ({
                        ...prevCompanyData,
                        [`name`]: e.currentTarget.value,
                      }));
                    }}
                  />
                  <div
                    style={{
                      // border: "1px solid gray",
                      fontSize: "15px",
                      fontWeight: "bold",
                      maxWidth: "8%",
                      minWidth: "8%",
                    }}
                  >
                    {newBlog ? <></> : <> {getRender(currentBlog.status)}</>}
                  </div>
                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    name="email"
                    className="textfield"
                    required
                    id="outlined-required"
                    label=" Email"
                    placeholder=" Email"
                    value={newCompany.email.length > 0 ? newCompany.email : ""}
                    onChange={(e) => {
                      setNewCompany((prevCompanyData) => ({
                        ...prevCompanyData,
                        [`email`]: e.currentTarget.value,
                      }));
                    }}
                  />
                  <div
                    style={{
                      // border: "1px solid gray",
                      fontSize: "15px",
                      fontWeight: "bold",
                      maxWidth: "8%",
                      minWidth: "8%",
                    }}
                  >
                    {newBlog ? <></> : <> {getRender(currentBlog.status)}</>}
                  </div>
                </div>
                <div
                  style={{
                    padding: "1rem 0rem",
                    alignItems: "center",
                    // display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                  <TextField
                    style={{
                      maxWidth: "100%",
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Password"
                    placeholder="Password"
                    value={
                      newCompany.password.length > 0 ? newCompany.password : ""
                    }
                    onChange={(e) => {
                      setNewCompany((prevCompanyData) => ({
                        ...prevCompanyData,
                        [`password`]: e.currentTarget.value,
                      }));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      // border: "1px solid gray",
                      fontSize: "15px",
                      fontWeight: "bold",
                      maxWidth: "8%",
                      minWidth: "8%",
                    }}
                  >
                    {newBlog ? <></> : <> {getRender(currentBlog.status)}</>}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      // width: "30%",
                      marginRight: "1rem",
                      // border: "1px solid  black",
                      background: "gray",
                    }}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setNewCompany({
                        email: "",
                        name: "",
                        password: "",
                      });
                      setCreateCompany(!createCompany);
                    }}
                    // variant="outlined"
                  >
                    {getTranslation("buttons.cancel")}
                  </Button>
                  <Button
                    style={{
                      // margin: "0.5rem",
                      color: "white",
                      width: "30%",
                      // border: "1px solid  black",
                      background: "#1d73ed",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      createNewCompany();
                    }}
                    variant="outlined"
                  >
                    {newBlog ? (
                      <> {getTranslation("buttons.create")}</>
                    ) : (
                      <>{getTranslation("buttons.save")}</>
                    )}
                  </Button>
                </div>

                {/* Content of the additional div */}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  // Custom styles
  const checkboxStyle = {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
    padding: "10px",
    bordeRadius: "10px",
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  };

  const handleChangeProject = (event: any) => {
    setCurrentBlog((prevFormData) => ({
      ...prevFormData,
      [`project_name`]: event.target.value,
    }));
  };

  const renderContact = () => {
    return (
      <>
        <div
          style={{
            width: "60%",
            maxWidth: "60%",
            minWidth: "60%",
            background: "white",
            borderRadius: "20px",
            padding: "2rem",
            maxHeight: "auto", // Set maximum height to 70% of the viewport height
            overflowY: "hidden",
            overflowX: "hidden", // Hide vertical scrollbar
            // Add vertical scrollbar when content overflows
            // scrollbarColor: "blue white", // Set scrollbar color to blue and scrollbar track to white
            scrollbarWidth: "thin", // Opti
          }}
        >
          <div className="" style={{ minHeight: "5vh" }}>
            {currentBlog.blog_uuid.length > 0 ? (
              <>
                {" "}
                <h1>Current Blog</h1>
              </>
            ) : (
              <>
                {" "}
                <h1>Create Blog</h1>
              </>
            )}
          </div>
          <div
            className=""
            style={{ minHeight: "55vh", maxHeight: "55vh", overflow: "scroll" }}
          >
            <div
              className="additional-div"
              style={{
                minHeight: "50vh",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {loadingSavingBlog ? (
                <>
                  <div className="container_loading">
                    <div className="loading"></div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      padding: "1rem 0rem",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "start",
                    }}
                  >
                    {currentBlog.blog_uuid.length > 0 ? (
                      // CURRENT
                      <>
                        {" "}
                        <TextField
                          style={{
                            maxWidth: "15%",
                          }}
                          name="company_name"
                          className="textfield"
                          // readOnly={true}
                          disabled={true}
                          id="outlined-required"
                          label="Company Name"
                          placeholder="CompanyName"
                          value={
                            currentBlog.company_name.length > 0
                              ? currentBlog.company_name
                              : ""
                          }
                          onChange={handleValueChange}
                        />
                        <TextField
                          style={{
                            maxWidth: "10%",
                            padding: "0rem 1rem",
                          }}
                          name="blog_length"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Blog Length"
                          placeholder="Blog Length"
                          value={currentBlog.blog_length}
                          onChange={(e) => {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`blog_length`]: Number(e.currentTarget.value),
                            }));
                          }}
                        />
                        <FormControl style={{ width: "20%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Writer
                          </InputLabel>
                          <Select
                            name="writer"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.writer_uuid}
                            label="Writer Name"
                            onChange={handleChange}
                          >
                            {" "}
                            <MenuItem value="">{"None"}</MenuItem>
                            {all_users.map((user: UserLite) => {
                              if (user.roles.includes("Writer")) {
                                return (
                                  <MenuItem
                                    key={user.user_uuid}
                                    value={user.user_uuid}
                                  >
                                    {user.name}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>{" "}
                      </>
                    ) : (
                      // CREATE BLOG
                      <>
                        <TextField
                          style={{
                            maxWidth: "30%",
                            padding: "0rem 1rem 0rem 0rem",
                          }}
                          name="company_name"
                          className="textfield"
                          required
                          disabled={true}
                          id="outlined-required"
                          // label="Company Name"
                          // placeholdere="Company Name"
                          value={currentCompanyData?.company_name}
                          onChange={handleValueChange}
                        />
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Project
                          </InputLabel>
                          <Select
                            name="editor_name"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.project_name}
                            label="Editor"
                            onChange={handleChangeProject}
                          >
                            {currentCompanyData &&
                            currentCompanyData.company_projects &&
                            currentCompanyData.company_projects.length > 0
                              ? currentCompanyData.company_projects.map(
                                  (project: string) => (
                                    <MenuItem key={project} value={project}>
                                      {project}
                                    </MenuItem>
                                  )
                                )
                              : null}
                          </Select>
                        </FormControl>{" "}
                        <FormControl
                          style={{ width: "100%", padding: "0rem 1rem" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Writer
                          </InputLabel>
                          <Select
                            name="writer"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.writer_uuid}
                            label="Writer Name"
                            onChange={handleChange}
                          >
                            {" "}
                            <MenuItem value="">{"None"}</MenuItem>
                            {all_users.map((user: UserLite) => {
                              if (user.roles.includes("Writer")) {
                                return (
                                  <MenuItem
                                    key={user.user_uuid}
                                    value={user.user_uuid}
                                  >
                                    {user.name}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          style={{
                            maxWidth: "10%",
                            padding: "0rem 1rem",
                          }}
                          name="blog_length"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Blog Length"
                          placeholder="Blog Length"
                          value={currentBlog.blog_length}
                          onChange={handleValueChange}
                        />
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBlog.status}
                            label="Company Name"
                            onChange={handleChangeStatus}
                          >
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                            <MenuItem value={"Written"}>Written</MenuItem>
                            <MenuItem value={"Editor"}>Editor</MenuItem>
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                            <MenuItem value={"Revision"}>Revision</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      padding: "1rem 0rem",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      style={{
                        maxWidth: "100%",
                      }}
                      name="blog_title"
                      className="textfield"
                      required
                      id="outlined-required"
                      label="Blog Title"
                      placeholder="CompanyName"
                      value={
                        currentBlog.blog_title.length > 0
                          ? currentBlog.blog_title
                          : ""
                      }
                      onChange={handleValueChange}
                    />{" "}
                    <SelectTools />
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Editor
                      </InputLabel>
                      <Select
                        name="editor_name"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentBlog.editor_uuid}
                        label="Editor"
                        onChange={handleChangeEditor}
                      >
                        {" "}
                        <MenuItem value="">{"None"}</MenuItem>
                        {all_users.map((user: UserLite) => {
                          if (user.roles.includes("Editor")) {
                            return (
                              <MenuItem
                                key={user.user_uuid}
                                value={user.user_uuid}
                              >
                                {user.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Urgent</h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={currentBlog.urgent}
                        onChange={(e) => {
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`urgent`]: !currentBlog.urgent,
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {newBlog ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Multiple Article </h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={currentBlog.multiple}
                            onChange={(e) => {
                              if (!currentBlog.multiple) {
                                setCurrentBlog((prevFormData) => ({
                                  ...prevFormData,
                                  [`url`]: "  ",
                                }));
                              }
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`multiple`]: !currentBlog.multiple,
                              }));

                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {currentBlog.multiple ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingBottom: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ fontSize: "small", color: "gray" }}>
                              {
                                "For Separate Blogs With Comma Example:( Keyword1 Keyword2 , Keyoword3 )"
                              }{" "}
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      padding: "0rem 0rem 1rem 0rem",
                      alignItems: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      style={{
                        maxWidth: "100%",
                      }}
                      name="keywords"
                      className="textfield"
                      required
                      id="outlined-required"
                      label="Keywords"
                      placeholder="CompanyName"
                      value={
                        currentBlog.keywords.length > 0
                          ? currentBlog.keywords
                          : ""
                      }
                      onChange={handleValueChange}
                    />
                    {/* <TextField
                    style={{
                      maxWidth: "100%",

                      padding: "0rem 0.5rem",
                    }}
                    name="url"
                    className="textfield"
                    required
                    id="outlined-required"
                    label="Google Doc Url"
                    placeholder="Url"
                    value={currentBlog.url.length > 0 ? currentBlog.url : ""}
                    onChange={handleValueChange}
                  />
                  <a
                    style={{ marginLeft: "1rem" }}
                    href={currentBlog.url}
                    target="_blank"
                  >
                    {currentBlog.url.length > 0 ? "Link" : ""}
                  </a> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>General Guidelines </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={generalGuidelines}
                        onChange={(e) => {
                          setGeneralGuidelines(!generalGuidelines);
                          // setCurrentBlog((prevFormData) => ({
                          //   ...prevFormData,
                          //   [`guideline`]: "",
                          // }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>

                  {newBlog ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Company Guidelines </h3>

                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={showCompanyGuidelines}
                            onChange={(e) => {
                              setShowCompanyGuidelines(!showCompanyGuidelines);
                              setSelectedGuidelines([]);
                              setShowGuidelineEditor("");
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`guideline`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {showCompanyGuidelines ? (
                        <>
                          <div
                            id="company_guidelines"
                            style={{
                              width: "100%",
                              // display: "flex",
                              gap: "15px",
                              display: "grid",
                              // gridTemplateColumns: "repeat(3, 1fr)",
                              // justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {guidelines.map((guideline: Guideline) => {
                              const doc = guideline.guideline_data.startsWith(
                                "http"
                              );
                              return (
                                <>
                                  <div className="guideline_css">
                                    {guideline.guideline_name}{" "}
                                    <div>
                                      <RemoveRedEyeIcon
                                        onClick={() =>
                                          handleEyeIconClick(
                                            guideline.guideline_uuid
                                          )
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        value="myCheckbox" // Assigning a value to the checkbox
                                        checked={selectedGuidelines.includes(
                                          guideline.guideline_uuid
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            guideline.guideline_uuid
                                          )
                                        }
                                        style={checkboxStyle}
                                      />{" "}
                                    </div>
                                  </div>
                                  {showGuidelineEditor ===
                                    guideline.guideline_uuid && (
                                    <>
                                      {doc ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <ReactQuill
                                              readOnly={true}
                                              style={{ width: "100%" }}
                                              value={guideline.guideline_data}
                                            />
                                            <a
                                              href={guideline.guideline_data}
                                              target="_blank"
                                            >
                                              {" "}
                                              Link
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <ReactQuill
                                            readOnly={true}
                                            style={{ width: "100%" }}
                                            value={guideline.guideline_data}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3>Company Guidelines</h3>
                        {
                          " (You can only see them when you open this blog from company)"
                        }
                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            checked={showCompanyGuidelines}
                            onChange={(e) => {
                              setShowCompanyGuidelines(!showCompanyGuidelines);
                              setSelectedGuidelines([]);
                              setShowGuidelineEditor("");
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`guideline`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </div>
                      {showCompanyGuidelines ? (
                        <>
                          <div
                            id="company_guidelines"
                            style={{
                              width: "100%",
                              // display: "flex",
                              gap: "15px",
                              display: "grid",
                              // gridTemplateColumns: "repeat(3, 1fr)",
                              // justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            {guidelines.map((guideline: Guideline) => {
                              const doc = guideline.guideline_data.startsWith(
                                "http"
                              );
                              return (
                                <>
                                  <div className="guideline_css">
                                    {guideline.guideline_name}{" "}
                                    <div>
                                      <RemoveRedEyeIcon
                                        onClick={() =>
                                          handleEyeIconClick(
                                            guideline.guideline_uuid
                                          )
                                        }
                                      />
                                      <input
                                        type="checkbox"
                                        value="myCheckbox" // Assigning a value to the checkbox
                                        checked={selectedGuidelines.includes(
                                          guideline.guideline_uuid
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            guideline.guideline_uuid
                                          )
                                        }
                                        style={checkboxStyle}
                                      />{" "}
                                    </div>
                                  </div>
                                  {showGuidelineEditor ===
                                    guideline.guideline_uuid && (
                                    <>
                                      {doc ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <ReactQuill
                                              readOnly={true}
                                              style={{ width: "100%" }}
                                              value={guideline.guideline_data}
                                            />
                                            <a
                                              href={guideline.guideline_data}
                                              target="_blank"
                                            >
                                              {" "}
                                              Link
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <ReactQuill
                                            readOnly={true}
                                            style={{ width: "100%" }}
                                            value={guideline.guideline_data}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Additional Guideline </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showAdditionalGuidelines}
                        onChange={(e) => {
                          setShowAdditionalGuidelines(
                            !showAdditionalGuidelines
                          );
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`guideline`]: "",
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showAdditionalGuidelines ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <ReactQuill
                          style={{ width: "100%" }}
                          value={currentBlog.additional_guidelines}
                          onChange={(content) => {
                            console.log();
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`additional_guidelines`]: content,
                            }));
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Create Google Doc </h3>

                    {newBlog ? (
                      <>
                        {" "}
                        <label style={labelStyle}>
                          <input
                            type="checkbox"
                            value="myCheckbox" // Assigning a value to the checkbox
                            // disabled= {? true :false}
                            checked={
                              currentBlog.url.length > 0
                                ? true
                                : createGoogleDoc
                            }
                            onChange={(e) => {
                              setCreateGoogleDoc(!createGoogleDoc);
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`url`]: "",
                              }));
                              //
                            }}
                            style={checkboxStyle}
                          />
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {createGoogleDoc &&
                  currentBlog.url.length === 0 &&
                  !currentBlog.multiple ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          Add custom link ?{" "}
                          <span
                            onClick={() => {
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`url`]: "Add url here...",
                              }));
                            }}
                            style={{ fontWeight: "bold" }}
                            className="hover"
                          >
                            Click here
                          </span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {(currentBlog.url.length > 0 || !newBlog) &&
                  !currentBlog.multiple ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          style={{
                            maxWidth: "100%",
                          }}
                          name="url"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Google Docs Url"
                          placeholder="Google Docs Url"
                          value={
                            currentBlog.url.length > 0 ? currentBlog.url : ""
                          }
                          onChange={handleValueChange}
                        />
                        <a
                          style={{ marginLeft: "1rem" }}
                          href={currentBlog?.url}
                          target="_blank"
                        >
                          {currentBlog?.url?.length > 0 ? "Link" : ""}
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Example Article </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showExampleArticle}
                        onChange={(e) => {
                          setShowExampleArticle(!showExampleArticle);
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`example_article`]: [],
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showExampleArticle ? (
                    <>
                      {/* Render each example article with delete button */}
                      {Array.isArray(currentBlog.example_article) &&
                        currentBlog.example_article.map((example, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              minWidth: "100%",
                            }}
                          >
                            <TextField
                              style={{
                                minWidth: "60%",
                                padding: "0.5rem 0rem",
                              }}
                              value={example}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setCurrentBlog((prevBlog) => ({
                                  ...prevBlog,
                                  example_article: [
                                    ...prevBlog.example_article.slice(0, index),
                                    newValue,
                                    ...prevBlog.example_article.slice(
                                      index + 1
                                    ),
                                  ],
                                }));
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                setCurrentBlog((prevBlog) => ({
                                  ...prevBlog,
                                  example_article: [
                                    ...prevBlog.example_article.slice(0, index),
                                    ...prevBlog.example_article.slice(
                                      index + 1
                                    ),
                                  ],
                                }));
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))}
                      {/* Add button to add another example article */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>Add another example</span>
                        <IconButton
                          className="hover"
                          style={{
                            color: "white",
                            padding: "0.1rem",
                            borderRadius: "50px",
                            background: "blue",
                          }}
                          onClick={() => {
                            setCurrentBlog((prevBlog) => ({
                              ...prevBlog,
                              example_article: [
                                ...(prevBlog.example_article || []),
                                "",
                              ],
                            }));
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                      {/* <div style={{display:'flex', flexDirection:'row', userSelect:'none', padding:'1rem 0rem', justifyContent:'start', gap:'10px', alignItems:'center'}}>
                          <span>Add another example</span> <AddIcon className="hover" style={{color:'white', padding:'0.1rem', borderRadius:'50px', background:'blue'}} />
                          </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h3>Additional Information </h3>

                    <label style={labelStyle}>
                      <input
                        type="checkbox"
                        value="myCheckbox" // Assigning a value to the checkbox
                        checked={showAdditionalInfo}
                        onChange={(e) => {
                          setShowAdditionalInfo(!showAdditionalInfo);
                          setCurrentBlog((prevFormData) => ({
                            ...prevFormData,
                            [`additional_info`]: "",
                          }));
                          //
                        }}
                        style={checkboxStyle}
                      />
                    </label>
                  </div>
                  {showAdditionalInfo ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <ReactQuill
                          style={{ width: "100%" }}
                          value={currentBlog.additional_info}
                          onChange={(content) => {
                            console.log();
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`additional_info`]: content,
                            }));
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {!newBlog ? (
                    <>
                      <div
                        style={{
                          padding: "1rem 0rem",
                          alignItems: "center",
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            width: "70%",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            style={{
                              maxWidth: "70%",
                            }}
                            name="docs_name"
                            className="textfield"
                            required
                            id="outlined-required"
                            label="Enter the whole text from the blog"
                            placeholder="Url"
                            multiline={true}
                            rows={4}
                            // value={
                            //   blog.url.length > 0
                            //     ? blog.url
                            //     : ""
                            // }
                            onChange={(e) => {
                              // ""
                              // console.log(e.currentTarget.value)
                              let text = e.currentTarget.value;
                              let words = text
                                .split(/\s+/)
                                .filter((word) => word.length > 0);
                              // const { name, value } = e.target;
                              setCurrentBlog((prevFormData) => ({
                                ...prevFormData,
                                [`words`]: words.length,
                              }));

                              // console.log(words.length);
                              // updateWords(index, words.length);
                              // console.log(index);
                              // console.log();
                            }}
                          />
                        </div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          style={{
                            maxWidth: "10%",
                          }}
                          name="words"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Words"
                          placeholder="Url"
                          value={currentBlog.words > 0 ? currentBlog.words : ""}
                          onChange={(e) => {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`words`]: Number(e.currentTarget.value),
                            }));
                          }}
                        />
                        <span
                          style={{ padding: "0rem 1rem" }}
                          onClick={() => {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`amount`]: Number(
                                (currentBlog.words * 0.005).toFixed(2)
                              ),
                            }));
                          }}
                        >
                          Calculate
                        </span>
                        <TextField
                          style={{
                            maxWidth: "10%",
                            // padding: "0rem 0.5rem",
                          }}
                          name="docs_name"
                          className="textfield"
                          required
                          id="outlined-required"
                          label="Amount"
                          placeholder="Amount"
                          value={
                            currentBlog.amount > 0 ? currentBlog.amount : ""
                          }
                          onChange={(e) => {
                            setCurrentBlog((prevFormData) => ({
                              ...prevFormData,
                              [`amount`]: Number(e.currentTarget.value),
                            }));
                          }}
                        />
                        <FormControl
                          style={{
                            maxWidth: "100%",
                            minWidth: "20%",
                            marginLeft: "1rem",
                          }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Blog Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              currentBlog.status.length > 0
                                ? currentBlog.status
                                : "Pending"
                            }
                            label="Company Name"
                            onChange={handleChangeStatus}
                          >
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"In Progress"}>
                              In Progress
                            </MenuItem>
                            {/* <MenuItem value={10}>Finished</MenuItem> */}
                            <MenuItem value={"Written"}>Written</MenuItem>
                            <MenuItem value={"Editor"}>Editor</MenuItem>
                            <MenuItem value={"Completed"}>Completed</MenuItem>
                            <MenuItem value={"Revision"}>Revision</MenuItem>
                            <MenuItem value={"Failed"}>Failed</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {/* Content of the additional div */}
                </>
              )}
            </div>
          </div>

          <div className="blog_buttons">
            <Button
              style={{
                // margin: "0.5rem",
                color: "white",
                // width: "15%",
                marginRight: "1rem",
                // border: "1px solid  black",
                background: "gray",
              }}
              startIcon={<CloseIcon />}
              onClick={() => {
                //   handleAdminTimeslotClick(index);
                setCreateGoogleDoc(false);
                setShowExampleArticle(false);
                setShowCompanyGuidelines(false);
                setSelectedGuidelines([]);
                setContact(!contact);
                setCurrentBlog({
                  blog_uuid: "",
                  amount: 0,
                  multiple: false,
                  blog_title: "",
                  company_name: "",
                  deadline: "",
                  urgent: false,
                  project_name: "",
                  keywords: "",
                  status: "",
                  company_uuid: "",
                  general_guidelines: false,
                  company_guidelines: [],
                  additional_guidelines: "",
                  writer_uuid: "",
                  url: "",
                  words: 0,
                  editor_name: "",
                  editor_uuid: "",
                  writer: "",
                  additional_info: "",
                  blog_length: 0,
                  example_article: [],
                  tools: [],
                  category:'',
                  sub_category:''
                });
              }}
              // variant="outlined"
            >
              {getTranslation("buttons.cancel")}
            </Button>
            <Button
              style={{
                // margin: "0.5rem",
                color: "white",
                // width: "15%",
                // border: "1px solid  black",
                background: "#1d73ed",
              }}
              startIcon={<SaveIcon />}
              onClick={() => {
                //   "";
                if (newBlog) {
                  createNewBlog();
                } else {
                  saveChanges();
                }
                // handleAdminTimeslotClick(index);
              }}
              variant="outlined"
            >
              {newBlog ? (
                <> {getTranslation("buttons.create")}</>
              ) : (
                <>{getTranslation("buttons.save")}</>
              )}
            </Button>
          </div>
          {/* Content of the additional div */}
        </div>
      </>
    );
  };

  function computePayment(): React.ReactNode {
    let total = 0;
    currentWriterBlogs.blogs.map((blog) => {
      // if ( blog.status === "Finished") {
      //   total = total +blog.amount
      // }
      total = total + blog.amount;
    });
    return <>{total.toFixed(2)} </>;
  }

  function computePaymentCompany(): React.ReactNode {
    let total = 0;
    currentCompanieBlogs.map((blog) => {
      // if ( blog.status === "Finished") {
      //   total = total +blog.amount
      // }
      total = total + blog.amount;
    });
    return <>{total.toFixed(2)} </>;
  }

  return (
    <>
      <div
        className="container minh-100"
        // , borderTop:'3vh solid #1d73ed',borderLeft:'3vh solid #1d73ed'
        style={{ display: "flex", width: "100%" }}
      >
        <div
          className="admin_header minh-100 left_side"
          style={{
            minHeight: "100%",
            width: "15%",
            borderBottom: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <div
            className="top_view"
            style={{
              userSelect: "none",
              minHeight: "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid lightgray",
            }}
          >
            <img
              src={LogoImg}
              onClick={() => {
                if (currentView != "") {
                  setShowCompanies(false);
                  setShowAdminGuidelines(false);
                }
                if (showCompanies) {
                  setCurrentCompanyUuid("");
                  setCurrenteView("");
                }
                if (showAdminGuidelines) {
                  setShowAdminGuidelines(false);
                  setCurrentCompanyUuid("");
                  setCurrenteView("");
                }
              }}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
          </div>
          <div style={{ minHeight: "100%" }}>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                color: showCompanies ? "white" : "",
                background: showCompanies ? "#1d73ed" : "white",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                ("");
                setCurrentCompanyUuid("");
                setShowCompanies(!showCompanies);
                setShowAdminGuidelines(false);
                if (showCompanies) {
                  // setCurrentCompanyUuid("")
                  // setCurrenteView("")
                }
              }}
            >
              Companies{" "}
              {!showCompanies ? (
                <>
                  <ArrowDropDownIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              ) : (
                <>
                  <ArrowDropUpIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              )}
            </div>
            <div
              hidden={!showCompanies}
              style={{ userSelect: "none", maxHeight: "auto" }}
            >
              <div
                className="hover"
                style={{
                  userSelect: "none",
                  padding: "1rem",
                  background: "#eff7fd",
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                  borderBottom: "1px solid lightgray",
                  justifyContent: "start",
                }}
                onClick={() => {
                  setCurrentCompanyUuid("all");
                  getAllBlogs("all")
                  if (currentView.length == 0) {
                    setCurrenteView("home");
                    // setSelectedCompany(index);
                    // setCurrentCompanyUuid(company.company_uuid);
                    // getCompanyBlogs(company.company_uuid);
                  } else {
                    console.log(currentView, currentCompanyUuid);
                    // if (currentCompanyUuid.length > 0) {
                    //   setSelectedCompany(index);
                    //   setCurrenteView("home");
                    //   setCurrentCompanyUuid(
                    //     companies[index].company_uuid
                    //   );
                    //   getCompanyBlogs(companies[index].company_uuid);
                    // } else {
                    //   setCurrentCompanyUuid("");
                    //   setCurrenteView("");
                    // }
                  }
                }}
              >
                {currentCompanyUuid === "all" ? <><TrendingFlatIcon/></>:<></>}


                {"All"}
              </div>
              {companies.map((company, index) => {
                return (
                  <>
                    <div
                      className="hover"
                      style={{
                        userSelect: "none",
                        padding: "1rem",
                        background: "#eff7fd",
                        alignItems: "center",
                        display: "flex",
                        gap: "10px",
                        borderBottom: "1px solid lightgray",
                        justifyContent: "start",
                      }}
                      onClick={() => {
                        if (currentView.length == 0) {
                          setCurrenteView("home");
                          setSelectedCompany(index);
                          setCurrentCompanyUuid(company.company_uuid);
                          getCompanyBlogs(company.company_uuid);
                        } else {
                          console.log(currentView, currentCompanyUuid);
                          if (currentCompanyUuid.length > 0) {
                            setSelectedCompany(index);
                            setCurrenteView("home");
                            setCurrentCompanyUuid(
                              companies[index].company_uuid
                            );
                            getCompanyBlogs(companies[index].company_uuid);
                          } else {
                            setCurrentCompanyUuid("");
                            setCurrenteView("");
                          }
                        }
                      }}
                    >
                      {currentCompanyUuid === company.company_uuid ? (
                        <>
                          <TrendingFlatIcon />
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {currentCompanyUuid === company.company_uuid ? <><TrendingFlatIcon/></>:<></>} */}
                      {company.company_name}
                    </div>

                    {currentCompanyUuid === company.company_uuid ? (
                      <>
                        {["Guidelines"].map((current) => {
                          return (
                            <>
                              <div
                                className="hover"
                                onClick={() => {
                                  setCurrenteView("guidelines");
                                }}
                                style={{
                                  userSelect: "none",
                                  padding: "1rem 1rem 1rem 2rem",
                                  background: "white",
                                  gap: "10px",
                                  alignItems: "center",
                                  fontWeight:
                                    currentView === "guidelines" ? "bold" : "",
                                  display: "flex",
                                  borderBottom: "1px solid lightgray",
                                  justifyContent: "start",
                                }}
                              >
                                <MenuBookIcon />
                                {current}
                              </div>
                            </>
                          );
                        })}
                        {["Settings"].map((current) => {
                          return (
                            <>
                              <div
                                className="hover"
                                onClick={() => {
                                  setCurrenteView("settings");
                                }}
                                style={{
                                  userSelect: "none",
                                  padding: "1rem 1rem 1rem 2rem",
                                  background: "white",
                                  gap: "10px",
                                  alignItems: "center",
                                  display: "flex",
                                  fontWeight:
                                    currentView === "settings" ? "bold" : "",
                                  borderBottom: "1px solid lightgray",
                                  justifyContent: "start",
                                }}
                              >
                                <SettingsIcon />
                                {current}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                background: showAdminGuidelines ? "#1d73ed" : "white",
                color: showAdminGuidelines ? "white" : "black",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                // setCurrentCompanyUuid("");
                setShowCompanies(false);
                setShowAdminGuidelines(!showAdminGuidelines);
                setCurrentCompanyUuid("");
                if (showAdminGuidelines) {
                  // setCurrenteView("")
                }
              }}
            >
              General Guidelines
              {/* {!showAdminGuidelines ? (
                <>
                  <ArrowDropDownIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              ) : (
                <>
                  <ArrowDropUpIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              )} */}
            </div>
            <div
              style={{
                userSelect: "none",
                padding: "1rem",
                // background: "blue",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Tutorials <ArrowDropDownIcon />
            </div>
          </div>
        </div>
        <div className="admin_header minh-100 right_side">
          <div className="top_view header_logout">
            <img
              className="mobile_img"
              src={LogoImg}
              onClick={() => {
                if (currentView != "") {
                  setShowCompanies(false);
                  setShowAdminGuidelines(false);
                }
                if (showCompanies) {
                  setCurrentCompanyUuid("");
                  setCurrenteView("");
                }
                if (showAdminGuidelines) {
                  setShowAdminGuidelines(false);
                  setCurrentCompanyUuid("");
                  setCurrenteView("");
                }
              }}
              style={{ minHeight: "6vh", maxHeight: "6vh" }}
              alt="My Local Image"
            />
            <div
              className="logout"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("userDetails");

                navigate("/login");
              }}
            >
              {" "}
              <LogoutIcon />{" "}
              <span style={{ marginLeft: "0.5rem" }}>Log out </span>
            </div>
          </div>
          <div className="mobile mobile_companies">
            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                color: showCompanies ? "white" : "",
                background: showCompanies ? "#1d73ed" : "white",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                ("");
                setCurrentCompanyUuid("");
                setShowCompanies(!showCompanies);
                setShowAdminGuidelines(false);
                if (showCompanies) {
                  // setCurrentCompanyUuid("")
                  // setCurrenteView("")
                }
              }}
            >
              Companies{" "}
              {!showCompanies ? (
                <>
                  <ArrowDropDownIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              ) : (
                <>
                  <ArrowDropUpIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              )}
            </div>

            <div
              className="hover"
              style={{
                userSelect: "none",
                padding: "1rem",
                background: showAdminGuidelines ? "#1d73ed" : "white",
                color: showAdminGuidelines ? "white" : "black",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                // setCurrentCompanyUuid("");
                setShowCompanies(false);
                setShowAdminGuidelines(!showAdminGuidelines);
                setCurrentCompanyUuid("");
                if (showAdminGuidelines) {
                  // setCurrenteView("")
                }
              }}
            >
              General Guidelines
              {/* {!showAdminGuidelines ? (
                <>
                  <ArrowDropDownIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              ) : (
                <>
                  <ArrowDropUpIcon
                    className="hover"
                    onClick={() => {
                      setShowCompanies(!showCompanies);
                    }}
                  />
                </>
              )} */}
            </div>
            <div
              style={{
                userSelect: "none",
                padding: "1rem",
                // background: "blue",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Tutorials <ArrowDropDownIcon />
            </div>
          </div>
          <div className="mobile">
            {showCompanies ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    maxWidth: "100%",
                  }}
                >
                  {companies.map((company, index) => {
                    return (
                      <>
                        <div
                          className="hover"
                          style={{
                            userSelect: "none",
                            padding: "1rem",
                            background: "#eff7fd",
                            alignItems: "center",
                            display: "flex",
                            gap: "10px",
                            borderBottom: "1px solid lightgray",
                            justifyContent: "start",
                          }}
                          onClick={() => {
                            if (currentView.length == 0) {
                              setCurrenteView("home");
                              setSelectedCompany(index);
                              setCurrentCompanyUuid(company.company_uuid);
                              getCompanyBlogs(company.company_uuid);
                            } else {
                              console.log(currentView, currentCompanyUuid);
                              if (currentCompanyUuid.length > 0) {
                                setSelectedCompany(index);
                                setCurrenteView("home");
                                setCurrentCompanyUuid(
                                  companies[index].company_uuid
                                );
                                getCompanyBlogs(companies[index].company_uuid);
                              } else {
                                setCurrentCompanyUuid("");
                                setCurrenteView("");
                              }
                            }
                          }}
                        >
                          {currentCompanyUuid === company.company_uuid ? (
                            <>
                              <TrendingFlatIcon />
                            </>
                          ) : (
                            <></>
                          )}
                          {/* {currentCompanyUuid === company.company_uuid ? <><TrendingFlatIcon/></>:<></>} */}
                          {company.company_name}
                        </div>

                        {currentCompanyUuid === company.company_uuid ? (
                          <>
                            {["Guidelines"].map((current) => {
                              return (
                                <>
                                  <div
                                    className="hover"
                                    onClick={() => {
                                      setCurrenteView("guidelines");
                                    }}
                                    style={{
                                      userSelect: "none",
                                      padding: "1rem 1rem 1rem 2rem",
                                      background: "white",
                                      gap: "10px",
                                      alignItems: "center",
                                      fontWeight:
                                        currentView === "guidelines"
                                          ? "bold"
                                          : "",
                                      display: "flex",
                                      borderBottom: "1px solid lightgray",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <MenuBookIcon />
                                    {current}
                                  </div>
                                </>
                              );
                            })}
                            {["Settings"].map((current) => {
                              return (
                                <>
                                  <div
                                    className="hover"
                                    onClick={() => {
                                      setCurrenteView("settings");
                                    }}
                                    style={{
                                      userSelect: "none",
                                      padding: "1rem 1rem 1rem 2rem",
                                      background: "white",
                                      gap: "10px",
                                      alignItems: "center",
                                      display: "flex",
                                      fontWeight:
                                        currentView === "settings"
                                          ? "bold"
                                          : "",
                                      borderBottom: "1px solid lightgray",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <SettingsIcon />
                                    {current}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          {/* TUKAA */}
          {currentCompanyUuid.length > 0 && !showAdminGuidelines ? (
            <>{renderCompanyView()}</>
          ) : (
            <></>
          )}

          {showAdminGuidelines ? (
            <>
              <CompanyGuidelines
                all_users={all_users}
                adminGuidelines={true}
                company={currentCompanyData}
              />
            </>
          ) : (
            <> </>
          )}

          {(!showCompanies && !showAdminGuidelines) ||
          (showCompanies && currentCompanyUuid.length === 0) ? (
            <>
              <div className="main_container admin_employee">
                <div style={{ minWidth: "100%" }}>
                  <h2>Welcome {userDetails?.name}</h2>
                  {/* <h3>Admin Dashboard</h3> */}
                </div>
                <div style={{ minWidth: "100%" }}>
                  {/* <p>
                    Here you can find all information about every company and user
                  </p> */}
                </div>
                <div
                  style={{
                    minWidth: "100%",
                    minHeight: "5vh",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <div
                    style={{
                      minWidth: "21rem",
                      minHeight: "3vh",
                      padding: "0.5rem",
                      background: "white",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    Date Range: 07 Jan 2018 - 08 Jan 2019
                  </div> */}
                  <div className="board_hide" style={{ minWidth: "50%" }}>
                    <h3>Admin Dashboard</h3>
                  </div>
                  <div
                    style={{
                      justifyContent: "end",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1rem",
                    }}
                  >
                    {/* <ToggleButtonGroup
                      color="primary"
                      value={SelectedView}
                      exclusive
                      onChange={(event, newSelectedView) => {
                        setSelectedView(newSelectedView);
                        // Other operations you want to perform when the selection changes
                      }}
                      aria-label="Platform"
                      sx={{
                        "& .MuiToggleButton-root": {
                          color: "#1d73ed",
                          outline: "#1d73ed",
                          padding: "1rem",
                          maxHeight: "3vh",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#1d73ed",
                          },
                        },
                        "& .Mui-selected": {
                          color: "white !important", // Custom style for the selected item text color
                          backgroundColor: "#1d73ed  !important", // Custom style for the selected item background color
                          "&:hover": {
                            backgroundColor: "#1d73ed  !important",
                            color: "white", // Maintain the custom style on hover
                          },
                          "& .MuiButtonBase-root": {
                            color: "#1d73ed  !important",
                            // backgroundColor: 'red', // Update the background color of the selected item
                          },
                        },
                      }}
                    >
                      <ToggleButton value="company">
                        <ApartmentIcon />
                      </ToggleButton>
                      <ToggleButton value="users">
                        <PeopleIcon />
                      </ToggleButton>
                    </ToggleButtonGroup> */}
                    {/* <Button
                      style={{
                        margin: "0.5rem",
                        color: "white",
                        // border: "1px solid  black",
                        background: "#1d73ed",
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={(e: any) => {
                        setNewBlog(true);
                        setCurrentBlog({
                          blog_uuid: "",
                          amount: 0,
                          blog_title: "",
                          company_name: "",
                          deadline: "",
                          keywords: "",
                          status: "",
                          company_uuid: "",
                          writer_uuid: "",
                          url: "",
                          words: 0,
                          editor_name: "",
                          editor_uuid: "",
                          writer: "",
                          additional_info: "",
                          blog_length: 0,
                          additional_guidelines:"",
                          company_guidelines:[],
                          general_guidelines:false,
                          multiple:false,
                          project_name:"",
                          urgent:false,
                          tools: [],
                          example_article:[]
                        });
                        setContact(!contact);
                      }}
                      variant="outlined"
                    >
                      {getTranslation("buttons.create_blog")}
                    </Button> */}
                    <Button
                      style={{
                        margin: "0.5rem",
                        color: "white",
                        // border: "1px solid  black",
                        background: "#1d73ed",
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={(e: any) => {
                        setNewBlog(true);

                        setCreateCompany(!createCompany);
                      }}
                      variant="outlined"
                    >
                      {getTranslation("buttons.add_company")}
                    </Button>
                    <Button
                      style={{
                        margin: "0.5rem",
                        color: "white",
                        // border: "1px solid  black",
                        background: "#1d73ed",
                      }}
                      startIcon={<AddCircleIcon />}
                      onClick={(e: any) => {
                        setCreateWriter(!createWriter);
                      }}
                      variant="outlined"
                    >
                      {getTranslation("buttons.add_user")}
                    </Button>
                    {/* <Button
                  style={{
                    margin: "0.5rem",
                    color: "white",
                    // border: "1px solid  black",
                    background: "#1d73ed",
                  }}
                  startIcon={<AddCircleIcon />}
                  onClick={(e: any) => {
                    setCreateEditor(!createEditor);
                  }}
                  variant="outlined"
                >
                  {getTranslation("buttons.add_editor")}
                </Button> */}
                  </div>
                </div>
                {SelectedView === "company" ? (
                  <>
                    <div
                      className={"admin_timeslot_header no_hover"}
                      style={{
                        margin: "2rem 0rem 0rem 0rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className={"regular_user"}
                        style={{ padding: "0rem" }}
                      >
                        {/* <div
                      onClick={() => {
                        // currentCompanieBlogs()
                        setSelectedCompany(100);
                      }}
                      className="user_name"
                      style={{
                        padding: "0.2rem 1.5rem",
                        // borderRadius: "50px",
                        fontWeight: "bold",
                        background: 100 === selectedCompany ? "white" : "",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: 100 == selectedCompany ? "#1d73ed" : "",
                        minWidth: "auto",
                      }}
                    >
                      All
                    </div> */}
                        {/* {["Tab 1", "Tab 2"].map((company, index) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  // setSelectedCompany(index);
                                  // setCurrentCompanyUuid(company);
                                  // getCompanyBlogs(company);
                                }}
                                className="user_name"
                                style={{
                                  padding: "0.2rem 1.5rem",
                                  // borderRadius: "50px",
                                  fontWeight: "bold",
                                  background:
                                    index === selectedCompany ? "white" : "",
                                  fontSize: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color:
                                    index === selectedCompany ? "#1d73ed" : "",
                                  minWidth: "auto",
                                }}
                              >
                                {company}
                              </div>
                            </>
                          );
                        })} */}
                      </div>
                      {selectedCompany != -1 ? (
                        <>
                          {" "}
                          <div
                            style={{
                              minHeight: "15vh",
                              width: "100%",
                              background: "white",
                            }}
                          >
                            {/* Headers for Company Info */}
                            <div
                              className={"admin_timeslot_header no_hover"}
                              style={{
                                margin: "2rem 0rem 0rem 0rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  minHeight: "5vh",
                                  alignItems: "end",
                                  margin: "1rem",
                                }}
                              >
                                <div style={{ display: "grid" }}>
                                  <span
                                    style={{
                                      padding: "0rem 0.8rem",
                                      fontSize: "13px",
                                      textAlign: "center",
                                      color: "#1d73ed",
                                    }}
                                  >
                                    Amount (USD)
                                  </span>
                                  <span
                                    style={{
                                      padding: "0rem 0.8rem",
                                      fontSize: "35px",
                                      textAlign: "center",
                                    }}
                                  >
                                    ${computePaymentCompany()}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={"regular "}
                                style={{ padding: "0rem" }}
                              >
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    borderRadius: "50px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    minWidth: "20%",
                                  }}
                                >
                                  Keywords
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    maxWidth: "20%",
                                    fontWeight: "bold",
                                    minWidth: "20%",
                                  }}
                                >
                                  Keywords / Topic
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    maxWidth: "3%",
                                    fontWeight: "bold",
                                    minWidth: "3%",
                                  }}
                                >
                                  URL
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    maxWidth: "15%",
                                    fontWeight: "bold",
                                    minWidth: "15%",
                                  }}
                                >
                                  Assign To
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "6%",
                                    minWidth: "6%",
                                  }}
                                >
                                  Words
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "6%",
                                    minWidth: "6%",
                                  }}
                                >
                                  Amount
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  Status
                                </div>
                                <div
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    maxWidth: "8%",
                                    minWidth: "8%",
                                  }}
                                >
                                  Deadline
                                </div>
                              </div>
                            </div>
                            <div
                              className="table"
                              style={{
                                background: "white",
                                minHeight: "20vh",
                              }}
                            >
                              <div className="reservation admin-timeslot-container">
                                {currentCompanieBlogs.length > 0 ? (
                                  currentCompanieBlogs.map(
                                    (blog: SuperAdminBlog, index: number) => {
                                      // if (selectedCompany != 100) {
                                      //   if (
                                      //     currentCompanieBlogs[Number(selectedCompany)].company_name !=
                                      //     currentCompanieBlogs.company_name
                                      //   ) {
                                      //     // return (<></>)
                                      //     return;
                                      //   }
                                      // }

                                      return (
                                        <>
                                          <div
                                            className={
                                              selectedBlogIndex !== index
                                                ? "admin_timeslot"
                                                : "admin_timeslot_opened"
                                            }
                                          >
                                            <div
                                              className={"regular"}
                                              style={{ padding: "0rem" }}
                                            >
                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  borderRadius: "50px",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                  minWidth: "20%",
                                                }}
                                              >
                                                {blog.keywords}
                                              </div>
                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  maxWidth: "20%",
                                                  fontWeight: "bold",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  minWidth: "20%",
                                                }}
                                              >
                                                {blog.blog_title}
                                              </div>

                                              <div
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  maxWidth: "5%",
                                                  fontWeight: "bold",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  minWidth: "3%",
                                                }}
                                              >
                                                Link
                                              </div>
                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  maxWidth: "15%",
                                                  fontWeight: "bold",
                                                  minWidth: "15%",
                                                }}
                                              >
                                                {blog.writer}
                                              </div>
                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: "5%",
                                                  minWidth: "5%",
                                                }}
                                              >
                                                {blog.words}
                                              </div>
                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  maxWidth: "6%",
                                                  minWidth: "6%",
                                                }}
                                              >
                                                {blog.amount + "$"}
                                              </div>

                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  // border: "1px solid gray",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  maxWidth: "8%",
                                                  minWidth: "8%",
                                                }}
                                              >
                                                {getRender(blog.status)}
                                              </div>

                                              <div
                                                onClick={() => {
                                                  setContact(!contact);
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                  // handleAdminTimeslotClick(index);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  maxWidth: "8%",
                                                  minWidth: "8%",
                                                }}
                                              >
                                                {blog.deadline}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={"admin_timeslot_header no_hover"}
                      style={{
                        margin: "2rem 0rem 0rem 0rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className={"regular_user"}
                        style={{
                          padding: "0rem",
                          borderBottom:
                            selectedUserId === 9999 ? "1px solid gray" : "",
                        }}
                      >
                        {all_users.map((writer: UserLite, index) => {
                          return (
                            <>
                              <div
                                onClick={() => {
                                  setSelectedUserId(index);
                                  setSelectedWriterUuid(writer.user_uuid);
                                  getWriterBlogs(writer.user_uuid);
                                }}
                                className="user_name"
                                style={{
                                  padding: "0.2rem 1.5rem",
                                  // borderRadius: "50px",
                                  fontWeight: "bold",
                                  background:
                                    index === selectedUserId
                                      ? "white"
                                      : !writer.verified
                                      ? "#ffbe53"
                                      : "",
                                  fontSize: "15px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color:
                                    index === selectedUserId
                                      ? "#1d73ed"
                                      : !writer.verified
                                      ? "white"
                                      : "",
                                  minWidth: "auto",
                                }}
                              >
                                {writer.name}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {selectedWriterUuid.length > 0 ? (
                        <>
                          <div
                            style={{
                              minHeight: "15vh",
                              width: "100%",
                              background: "white",
                            }}
                          >
                            {currentWriterBlogs.user_info.verified ? (
                              <></>
                            ) : (
                              <>
                                <div
                                  style={{
                                    paddingLeft: "3rem",
                                    display: "flex",
                                    margin: "2rem 0rem 0rem 0rem",
                                    color: "#ffbe53",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  onClick={() => {
                                    setCreateWriter(!createWriter);
                                    setEditUser(true);
                                    setUser((prevFormData) => ({
                                      ...prevFormData,
                                      name: currentWriterBlogs.user_info.name,
                                      user_uuid:
                                        currentWriterBlogs.user_info.user_uuid,
                                      email: currentWriterBlogs.user_info.email,
                                      roles: currentWriterBlogs.user_info.roles,
                                      verified:
                                        currentWriterBlogs.user_info.verified,
                                    }));
                                    console.log(
                                      currentWriterBlogs.user_info.roles
                                    );
                                    setSelectedOptions(
                                      currentWriterBlogs.user_info.roles
                                    );
                                  }}
                                >
                                  This user is not verifeid.
                                </div>
                              </>
                            )}
                            <div
                              className={"admin_timeslot_header no_hover"}
                              style={{
                                margin: "2rem 0rem 0rem 0rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  minHeight: "5vh",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: "3rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <SettingsIcon
                                    className="settings"
                                    onClick={() => {
                                      setCreateWriter(!createWriter);
                                      setEditUser(true);
                                      setUser((prevFormData) => ({
                                        ...prevFormData,
                                        name: currentWriterBlogs.user_info.name,
                                        user_uuid:
                                          currentWriterBlogs.user_info
                                            .user_uuid,
                                        email:
                                          currentWriterBlogs.user_info.email,
                                        roles:
                                          currentWriterBlogs.user_info.roles,
                                        verified:
                                          currentWriterBlogs.user_info.verified,
                                      }));
                                      console.log(
                                        currentWriterBlogs.user_info.roles
                                      );
                                      setSelectedOptions(
                                        currentWriterBlogs.user_info.roles
                                      );
                                    }}
                                  />
                                  {currentWriterBlogs.user_info.roles.map(
                                    (role) => {
                                      return (
                                        <span
                                          style={{
                                            marginLeft: "0.5rem",
                                            padding: "0.3rem 1rem",
                                            background:
                                              role === "Writer"
                                                ? "lightgreen"
                                                : "lightgreen",
                                            borderRadius: "10px",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {role}
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    paddingRight: "1rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      padding: "0rem 0.8rem",
                                      fontSize: "13px",
                                      textAlign: "center",
                                      color: "#1d73ed",
                                    }}
                                  >
                                    Amount (USD)
                                  </span>
                                  <span
                                    style={{
                                      padding: "0rem 0.8rem",
                                      fontSize: "35px",
                                      textAlign: "center",
                                    }}
                                  >
                                    ${computePayment()}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={"regular "}
                                style={{ padding: "0rem" }}
                              >
                                <div className="user_keywords_2">Keywords</div>
                                <div className="user_blog_length">Length</div>
                                <div className="user_url">URL</div>
                                <div
                                  className="board_hide"
                                  style={{
                                    padding: "0.2rem 0.5rem",
                                    // border: "1px solid gray",
                                    borderRadius: "50px",
                                    fontSize: "15px",
                                    maxWidth: "8%",
                                    fontWeight: "bold",
                                    minWidth: "8%",
                                  }}
                                >
                                  Guidline
                                </div>
                                <div className="user_deadline">Status</div>
                                <div className="board_hide user_deadline">
                                  Deadline
                                </div>
                              </div>
                            </div>
                            <div
                              className="table"
                              style={{
                                background: "white",
                                minHeight: "20vh",
                              }}
                            >
                              <div style={{ paddingLeft: "3rem" }}>
                                <h2>Writing</h2>
                              </div>
                              <div className="reservation admin-timeslot-container">
                                {currentWriterBlogs.blogs.length > 0 ? (
                                  currentWriterBlogs.blogs.map(
                                    (blog: SuperAdminBlog, index: number) => {
                                      if (
                                        blog.editor_name.length > 0 &&
                                        blog.editor_name ===
                                          currentWriterBlogs.user_info.user_uuid
                                      ) {
                                      } else {
                                        let urgent = blog.urgent ? true : false;

                                        return (
                                          <div
                                            style={{
                                              background: urgent
                                                ? "#ffdfe3"
                                                : "",
                                            }}
                                            className={
                                              selectedBlogIndex !== index
                                                ? "admin_timeslot"
                                                : "admin_timeslot_opened"
                                            }
                                          >
                                            <div
                                              className={"regular"}
                                              style={{ padding: "0rem" }}
                                            >
                                              <div className="user_keywords_2">
                                                {blog.keywords}
                                              </div>
                                              {/* <div
                                                onClick={() => {
                                                  setContact(!contact);

                                                  fetchGuidelines()
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  maxWidth: "20%",
                                                  fontWeight: "bold",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  minWidth: "20%",
                                                }}
                                              >
                                                {blog.blog_title}
                                              </div> */}
                                              <div className="user_blog_length">
                                                {blog.blog_length}
                                              </div>
                                              <div className="user_url">
                                                <a
                                                  href={blog.url}
                                                  target="_blank"
                                                >
                                                  {blog.url.length > 0
                                                    ? "Link"
                                                    : ""}
                                                </a>
                                              </div>
                                              <div
                                                style={{
                                                  // border: "1px solid gray",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  maxWidth: "8%",
                                                  minWidth: "8%",
                                                }}
                                              ></div>
                                              <div className="user_deadline">
                                                {getRender(blog.status)}
                                              </div>

                                              <div
                                                className="board_hide"
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  fontWeight: "bold",
                                                  maxWidth: "8%",
                                                  minWidth: "8%",
                                                }}
                                              >
                                                {blog.deadline}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div style={{ paddingLeft: "3rem" }}>
                                <h2>Editing</h2>
                              </div>
                              <div className="reservation admin-timeslot-container">
                                {currentWriterBlogs.editor_blogs.length > 0 ? (
                                  currentWriterBlogs.editor_blogs.map(
                                    (blog: SuperAdminBlog, index: number) => {
                                      if (
                                        blog.editor_uuid.length > 0 &&
                                        blog.editor_uuid ===
                                          currentWriterBlogs.user_info.user_uuid
                                      ) {
                                        let urgent = blog.urgent ? true : false;

                                        return (
                                          <>
                                            <div
                                              style={{
                                                background: urgent
                                                  ? "#ffdfe3"
                                                  : "",
                                              }}
                                              className={
                                                selectedBlogIndex !== index
                                                  ? "admin_timeslot"
                                                  : "admin_timeslot_opened"
                                              }
                                            >
                                              <div
                                                className={"regular"}
                                                style={{ padding: "0rem" }}
                                              >
                                                <div className="user_keywords_2">
                                                  {blog.keywords}
                                                </div>
                                                {/* <div
                                                onClick={() => {
                                                  setContact(!contact);

                                                  fetchGuidelines()
                                                  setCurrentBlog(blog);
                                                  setNewBlog(false);
                                                }}
                                                style={{
                                                  padding: "0.2rem 0.5rem",
                                                  // border: "1px solid gray",
                                                  borderRadius: "50px",
                                                  fontSize: "15px",
                                                  maxWidth: "20%",
                                                  fontWeight: "bold",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  minWidth: "20%",
                                                }}
                                              >
                                                {blog.blog_title}
                                              </div> */}
                                                <div className="user_blog_length">
                                                  {blog.blog_length}
                                                </div>
                                                <div className="user_url">
                                                  <a
                                                    href={blog.url}
                                                    target="_blank"
                                                  >
                                                    {blog.url.length > 0
                                                      ? "Link"
                                                      : ""}
                                                  </a>
                                                </div>
                                                <div
                                                  style={{
                                                    // border: "1px solid gray",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    maxWidth: "8%",
                                                    minWidth: "8%",
                                                  }}
                                                ></div>
                                                <div className="user_deadline">
                                                  {getRender(blog.status)}
                                                </div>

                                                <div
                                                  className="board_hide"
                                                  style={{
                                                    padding: "0.2rem 0.5rem",
                                                    // border: "1px solid gray",
                                                    borderRadius: "50px",
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    maxWidth: "8%",
                                                    minWidth: "8%",
                                                  }}
                                                >
                                                  {blog.deadline}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      } else {
                                      }
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* <div className={contact ? "modal-container" : "hidden"}>
        {renderContact()}
      </div> */}
      <div className={createCompany ? "modal-container" : "hidden"}>
        {renderCompany()}
      </div>
      <div className={createWriter ? "modal-container" : "hidden"}>
        {renderWriter()}
      </div>
      <div className={createEditor ? "modal-container" : "hidden"}>
        {renderEditor()}
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {toast_message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminWelcome;
